import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Step1a from "./Step1a";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step1b from "./Step1b";

const PrevArrow = ({ style, onClick }) => {
  return (
    <div
      className="slick-prev "
      style={{
        ...style,
        fontSize: "24px",
        color: "grey",
        zIndex: 1,
        left: "-3%",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: "20px",
        height: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // background: "rgba(var(--bs-primary-rgb), 0.3)",
        background: "rgba(0, 0, 0, 0.6)",
        borderRadius: "0.4rem",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <span
        className="carousel-control-prev-icon"
        style={{ filter: "brightness(1.4)", width: "15px", height: "15px" }}
      ></span>
    </div>
  );
};

const NextArrow = ({ style, onClick }) => {
  return (
    <div
      style={{
        ...style,
        background: "rgba(0, 0, 0, 0.6)",
        fontSize: "24px",
        color: "grey",
        zIndex: 1,
        right: "-3%",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: "20px",
        height: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // background: "rgba(var(--bs-primary-rgb), 0)",
        borderRadius: "0.4rem",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <span
        className="carousel-control-next-icon"
        style={{ filter: "brightness(1.4)", width: "15px", height: "15px" }}
      ></span>
    </div>
  );
};
function LandingPage2() {
  const settings = {
    speed: 500,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    // dots: true,
    swipe: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  return (
    <div className="vstack card bg-transparent w-50 h-100 p-0 bg-none border-0 mx-0">
      <style jsx>{`
        .steps::-webkit-scrollbar {
          height: 8px;
        }
        .steps::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.6);
          border-radius: 4px;
        }
        .steps::-webkit-scrollbar-thumb:hover {
          background-color: rgba(0, 0, 0, 0.8);
        }
        .steps::-webkit-scrollbar-track {
          background: rgba(0, 0, 0, 0.1);
        }
        [data-bs-theme="dark"] .steps::-webkit-scrollbar-thumb {
          background-color: rgba(255, 255, 255, 0.6);
        }
        [data-bs-theme="dark"] .steps::-webkit-scrollbar-thumb:hover {
          background-color: rgba(255, 255, 255, 0.8);
        }
        [data-bs-theme="dark"] .steps::-webkit-scrollbar-track {
          background: rgba(255, 255, 255, 0.1);
        }
      `}</style>
      <div className="card-body p-0 mb-0 m-0">
        <div
          className="steps"
          style={{ display: "flex", overflowX: "auto", width: "100%" }}
        >
          <div style={{ flex: "0 0 auto", width: "100%" }}>
            <Step1a />
          </div>
          <div style={{ flex: "0 0 auto", width: "100%" }}>
            <Step1b />
          </div>
          <div style={{ flex: "0 0 auto", width: "100%" }}>
            <Step2 />
          </div>
          <div style={{ flex: "0 0 auto", width: "100%" }}>
            <Step3 />
          </div>
          <div style={{ flex: "0 0 auto", width: "100%" }}>
            <Step4 />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage2;
{
  /*
          <div
          style={{ height: "40rem" }}
          id="carouselId"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <ol class="carousel-indicators">
            <li
              data-bs-target="#carouselId"
              data-bs-slide-to="0"
              class="active"
              aria-current="true"
              aria-label="First slide"
            ></li>
            <li
              data-bs-target="#carouselId"
              data-bs-slide-to="1"
              aria-label="Second slide"
            ></li>
            <li
              data-bs-target="#carouselId"
              data-bs-slide-to="2"
              aria-label="Third slide"
            ></li>
            <li
              data-bs-target="#carouselId"
              data-bs-slide-to="3"
              aria-label="Fourth slide"
            ></li>
            <li
              data-bs-target="#carouselId"
              data-bs-slide-to="4"
              aria-label="Fifth slide"
            ></li>
          </ol>
          <div class="carousel-inner" role="listbox">
            <div class="carousel-item active">
              <Step1a />
            </div>
            <div class="carousel-item">
              <Step1b />
            </div>
            <div class="carousel-item">
              <Step2 />
            </div>
            <div class="carousel-item">
              <Step3 />
            </div>
            <div class="carousel-item">
              <Step4 />
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselId"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselId"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>

  */
}
