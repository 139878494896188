import React, { useState, useEffect } from "react";
import ProductCard from "../Products/ProductCard";
import algoliasearch from "algoliasearch/lite";
import { useParams, useLocation, Link } from "react-router-dom";
import useSearchedProducts from "../StatesStore/searchedProducts";
import useSearchFilter from "../StatesStore/SearchFilters";
import UserProfileCard from "../Header/userProfileCard";
import { UserAuth } from "../Context";
import LoadingSpinner from "../UserProfile/LoadingSpinner";
import ProductCard5 from "../Products/ProductCard5";

//algolia object initialization and index mapping
const client = algoliasearch("EMFUSB67CG", "0f7466afc30b24c667a61b43cf879898");
const index = client.initIndex("products"); //name of index in algolia is products
const indexUsers = client.initIndex("users"); // Name of index in Algolia is users

export default function SearchResults() {
  //**********************  Router Functions  **********************************//
  const location = useLocation();
  const { input } = useParams();
  const products = useSearchedProducts((state) => state.products);

  //*********************** other states     *************************************/
  const [allProducts, setAllProducts] = useState([]);
  const [allUsers, setAllUsers] = useState([]); // Corrected line
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(8);
  const [loading, setLoading] = useState(true);
  const [countLeftUser, setCountLeftUser] = useState(false);
  const [countLeftProduct, setCountLeftProduct] = useState(false);
  const [paramInput, setParamInput] = useState(input);
  const [totalProductCount, setTotalProductCount] = useState(0); //total product count
  const [totalUserCount, setTotalUserCount] = useState(0); //total User count
  const { user } = UserAuth();

  const [result, setResult] = useState(false);
  const setSearchedProduct = useSearchedProducts(
    (state) => state.setSearchedProduct
  );
  const searchFilter = useSearchFilter((state) => state.SearchFilter);

  //******************************* Functions **********************************************/
  useEffect(() => {
    console.log("Page count in useeffect", currentPage);
    setResult(false);
    setAllProducts([]);
    setSearchedProduct([]);
    setCurrentPage(0);
    setParamInput(input);
    retrieveData(0);
    setResult(true);
    setCurrentPage(currentPage + 1);
  }, [location.pathname, input]); //only updates when user refreshes

  //**********************   Handle Load More ********************************************/
  // ****************** async call to retrieve data **************************************/
  const retrieveData = async (page) => {
    if (input.length >= 3) {
      // console.log('Page before fetch: ', page);
      try {
        const offset = page * itemsPerPage;
        const data = await index.search(input, {
          offset,
          length: itemsPerPage,
        });
        setLoading(false);

        // Create a Set to ensure unique products
        const uniqueProducts = new Set([...allProducts, ...data.hits]);

        // Convert the Set back to an array
        const updatedProducts = Array.from(uniqueProducts);

        setAllProducts(updatedProducts);
        setTotalProductCount(data.nbHits);
        console.log("all products in search 1111", updatedProducts);

        setSearchedProduct(data.hits);

        if (data.nbHits > data.offset) {
          setCountLeftProduct(true);
        } else {
          setCountLeftProduct(false);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }

      try {
        const offset = page * itemsPerPage;
        const data = await indexUsers.search(input, {
          offset,
          length: itemsPerPage,
          typoTolerance: "strict", // Enable strict typo tolerance
          // Contexts:['firstName', 'lastName', 'email', 'userName'], // Searchable contexts
          synonyms: false, // Disable synonyms
        });

        // // Create a Set to store unique users based on a unique identifier (e.g., user ID)
        // const uniqueUserSet = new Set(allUsers.map(user => user.email));

        // // Filter out duplicates and convert back to an array
        // const uniqueUsers = data.hits.filter(user => !uniqueUserSet.has(user.email));
        console.log("Data hits in search", data.hits);
        setLoading(false);
        setAllUsers(data.hits);
        setTotalUserCount(data.nbHits);
        setCountLeftUser(data.nbHits > offset + itemsPerPage);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
  };
  // console.log('global products in search 1111', products);
  const filteredProducts = products.filter(
    (product) =>
      (searchFilter.gender.length === 0 ||
        searchFilter.gender.includes(product.category)) &&
      (searchFilter.brand.length === 0 ||
        searchFilter.brand.includes(product.supplier)) &&
      searchFilter.minprice <= product.newPrice &&
      searchFilter.maxprice >= product.newPrice
  );

  const isFilter =
    searchFilter?.gender.length > 0 ||
    searchFilter?.brand.length > 0 ||
    searchFilter?.size.length > 0 ||
    searchFilter?.minprice > 0 ||
    searchFilter?.maxprice < 90000;

  // console.log("value of current page :", currentPage);

  // console.log("value of countLeft page :", countLeftProduct);
  return (
    // <div className="col-md-8 col-lg-6 vstack p-0 mt-0 mt-lg-5 ">
    <div style={{ width: "50%" }} className="ms-0 ms-lg-1 vstack px-0">
      {loading ? (
        <LoadingSpinner />
      ) : allUsers.length === 0 && filteredProducts.length === 0 ? (
        <div
          style={{ height: "35rem" }}
          className="w-100 card d-flex justify-content-center align-items-center"
        >
          <h3 className="fw-semibold">No Users or Products Found</h3>
        </div>
      ) : (
        <></>
      )}
      {allUsers.length > 0 && (
        <div className="userSection card p-0 mb-2">
          <div className="card-header p-3 ">
            {result && (
              <div className="card-title d-flex justify-content-between align-items-center mb-0">
                <h4>Users</h4>
                {(countLeftUser > 8 ||
                  totalUserCount > currentPage * itemsPerPage) && (
                  <Link to={`${location.pathname}/users`} className="nav-link">
                    <u>View All {totalUserCount} Users</u>
                  </Link>
                )}
              </div>
            )}
          </div>
          <div className="card-body">
            <div className="row g-4">
              {paramInput.length >= 3 && allUsers.length ? (
                allUsers?.map((searchUser, index) => {
                  if (searchUser?.objectUser?.isVerified)
                    return (
                      <UserProfileCard
                        key={index}
                        searchUser={searchUser}
                        user={user}
                      />
                    );
                })
              ) : (
                <div>Need At least three Characters to search</div>
              )}
            </div>
          </div>
        </div>
      )}
      {filteredProducts.length > 0 && (
        <div className="productsSection card p-0">
          <div className="card-header border-0 p-2">
            {result && (
              <div className="card-title d-flex justify-content-between align-items-center">
                <h4>Products</h4>
                {(countLeftProduct > 8 ||
                  totalProductCount > currentPage * itemsPerPage) && (
                  <Link
                    to={`${location.pathname}/products`}
                    className="nav-link"
                  >
                    <u>View All {totalProductCount} Products</u>
                  </Link>
                )}
              </div>
            )}
          </div>
          <div className="card-body pt-0 px-3">
            <div className="row g-4">
              {paramInput.length >= 3 && filteredProducts.length ? (
                filteredProducts.map((product, index) => (
                  <div className="col-6 col-sm-4 col-md-3 col-lg-4 px-1 mt-2">
                    <ProductCard5 key={index} product={product} />
                  </div>
                ))
              ) : (
                <div>Need At least three Characters to search</div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
