/* global GLightbox */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import GLightbox from "glightbox";
import "glightbox/dist/css/glightbox.css";
import { UserAuth } from "../../Context";
import BrandDisplay from "../../NewsFeed/BrandDisplay";
import formatPrice from "../../lib/formatPrice";
import { getBrandDetails } from "../../lib/getBrandDetails";
import useFetch from "../../hooks/useFetch";
import usePost from "../../hooks/usePost";
import { db } from "../../FirebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import GlightImage from "./GlightImage";
import AddOrRemoveLike from "../../Products/AddOrRemoveLike";
import AddToChat from "../../components/AddToChat";
import AddToCart from "../../components/AddToCart";
import AddToList from "../../components/AddToList";


const ProductCard5 = ({ product, isStrip, addButtonData }) => {
  const [likeCount, setLikeCount] = useState(product?.likes);
  const [favouritesCount, setFavouritesCount] = useState(product?.favourites);
  const [brandData, setBrandData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isInList, setIsInList] = useState(false);
  const [showToolTipCart, setShowToolTipCart] = useState(false);
  const [showToolTipFavorites, setShowToolTipFavorites] = useState(false);
  const [showToolTipChat, setShowToolTipChat] = useState(false);
  const [showToolTipAddToList, setShowToolTipAddToList] = useState(false);
  const [loginCount, setLoginCount] = useState(0);
  const [lightbox, setLightbox] = useState(null);
  const { user } = UserAuth();
  const { data: activeBrands } = useFetch(
    `${process.env.REACT_APP_API}/api/activeBrands`
  );
  let url = process.env.REACT_APP_API + `/api/activity/${user?.uid}`;

  const { data: postData, post } = usePost(url);

  const fetchBrandData = async () => {
    try {
      const result = await getBrandDetails(product?.supplier, activeBrands);
      setBrandData(result);
    } catch (error) {
      console.error("Error getting brand data:", error);
    }
  };

  const checkProductInList = async () => {
    const userRef = doc(
      db,
      "users",
      addButtonData.userId,
      "shoppingList",
      addButtonData.selectedShopList,
      "products",
      product?.id
    );
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      setIsInList(true);
    }
  };
  // useEffect(() => {
  //   const lightbox = GLightbox({
  //     selector: `[data-postglightbox="${product?.id}"]`,
  //   });

  //   return () => {
  //     lightbox.destroy();
  //   };
  // }, [product]);
  useEffect(() => {
    if (!product || !activeBrands) return;
    fetchBrandData();
  }, [product, activeBrands]);

  useEffect(() => {
    if (!product || !addButtonData) return;
    checkProductInList();
  }, [product, addButtonData]);

  const handleImageLoad = () => {
    setLoading(false);
  };

  // const fetchLoginCount = async () => {
  //   const userRef = doc(db, "users", user?.uid);
  //   const userSnap = await getDoc(userRef);
  //   if (userSnap.exists()) {
  //     setLoginCount(userSnap?.data().loginCount || 0);
  //   }
  // };

  // useEffect(() => {
  //   if (!user) return;
  //   fetchLoginCount();
  // }, [user]);

  const handleDismiss = () => {
    setShowToolTipCart(false);
    setShowToolTipFavorites(false);
    setShowToolTipChat(false);
    setShowToolTipAddToList(false);
  };

  // useEffect(() => {
  //   const lightboxInstance = GLightbox({
  //     elements: [
  //       {
  //         href: product?.imageUrl,
  //         type: 'image',
  //         alt: product?.name
  //       }
  //     ]
  //   });
    
  //   setLightbox(lightboxInstance);

  //   return () => {
  //     if (lightboxInstance) {
  //       lightboxInstance.destroy();
  //     }
  //   };
  // }, [product?.imageUrl]);

  const handleImageClick = (e) => {
    // e.preventDefault();
    if (lightbox) {
      lightbox.open();
    }
  };

  const renderTooltipCart = (props) => (
    <Tooltip
      id="button-tooltip"
      className="custom-tooltip bg-secondary rounded-2 p-0"
      {...props}
    >
      👋 Welcome! Click here to add this item to your Cart.
    </Tooltip>
  );

  return (
    <div className="mt-0 mb-1 mb-sm-1 ms-1 ms-sm-1 me-1 me-sm-1">
      <Card>
        <div style={{ position: "relative", height: "auto" }}>
          {/* Use GlightImage instead of Card.Img */}
          <GlightImage
            product={product}
            image={product?.imageUrl}
            imageIndex={0}
            brandData={brandData}
          />
        </div>

        <div
          className="position-absolute rounded-pill top-0 start-0 m-2"
          style={{
            fontSize: "12px",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          }}
        >
          {product?.newPrice && (
            <p className="m-0 text-white px-2 py-1">
              PKR{" "}
              {product?.newPrice
                ? formatPrice(product.newPrice)
                : formatPrice(product.oldPrice)}
            </p>
          )}
        </div>
        <div
          className="d-inline-flex ps-2 pe-2 align-items-center justify-content-center rounded-pill text-light position-absolute end-0 m-2"
          style={{
            fontSize: "1.3rem",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          }}
        >
          {/* <Link className="text-center text-body"> */}
       
            <Link
              className="text-center text-light"

            >
              <AddToCart user={user} product={product} />
            </Link>
      
          {/* </Link> */}
        </div>
        {/* Plus Button to add product in the list */}
        {/* {addButtonData && (
          <div
            className="position-absolute rounded-circle top-0 end-0 m-2"
            style={{
              fontSize: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              if (!isInList) {
                addButtonData.handleAddProductToList(product?.id, product);
                setIsInList(true);
              }
            }}
          >
            {isInList ? (
              <CheckCircleFill className="fs-4 text-body" />
            ) : (
              <PlusCircleFill className="fs-4 text-body" />
            )}
          </div>
        )} */}
        {/* Strip */}
        {(isStrip === undefined || isStrip === true) && (
          <>
            <div
              className="d-inline-flex ps-1 pe-2 align-items-center justify-content-center rounded-pill text-light position-absolute bottom-0 end-0"
              style={{
                fontSize: "12px",
                marginBottom: "2.4rem",
                marginRight: "0.5rem",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
              }}
            >
           
                <span
     
                >
                  <AddToList product={product} componentName={"strip2"} />
                </span>
           
            </div>
            <div
              className="d-inline-flex px-2 align-items-center justify-content-center rounded-pill text-light position-absolute bottom-0 end-0 m-2"
              style={{
                fontSize: "12px",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
              }}
            >
          
                <span
                  onClick={handleDismiss}
    
                >
                  <AddToChat product={product} />
                </span>
    
            </div>
            <div
              className="align-items-center justify-content-center rounded-pill position-absolute bottom-0 start-0 m-2"
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.6)",
              }}
            >
              <Link
                className="text-center text-light d-flex align-items-center gap-1 px-2"
                onClick={handleDismiss}
                onMouseLeave={handleDismiss}
                onMouseEnter={() => setShowToolTipCart(true)}
              >
                <AddOrRemoveLike
                  user={user}
                  product={product}
                  setLikeCount={setLikeCount}
                  componentName={"strip2"}
                />
                <div style={{ fontSize: "10px" }}>({likeCount})</div>
              </Link>
            </div>
          </>
          //   <Link className="text-center d-flex flex-column nav-link" to="">
          //     <i className="bi bi-eye"></i>
          //     <div
          //       style={{
          //         fontSize: "10px",
          //         marginTop: "-5px",
          //         marginBottom: "-2px",
          //       }}
          //     >
          //       {product?.views ?? 0}
          //     </div>
          //   </Link>

          //   <Link
          //     className="text-center text-light"
          //     onClick={handleDismiss}
          //     onMouseLeave={handleDismiss}
          //     onMouseEnter={() => setShowToolTipFavorites(true)}
          //   >
          //     <AddOrRemoveFavourites
          //       product={product}
          //       user={user}
          //       setFavouritesCount={setFavouritesCount}
          //     />
          //     <div style={{ fontSize: "10px", marginTop: "-7px" }}>
          //       {favouritesCount ?? 0}
          //     </div>
          //   </Link>

          //   <span
          //     onClick={handleDismiss}
          //     onMouseLeave={handleDismiss}
          //     onMouseEnter={() => setShowToolTipChat(true)}
          //   >
          //     <ShareMenu2 product={product} />
          //   </span>

          //   <span
          //     onClick={handleDismiss}
          //     onMouseLeave={handleDismiss}
          //     onMouseEnter={() => setShowToolTipAddToList(true)}
          //   >
          //     <AddToList product={product} componentName={"strip2"} />
          //   </span>

          //   <span
          //     onClick={handleDismiss}
          //     onMouseLeave={handleDismiss}
          //     onMouseEnter={() => setShowToolTipAddToList(true)}
          //   >
          //     <AddToChat product={product} />
          //   </span>
          // </div>
        )}
      </Card>

      <div className="pe-1 pb-1">
        <div className="d-flex align-items-center">
          {/* Logo */}
          <div
            className="avatar me-2"
            style={{ width: "35px", height: "35px" }}
          >
            <Link to={"/BrandHome/" + product?.supplier}>
              <img
                className="avatar-img rounded"
                src={brandData?.logo}
                alt=""
              />
            </Link>
          </div>
          <div
            className="d-flex flex-column justify-content-start"
            style={{ width: "78%" }}
          >
            <div className="mb-0">
              <BrandDisplay brandData={brandData} type={"ProductCard"} />
            </div>
            <div
              className="fw-bold h6 position-relative"
              style={{
                marginTop: "2px",
                fontSize: "12px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {product?.name}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard5;
