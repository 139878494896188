import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useParams } from "react-router-dom";
import UserActivity from "./UserActivity";
import UserPosts from "./UserPosts";
import UserFollowing from "./UserFollowingTab";
import UserFollower from "./UserFollowerTab";
import UserConnections from "./UserConnections";
import SavedPosts from "./SavedPosts";
import InviteStats from "../Referral/InviteStats";
import { useFetchUserData } from "../hooks/user/useFetchUserData";
import { UserAuth } from "../Context";
import PointsStats from "../Points/PointsStats";
import ShoppingList from "./ProfileShoppingList/ShoppingLists";
import UserProfileHeader3 from "./UserProfileHeader3";
//importing sidebar links here

function ProfilePage() {
  const { uid } = useParams(); // Extract the "uid" parameter from the URL
  const { user } = UserAuth();
  const location = useLocation();
  const { userData } = useFetchUserData(uid);
  const getSelectedOptionFromUrl = () => {
    const pathSegments = location.pathname.split("/");
    if (pathSegments.includes("posts")) return "posts";
    if (pathSegments.includes("shoppinglist")) return "shoppinglist";
    if (pathSegments.includes("userfollowing")) return "following";
    if (pathSegments.includes("userfollowers")) return "follower";

    return "shoppinglist"; // Default
  };

  const [selectedOption, setSelectedOption] = useState(
    getSelectedOptionFromUrl()
  );
  useEffect(() => {
    document.body.scrollIntoView({ behavior: "smooth" });
    document.body.scrollTo(0, 0);
    console.log("SCROLL View ProfilePage");
  }, [uid]);

  return (
    <>
      {/* // Main content START */}
      {/* <span className="col-lg-2"></span> */}
      <div
        style={{ width: "50%" }}
        className="vstack px-0 ms-0 ms-lg-1 me-0 me-lg-2"
      >
        {/* <div className="col-lg-8 vstack mt-0 mt-lg-5 px-0"> */}
        <UserProfileHeader3
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
        <div
        // className="card pt-3 mt-3"
        // style={{ height: "23rem", overflowY: "auto" }}
        >
          <Routes>
            <Route path="/userActivity" element={<UserActivity />} />
            {/* <Route
            path="/profile/userAbout"
            element={<UserAbout userData={userData} />}
          /> */}
            <Route
              path="/"
              element={<ShoppingList userData={userData} user={user} />}
            />
            <Route
              path="/profile/pointsStats"
              element={<PointsStats userData={userData} />}
            />
            <Route
              path="/profile/invite"
              element={<InviteStats userData={userData} />}
            />

            <Route
              path="/posts"
              element={<UserPosts userData={userData} user={user} />}
            />
            <Route
              path="/shoppinglist"
              element={<ShoppingList userData={userData} user={user} />}
            />
            <Route path="/posts/savedposts" element={<SavedPosts />} />
            <Route
              path="/network/userFriends"
              element={<UserConnections userData={userData} />}
            />
            <Route
              path="/network/userfollowing"
              element={<UserFollowing userData={userData} path={"users"} />}
            />
            <Route
              path="/network/brands"
              element={<UserFollowing userData={userData} path={"brands"} />}
            />
            <Route
              path="/network/userfollowers"
              element={<UserFollower userData={userData} />}
            />
          </Routes>
        </div>
      </div>
      {/* <div className="col-lg-20 d-flex flex-column h-100 gap-2 d-none d-lg-block">
      <div className="">
          <RecentlyJoinedUsersProfile />
        </div>
      <div className="">
          <FriendList />
        </div>
      </div> */}
      {/* <span className="col-lg-2"></span> */}
      {/* // Main content END // <RightSideBarProfilePage /> */}
    </>
  );
}

export default ProfilePage;
