import React, { useState } from "react";
import BrandSelection from "./BrandSelection";
import CategorySelection from "./CategorySelection";
import { Link } from "react-router-dom";
function BrandsAndCategories() {
  // const [option, setOption] = useState("brands");
  const [activeTab, setActiveTab] = useState("brand");

  // Function to render active tab content
  const renderTabContent = () => {
    switch (activeTab) {
      case "brand":
        return <BrandSelection />;
      case "category":
        return <CategorySelection />;
      default:
        return <BrandSelection />;
    }
  };
  return (
    <>
      {/* <div className="card p-3 mb-2 pb-0 rounded-2">
        <div className="container card-header bg-transparent d-flex text-center align-items-md-center justify-content-between border-0 p-0 mx-1 my-0">
          <ul className="nav nav-bottom-line d-sm-flex align-items-center justify-content-start border-bottom-0 w-75">
            <li className="h5 nav-item mb-0">
              <Link
                className={`nav-link px-2 py-3 ${
                  option === "brands" ? "active" : ""
                }`}
                onClick={() => {
                  setOption("brands");
                }}
              >
                Brands
              </Link>
            </li>
            <li className="h5 nav-item ">
              <Link
                className={`nav-link py-3 px-2 ${
                  option === "categories" ? "active" : ""
                }`}
                onClick={() => {
                  setOption("categories");
                }}
              >
                <span>Categories</span>
              </Link>
            </li>
          </ul>
        </div>
      </div> */}
      <div className="card">
        {/* Tab Navigation */}
        <ul className="card-header nav nav-tabs nav-bottom-line2 active justify-content-center justify-content-md-start my-0 pe-3 py-0 ps-4">
          <li className="nav-item">
            <Link
              className={`nav-link pt-3 pb-2 ${
                activeTab === "brand" ? "active" : ""
              }`}
              onClick={() => setActiveTab("brand")}
            >
              Brand
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link pt-3 pb-2 ${
                activeTab === "category" ? "active" : ""
              }`}
              onClick={() => setActiveTab("category")}
            >
              Categories
            </Link>
          </li>
        </ul>
        <div className="card-body pt-0 px-2">
          {/* Tab Content */}
          {renderTabContent()}
        </div>
      </div>
      {/* {option === "brands" && <BrandSelection />}
      {option === "categories" && <CategorySelection />} */}
    </>
  );
}

export default BrandsAndCategories;
