import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../FirebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import Accordion from "react-bootstrap/Accordion";
import "./style.css";

function HeaderMenuSideBar({ activeAccordion, setActiveAccordion }) {
  const [womenCategories, setWomenCategories] = useState([]);
  const [menCategories, setMenCategories] = useState([]);

  useEffect(() => {
    const getMenu = async () => {
      const data = collection(db, "universalCategories");
      const querySnapshot = await getDocs(data);
      const categoriesData = querySnapshot.docs.map(
        (doc) => doc.data().subCategories
      );
      setWomenCategories(categoriesData[1]);
      setMenCategories(categoriesData[0]);
    };

    getMenu();
  }, []);
  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index); // Toggle the clicked accordion
  };
  const renderDropdownItems = (categories, gender, count) => {
    return Object.keys(categories)
      ?.sort()
      ?.map((categoryName, index) => (
        <Accordion
          key={categoryName}
          flush
          activeKey={
            activeAccordion === count + index ? `${count + index}` : null
          }
        >
          <Accordion.Item
            className="bg-transparent"
            eventKey={`${count + index}`}
          >
            <Accordion.Header
              className="mb-1"
              onClick={() => toggleAccordion(count + index)}
            >
              {categoryName}
            </Accordion.Header>
            <Accordion.Body className="p-0">
              <ul
                className="list-unstyled fw-lighter"
                style={{ marginLeft: "8px" }}
              >
                {categories[categoryName]
                  .filter(
                    (subCategory) =>
                      subCategory.name !== "None" &&
                      subCategory.displayName !== "None"
                  )
                  .map((subCategory, index) => (
                    <li key={index} className="mb-2">
                      <Link
                        className="dropdown-item"
                        to={`/AllProducts/${gender}/${categoryName}/${subCategory.name}`}
                        // onClick={handleOutsideClick}
                      >
                        <span
                          data-bs-toggle="collapse"
                          data-bs-target="#navbarCollapse"
                          // onClick={handleOutsideClick}
                        >
                          {subCategory.displayName}
                        </span>
                      </Link>
                    </li>
                  ))}
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ));
  };

  return (
    <div className="text-start">
      {/* MENS */}
      <div className=" ">
        <h6 className="text-start fs-6 fw-normal mb-2">Men</h6>
        <ul
          className="p-0 m-0 ms-2 mt-1"
          id="collapseExample"
          style={{ listStyleType: "none", overflowX: "auto" }}
        >
          {renderDropdownItems(menCategories, "Men", 10)}
        </ul>
      </div>
      {/* WOMENS */}
      <div className="mt-1">
        <h6 className="text-start fs-6 fw-normal mb-2">Women</h6>
        <ul
          className="p-0 m-0 ms-2 mt-1"
          id="collapseExample"
          style={{ listStyleType: "none" }}
        >
          {renderDropdownItems(womenCategories, "Women", 20)}
        </ul>
      </div>
    </div>
  );
}

export default HeaderMenuSideBar;
