import { ConnectionRequest } from "./ConnectionRequest";
import UserActiveConnections from "./ActiveConnections";
import React, { useState, useEffect } from "react";
import { UserAuth } from "../Context";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { useParams } from "react-router-dom";
import PrivateProfile from "./PrivateProfile";
import LoadingSpinner from "./LoadingSpinner";

export default function UserConnections({ userData, componentName }) {
  const { uid } = useParams();
  const { user } = UserAuth();
  const [pendingList, setPendingList] = useState([]);
  const [activeList, setActiveList] = useState([]);
  const [connectionsTimestamps, setConnectionsTimestamps] = useState({});
  const [loading, setLoading] = useState(true);
  console.log('uid',uid);
  
  useEffect(() => {
    if (!uid) {
      // Handle the case where the user is not logged in or the data is not yet loaded
      return;
    }
    setLoading(true);
    // Reference to the user's connections subcollection
    const connectionsRef = collection(db, "users", uid, "connections");

    // Listen for real-time updates to the connections subcollection
    const unsubscribe = onSnapshot(connectionsRef, (snapshot) => {
      const pendingList = [];
      const activeList = [];
      const timestamps = {};

      snapshot.forEach((doc) => {
        const data = doc.data();
        timestamps[doc.id] = data.timestamp;
        if (data.type === "pending") {
          pendingList.push(doc.id);
        } else if (data.type === "active") {
          activeList.push(doc.id);
        }
      });

      setPendingList(pendingList);
      setActiveList(activeList);
      setConnectionsTimestamps(timestamps);
    });
    // Clean up the listener when the component unmounts
    setLoading(false);
    return () => unsubscribe();
  }, [uid]);

  // Render a loading message or similar if the user data is not yet available
  // if (!uid) {
  //   return <div>Loading...</div>;
  // }
  if (loading) {
    return <LoadingSpinner/>;
  }

  const Friendlist = userData?.friendRequests?.active || [];
  const privacy = userData?.privacySettings?.accountSetting || "private";
  const isCurrentUserProfile = user?.uid === uid;
  const isUserInFriendList = Friendlist.includes(user?.uid);

  return (
    <>
      {/* Card Connections START */}
      {(isCurrentUserProfile || privacy === "public" || isUserInFriendList) && (
        <>
          <div
          className={`card ${
            componentName === "Network" ? "border-0 h-100" : "pt-3 mt-3"
          }`}
          style={{ height: "23rem", overflowY: "auto" }}
        >
            {/* Card header START */}
            {/* <div className="card-header border-0 pb-0 px-0"> */}
              {/* <h5 className="card-title px-4">
                {activeList.length || 0} Connections
              </h5> */}
            {/* </div> */}
            {/* Card header END */}
            {/* Card body START */}
            <div className="card-body p-0 px-0">
              {/* Connections Item */}

              {isCurrentUserProfile && pendingList?.length > 0 && (
                <ConnectionRequest
                  pendingList={pendingList}
                  loggedInUser={user?.uid}
                  connectionsTimestamps={connectionsTimestamps}
                />
              )}
              {/* Divider */}
              {pendingList?.length > 0 && 
              <div>
                <hr className="mb-2 mt-0" />
              </div>}
              <UserActiveConnections
                activelist={activeList}
                loggedInUser={user?.uid}
                connectionsTimestamps={connectionsTimestamps}
                activeList={activeList}
              />
              <div className="d-grid">
                {/* Load more button START */}
                {/* <a
              href="#!"
              role="button"
              className="btn btn-sm btn-loader btn-primary-soft"
              data-bs-toggle="button"
              aria-pressed="true"
            >
              <span className="load-text"> Load more connections </span>
              <div className="load-icon">
                <div className="spinner-grow spinner-grow-sm" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </a> */}
                {/* Load more button END */}
              </div>
            </div>
            {/* Card body END */}
          </div>
          {/* Card Connections END */}
        </>
      )}

      {!isCurrentUserProfile && privacy === "private" && <PrivateProfile />}
    </>
  );
}
