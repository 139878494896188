import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Masonry from "react-responsive-masonry";
import ProductCard from "../Products/ProductCard";
import fetchProducts from "../lib/productUtils";
import { getCategoryDisplayName } from "../lib/getCategoryName";
import { UserAuth } from "../Context";
import useBrandProducts from "../StatesStore/brandProducts";
import useBrandFilter from "../StatesStore/BrandFilters";
import useLogActivity from "../hooks/user/useLogActivity";
import LoadingSpinner from "../UserProfile/LoadingSpinner";
import ProductCard5 from "../Products/ProductCard5";
import ProductCardBrandsPage from "../Products/ProductCardBrandsPage";

function BrandProducts({ brandName }) {
  const setBrandProduct = useBrandProducts((state) => state.setBrandProduct);
  const BrandFilter = useBrandFilter((state) => state.BrandFilter);

  // console.log(brandName);

  const { user } = UserAuth();
  let { category } = useParams();
  let { subCategory } = useParams();
  let { subSubCategory } = useParams();

  const { logActivity } = useLogActivity(user?.uid);

  // console.log("subCategory", subCategory);
  // console.log("subSubCategory", subSubCategory);
  let { catLabel: categoryLabel } = "";

  if (typeof subCategory === "undefined") {
    categoryLabel = "All Products";
  } else {
    // Map category names to display names
    const mappedSubCategory = getCategoryDisplayName(subCategory);
    const mappedSubSubCategory = getCategoryDisplayName(subSubCategory);

    categoryLabel = mappedSubCategory + " - " + mappedSubSubCategory;
  }

  const [allProducts, setAllProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);
  const [filter, setFilter] = useState("NF");
  const [bname, setBname] = useState("");
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [countLeft, setCountLeft] = useState(true);
  const [prevSubCategory, setPrevSubCategory] = useState(null);
  const [prevSubSubCategory, setPrevSubSubCategory] = useState(null);
  const [columnCount, setColumnCount] = useState(getColumnCount());
  const [searchParams, setSearchParams] = useSearchParams();
  const [sizes, setSizes] = useSearchParams();

  // const sizes = searchParams.get('size') ? searchParams.get('size').split(',') : [];
  // setSizes(searchParams.get('size') ? searchParams.get('size').split(',') : [])
  // // let  size  = useParams();
  // console.log("The sizes are ",sizes);
  useEffect(() => {
    function handleResize() {
      setColumnCount(getColumnCount());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function getColumnCount() {
    const screenWidth = window.innerWidth;
    let count = 2;
    if (screenWidth >= 440) {
      count = 3;
    }
    if (screenWidth >= 768) {
      count = 4;
    }
    if (screenWidth >= 900) {
      count = 5;
    }
    return count;
  }
  useEffect(() => {
    setCurrentPage(1);
    setAllProducts([]);
    setBrandProduct([]);
    setLastVisible(null);
    setCountLeft(true);
    window.scrollTo(0, 0);

    const activityData = {
      brand: brandName,
      type: "viewedBrand",
    };

    logActivity(activityData);
  }, [bname, subCategory, subSubCategory]);

  // useEffect(() => {
  //   const logBrandView = async () => {
  //     if (!user) return;

  //     const userRef = doc(db, "users", user?.uid);
  //     const userDoc = await getDoc(userRef);
  //     const logRef = collection(userRef, "logs");

  //     // Determine category based on user gender if category is null
  //     let finalCategory = category;
  //     if (!finalCategory) {
  //       const userGender = userDoc.data().gender;
  //       if (userGender === "male") {
  //         finalCategory = "Men";
  //       } else if (userGender === "female") {
  //         finalCategory = "Women";
  //       }
  //     }

  //     let timestamp = new Date();
  //     let logData = {
  //       brand:brandName,
  //       category: finalCategory,
  //       timestamp,
  //     };

  //     // Add subCategory and subSubCategory if they are not null
  //     if (subCategory) logData.subCategory = subCategory;
  //     if (subSubCategory) logData.subSubCategory = subSubCategory;

  //     // Construct the query dynamically based on available data
  //     const constraints = [where("brand", "==", brandName)];
  //     if (finalCategory) constraints.push(where("category", "==", finalCategory));
  //     if (subCategory) constraints.push(where("subCategory", "==", subCategory));
  //     if (subSubCategory) constraints.push(where("subSubCategory", "==", subSubCategory));

  //     const logDocRef = query(logRef, ...constraints);
  //     const snapshot = await getDocs(logDocRef);

  //     if (snapshot.empty) {
  //       // Document doesn't exist, create a new one
  //       await addDoc(logRef, logData);
  //       console.log("Log entry added successfully");
  //     } else {
  //       // Document exists, update the timestamp
  //       snapshot.forEach((doc) => {
  //         setDoc(doc.ref, logData, { merge: true });
  //         console.log(`Updated timestamp for ${brandName}`);
  //       });
  //     }
  //        // Invalidate query key after the log is updated
  //        //queryClient.invalidateQueries(['fetchData' ,]);
  //       //  queryClient.invalidateQueries({ queryKey: ['fetchData'] })
  //   };

  //   logBrandView();
  // }, [brandName, category, subCategory, subSubCategory, user]);

  useEffect(() => {
    // Reset lastVisible and count when category changes
    if (
      subCategory !== prevSubCategory ||
      subSubCategory !== prevSubSubCategory
    ) {
      setLastVisible(null);
      setCountLeft(true);
    }

    // Update prevSubCategory and prevSubSubCategory
    setPrevSubCategory(subCategory);
    setPrevSubSubCategory(subSubCategory);
  }, [subCategory, subSubCategory, countLeft]);

  useEffect(() => {
    setBname(brandName);
    console.log("CHANGED");
    // const queryParams = new URLSearchParams(window.location.search);
    // const sizes = queryParams.get('size') ? queryParams.get('size').split(',') : [];
    // console.log("sizes",sizes);
    fetchProducts({
      activeBrands: [],
      brandName,
      category: BrandFilter.category || category,
      subCategory: BrandFilter.subCategory || subCategory,
      subSubCategory: BrandFilter.subSubCategory || subSubCategory,
      prevSubSubCategory,
      prevSubCategory,
      lastVisible,
      currentPage,
      setAllProducts,
      setLoading,
      setLastVisible,
      setCountLeft,
      filter,
      sizes: BrandFilter.size,
      colors: BrandFilter.color,
      minprice: BrandFilter.minprice,
      maxprice: BrandFilter.maxprice,
    });
  }, [
    brandName,
    subCategory,
    subSubCategory,
    currentPage,
    category,
    filter,
    BrandFilter,
    BrandFilter.size,
    BrandFilter.color,
    BrandFilter.minprice,
    BrandFilter.maxprice,
    BrandFilter.category,
  ]);

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  // console.log("BrandFilter.size",BrandFilter.size);
  // console.log("BrandFilter",BrandFilter);
  const filteredProducts = allProducts;

  // console.log('filtered products ', filteredProducts)
  // console.log('Products global state in brand Products', products);
  return (
    <>
      <div className="card">
        {/* Card header START */}
        <div className="card-header d-sm-flex text-center align-items-center justify-content-between border-0 pt-2 pb-1 my-1 px-2 mx-1">
          <h1 className="card-title h4">{categoryLabel}</h1>{" "}
          <div className="col-sm-6 col-lg-4">
            <select
              className="form-select js-choice"
              data-search-enabled="true"
              onChange={handleFilterChange}
              value={filter}
            >
              <option value="NF">Newest First</option>
              <option value="PL">Price - Low to High</option>
              <option value="PH">Price - High to Low</option>
              <option value="DL">Discount - Low to High</option>
              <option value="DH">Discount - High to Low</option>
            </select>
          </div>
        </div>

        {/* Card body START */}
        <div className="card-body">
          <div className="row g-4">
            {/* Product item START */}
            {filteredProducts.map((product, index) => {
              // return <ProductCard key={index} product={product} />;
              return (
                <div className="col-6 col-sm-4 col-md-3 col-lg-4 px-1 mt-2">
                  <ProductCardBrandsPage key={index} product={product} />
                </div>
              );
            })}

            {/* Product item END */}
          </div>
        </div>
        {/* Card body END */}

        {/*} Pagination */}
      </div>

      <button
        className={`btn btn-loader  mt-12  btn-primary-soft ${
          loading ||
          !countLeft ||
          filteredProducts.length < currentPage * itemsPerPage
            ? "disabled"
            : ""
        }`}
        onClick={
          !countLeft
            ? null
            : () => {
                if (!loading) {
                  setLoading(true);
                  setCurrentPage((prevValue) => prevValue + 1);
                }
              }
        }
      >
        {loading ? (
          <LoadingSpinner />
        ) : (
          <span className="load-text">
            {filteredProducts.length >= currentPage * itemsPerPage
              ? "Load more"
              : "No more products to show"}
          </span>
        )}
      </button>
    </>
  );
}

export default BrandProducts;
