import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Spinner } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import ProfilePicture from "../UserProfile/ProfilePicture";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import VideoCard2 from "./VideoCard2";
import AddOrRemoveSaved from "../Products/AddOrRemoveSaved";
import { UserAuth } from "../Context";
import FeedReaction2 from "../NewsFeed/FeedReaction2";
import UserCard3Footer from "./UserCard3Footer";

const PrevArrow = ({ style, onClick }) => {
  return (
    <div
      className="slick-prev"
      style={{
        ...style,
        fontSize: "20px",
      }}
      onClick={onClick}
    >
      <span
        className="carousel-control-prev-icon"
        style={{ filter: "brightness(1)" }}
      ></span>
    </div>
  );
};
const NextArrow = ({ style, onClick }) => {
  return (
    <div
      className="slick-next"
      style={{
        ...style,
        fontSize: "20px",
      }}
      onClick={onClick}
    >
      <span
        className="carousel-control-next-icon"
        style={{ filter: "brightness(1)" }}
      ></span>
    </div>
  );
};
const UserCard3 = ({ post }) => {
  const [loading, setLoading] = useState(true);
  const { ref, inView } = useInView({
    threshold: 0.5,
  });
  const [postMedia, setPostMedia] = useState([]);
  const { user } = UserAuth();
  //   console.log("post", post);
  useEffect(() => {
    const fetchRemainingPosts = async () => {
      const postRef = collection(db, "posts", post?.id, "media");
      const postSnap = await getDocs(postRef);
      const postMediaUrls = postSnap.docs.map((post) => ({
        id: post.id,
        url: post?.data()?.url,
        type: post?.data()?.type,
      }));
      const sortedPostMedia = [
        { id: post?.id, url: post?.thumbnailUrl, type: post?.type },
        ...postMediaUrls,
      ].sort((a, b) => (a.type === "video" ? -1 : b.type === "image" ? 1 : 0));

      setPostMedia(sortedPostMedia);
    };
    if (post?.mediaCount > 1) {
      fetchRemainingPosts();
    } else {
      setPostMedia([
        { id: post?.id, url: post?.thumbnailUrl, type: post?.type },
      ]);
    }
  }, [post]);

  const handleImageLoad = () => {
    setLoading(false);
  };
  const settings = {
    speed: 500,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: post?.mediaCount > 1 ? true : false,
    swipe: post?.mediaCount > 1 ? true : false,
    nextArrow: post?.mediaCount > 1 ? <NextArrow /> : <></>,
    prevArrow: post?.mediaCount > 1 ? <PrevArrow /> : <></>,
  };
  //   console.log("postMedia", postMedia);
  return (
    post?.thumbnailUrl && (
      <div className="my-1 my-sm-1 mx-0 userCard">
        <style jsx>
          {`
            .userCard {
              margin-top: 0 !important;
            }
            .userCard .slick-dots {
              bottom: 10px !important;
            }

            .userCard .slick-prev {
              z-index: 1;
              left: -1% !important;
            }

            .userCard .slick-next {
              z-index: 1;
              right: 3% !important;
            }
          `}
        </style>
        <Card>
          <Card.Body className="p-0">
            <div style={{ position: "relative", height: "auto" }}>
              <Slider {...settings} className="m-0">
                {postMedia.map((p, index) => (
                  <div key={index}>
                    {p?.type === "image" && (
                      <>
                        {loading && (
                          <div
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              zIndex: 1,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                              height: "100%",
                              backgroundColor: "#eef0f2", // background for the placeholder
                            }}
                          >
                            <Spinner
                              animation="border"
                              style={{
                                color: "grey",
                                width: "20px",
                                height: "20px",
                              }}
                            />
                          </div>
                        )}
                        <Link to={`/post/${p.id}`}>
                          <Card.Img
                            src={p?.url ?? ""}
                            style={{
                              height: `auto`,
                              objectFit: "cover",
                              opacity: loading ? 0 : 1, // Hide the image until it's fully loaded
                              transition: "opacity 0.5s ease-in-out",
                              display: "inline",
                            }}
                            onLoad={handleImageLoad}
                            loading="lazy"
                          />
                        </Link>
                      </>
                    )}
                    {p?.type === "video" && (
                      <VideoCard2
                        url={p?.url}
                        post={p}
                        ref={ref}
                        inView={inView}
                      />
                    )}
                  </div>
                ))}
              </Slider>
            </div>
            <div className="d-inline-flex pe-1 align-items-center justify-content-center rounded-pill text-light position-absolute end-0 top-0">
              <span>
                <AddOrRemoveSaved post={post} user={user} />
              </span>
            </div>
            <div
              className="position-absolute bottom-0 start-0 end-0 rounded-bottom-2"
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.6)",
              }}
            >
              <FeedReaction2 post={post} />
            </div>
          </Card.Body>
        </Card>
        <UserCard3Footer post={post} />
        {/* <div className="card-footer  p-0 ">
          <FeedReaction post={post} />
        </div> */}
      </div>
    )
  );
};

export default UserCard3;
