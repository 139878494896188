import React, { useState, useEffect } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import uuid4 from "uuid4";
import { toast } from "react-toastify";
import {
  getDoc,
  doc,
  setDoc,
  onSnapshot,
  collection,
  getDocs,
  serverTimestamp,
  where,
  query,
} from "firebase/firestore";
import SideNavUserProfile from "./SideNav/SideNavUserProfile";
import { db } from "./FirebaseConfig";
import { toastOptions } from "./lib/toastOptions";
import { UserAuth } from "./Context";
import { Modal, Form, Button } from "react-bootstrap";
import MyList from "./ShoppingList/MyList";
import InviteLists from "./ShoppingList/InviteLists";
import useSocketStore from "./StatesStore/Socket";
const ShoppingList = () => {
  // My Shopping list Data
  const [shoppingListData, setShoppingListData] = useState([]);
  // Invited ShoppingList Data
  const [invitedshoppingListData, setInvitedShoppingListData] = useState([]);
  // Current Selected Category
  const [selectedListCategory, setSelectedListCategory] = useState(
    "MyShoppingList"
  );

  // Two categories: true for MyShoppingList, false for Invited Shopping List
  const checkMyShoppingList = selectedListCategory === "MyShoppingList";
  // Used when createing new list
  const [newListName, setNewListName] = useState("");
  // Used to separate the invited list by listId and userId
  // const [invitedList, setInvitedList] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const { user } = UserAuth();
  const location = useLocation();
  const [activeAccordion, setActiveAccordion] = useState(null);
  const socket = useSocketStore((state) => state.socket);
  console.log("user auth", user);

  useEffect(() => {
    if (location.pathname.startsWith("/shoppingLists/inviteLists")) {
      setSelectedListCategory("InvitedShoppingList");
    } else {
      setSelectedListCategory("MyShoppingList");
    }
  }, [location]);

  useEffect(() => {
    const fetchShoppingLists = async () => {
      if (!user) return;

      const userRef = doc(db, "users", user.uid);
      const shoppingListRef = collection(userRef, "shoppingList");

      let productUnsubscribes = [];
      const unsubscribe = onSnapshot(shoppingListRef, async (snapshot) => {
        const shoppingListPromises = snapshot.docs.map(async (document) => {
          const shoppingList = document.data();
          const dateCreated =
            shoppingList.dateCreated?.seconds || new Date().getTime() / 1000;

          const productRef = collection(
            shoppingListRef,
            document.id,
            "products"
          );
          const productUnsubscribe = onSnapshot(
            productRef,
            (productSnapshot) => {
              const products = productSnapshot.docs.map((productDoc) => ({
                id: productDoc.id,
                ...productDoc.data(),
              }));

              setShoppingListData((prevData) => {
                const updatedData = prevData.map((list) => {
                  if (list.name === document.id) {
                    return { ...list, products };
                  }
                  return list;
                });
                return updatedData.sort(
                  (a, b) =>
                    b.products.length - a.products.length ||
                    b.dateCreated - a.dateCreated
                );
              });
            }
          );

          productUnsubscribes.push(productUnsubscribe);

          const productSnapshot = await getDocs(productRef);
          const products = productSnapshot.docs.map((productDoc) => ({
            id: productDoc.id,
            ...productDoc.data(),
          }));

          return {
            name: document.id,
            dateCreated,
            products,
            listId: shoppingList.listId,
            sizes: shoppingList.sizes,
          };
        });

        const shoppingListDataArray = await Promise.all(shoppingListPromises);
        shoppingListDataArray.sort(
          (a, b) =>
            // b.products.length - a.products.length ||
            a.dateCreated - b.dateCreated
        );
        setShoppingListData(shoppingListDataArray);
      });

      return () => {
        unsubscribe();
        productUnsubscribes.forEach((unsubscribe) => unsubscribe());
      };
    };

    const fetchInvitedShoppingLists = async () => {
      if (!user) return;

      const invitedListRef = collection(db, "users", user.uid, "invitedList");
      const invitedListUnsubscribes = [];

      const unsubscribe = onSnapshot(invitedListRef, async (snapshot) => {
        const invitedLists = snapshot.docs.map((doc) => ({
          listId: doc.id,
          userId: doc.data().userId,
          role: doc.data().role,
        }));

        const shoppingLists = await Promise.all(
          invitedLists.map(async ({ listId, userId, role }) => {
            const shoppingListRef = collection(
              db,
              "users",
              userId,
              "shoppingList"
            );
            const q = query(shoppingListRef, where("listId", "==", listId));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) return null;

            const shoppingListSnapshot = querySnapshot.docs[0];
            const shoppingListData = shoppingListSnapshot.data();

            const productRef = collection(
              shoppingListRef,
              shoppingListSnapshot.id,
              "products"
            );
            const productUnsubscribe = onSnapshot(
              productRef,
              (productSnapshot) => {
                const products = productSnapshot.docs.map((productDoc) => ({
                  id: productDoc.id,
                  ...productDoc.data(),
                }));

                setInvitedShoppingListData((prevData) => {
                  const updatedData = prevData.map((list) => {
                    if (list.name === shoppingListSnapshot.id) {
                      return { ...list, products };
                    }
                    return list;
                  });
                  return updatedData;
                });
              }
            );

            invitedListUnsubscribes.push(productUnsubscribe);

            const productSnapshot = await getDocs(productRef);
            const products = productSnapshot.docs.map((productDoc) => ({
              id: productDoc.id,
              ...productDoc.data(),
            }));

            return {
              name: shoppingListSnapshot.id,
              dateCreated:
                shoppingListData.dateCreated?.seconds ||
                new Date().getTime() / 1000,
              products,
              listId: shoppingListData.listId,
              sizes: shoppingListData.sizes,
              userId: userId,
              role: role,
            };
          })
        );

        let filteredShoppingLists = shoppingLists.filter(
          (list) => list !== null
        );

        await Promise.all(
          filteredShoppingLists.map(async (list) => {
            const userRef = doc(db, "users", list?.userId);
            const userData = await getDoc(userRef);
            const userCompleteData = userData?.data();
            list.userName = userCompleteData?.userName;
            list.photo = userCompleteData?.photo;
          })
        );

        const urlListId = location.pathname.split("/").pop();
        if (urlListId && urlListId !== "inviteLists") {
          filteredShoppingLists.sort((a, b) => {
            if (a.listId === urlListId) return -1;
            if (b.listId === urlListId) return 1;
            return a.dateCreated - b.dateCreated;
          });
        } else {
          filteredShoppingLists.sort((a, b) => a.dateCreated - b.dateCreated);
        }

        setInvitedShoppingListData(filteredShoppingLists);
      });

      return () => {
        unsubscribe();
        invitedListUnsubscribes.forEach((unsubscribe) => unsubscribe());
      };
    };

    document.body.scrollIntoView({ behavior: "smooth" });
    document.body.scrollTo(0, 0);
    fetchShoppingLists();
    fetchInvitedShoppingLists();
  }, [user]);

  const handleCreateNewList = async () => {
    const listName = newListName.trim();

    if (listName) {
      const capitalizedListName =
        listName.charAt(0).toUpperCase() + listName.slice(1);

      if (await addToSavedList(capitalizedListName)) {
        const newListId = uuid4();

        setShoppingListData([
          ...shoppingListData,
          {
            name: capitalizedListName,
            dateCreated: new Date(),
            products: [],
            listId: newListId,
            visibility: "Private",
          },
        ]);

        toast.success(
          ` ${capitalizedListName} Shopping List has been created`,
          toastOptions
        );
      } else {
        toast.warn(`${capitalizedListName} already exists`, toastOptions);
      }

      setNewListName("");
      handleClose();
    }
  };

  const addToSavedList = async (listName) => {
    if (!user) return;

    try {
      const shoppingListRef = collection(db, "users", user.uid, "shoppingList");
      const newListId = listName;

      const existingListDoc = await getDoc(doc(shoppingListRef, newListId));
      if (!existingListDoc.exists()) {
        await setDoc(doc(shoppingListRef, newListId), {
          dateCreated: serverTimestamp(),
          listId: uuid4(),
          visibility: "Private",
        });
      } else {
        return false;
      }
      console.log("New shopping list added successfully!");
      return true;
    } catch (error) {
      console.error("Error creating New shopping list:", error.message);
      return false;
    }
  };

  return (
    <>
      {/* <div className="col-md-8 col-lg-6 vstack gap-3 text-body mt-0 mt-lg-5 px-3 px-lg-0 me-2"> */}
      <div
        style={{ width: "50%" }}
        className="vstack gap-3 text-body px-0 ms-0 ms-lg-1"
      >
        <div className="card p-3 mb-2 pb-0  rounded-2">
          {/* CARD HEADER */}
          <div className="container card-header bg-transparent d-flex text-center align-items-md-center justify-content-between border-0 p-0 mx-1 my-0">
            <ul className="nav nav-bottom-line2 d-sm-flex align-items-center justify-content-start border-bottom-0 w-75">
              {/* <div className="nav-item d-flex mb-sm-0 mb-2"> */}
              <li className="h5 nav-item mb-0">
                <Link
                  className={`nav-link py-3 pe-2 ${
                    checkMyShoppingList ? "active" : ""
                  }`}
                  onClick={() => {
                    setSelectedListCategory("MyShoppingList");
                  }}
                  to="/shoppingLists/"
                >
                  My Lists
                </Link>
              </li>
              <li className="h5 nav-item ">
                <Link
                  className={`nav-link py-3 px-2 ${
                    !checkMyShoppingList ? "active" : ""
                  }`}
                  onClick={() => {
                    setSelectedListCategory("InvitedShoppingList");
                  }}
                  to="/shoppingLists/inviteLists"
                >
                  <span>Invited Lists</span>
                </Link>
              </li>
              {/* </div> */}
            </ul>
            {checkMyShoppingList ? (
              <Link
                onClick={() => setShow(true)}
                className="h6 btn btn-sm btn-primary-soft mb-3"
              >
                <div className="d-block d-sm-none pt-1">
                  <i className="fa-solid fa-plus"></i>
                </div>
                <span className="d-none d-sm-block">
                  <i className="fa-solid fa-plus pe-1"></i> Create list
                </span>
              </Link>
            ) : (
              <div className="my-3 py-1"></div>
            )}
          </div>
          {/* Nav Strip under Header */}
        </div>
        <Routes>
          <Route
            path="/"
            element={
              <MyList
                shoppingListData={shoppingListData}
                setShoppingListData={setShoppingListData}
                activeAccordion={activeAccordion}
                setActiveAccordion={setActiveAccordion}
              />
            }
          />
          <Route
            path="/inviteLists/*"
            element={
              <InviteLists
                invitedshoppingListData={invitedshoppingListData}
                setInvitedShoppingListData={setInvitedShoppingListData}
                activeAccordion={activeAccordion}
                setActiveAccordion={setActiveAccordion}
                // invitedList={invitedList}
              />
            }
          />
        </Routes>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title id="modalLabelCreateEvents">Create new list</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form
            className="d-flex"
            onSubmit={(e) => {
              e.preventDefault();
              handleCreateNewList();
            }}
          >
            <div className="p-0 w-75 me-1">
              <Form.Control
                type="text"
                placeholder="Shopping list name"
                value={newListName}
                onChange={(e) => setNewListName(e.target.value)}
              />
            </div>
            <div className="p-0 w-25">
              <Button
                variant="primary-soft"
                type="submit"
                className="container"
                data-bs-dismiss="modal"
              >
                <span className="d-none d-sm-block">Create now</span>
                <span className="d-sm-none">Create</span>
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ShoppingList;
