import { format } from "date-fns";

export const formatTimestampForPost = (timestamp) => {
  if (!timestamp) {
    return "N/A";
  }

  // Convert Firestore timestamp to JavaScript Date object
  let postDate;
  if (timestamp._seconds && timestamp._nanoseconds) {
    // Node.js format
    postDate = new Date(
      timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000
    );
  } else if (timestamp.seconds && timestamp.nanoseconds) {
    // JavaScript format
    postDate = new Date(
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
    );
  } else {
    return "Invalid timestamp format";
  }

  const now = new Date();
  const timeDifferenceInSeconds = (now - postDate) / 1000;

  if (timeDifferenceInSeconds < 60) {
    return "now";
  }

  if (timeDifferenceInSeconds < 3600) {
    // Less than an hour ago
    const minutes = Math.floor(timeDifferenceInSeconds / 60);
    return `${minutes} min`;
  }

  if (timeDifferenceInSeconds < 86400) {
    // Less than a day ago
    const hours = Math.floor(timeDifferenceInSeconds / 3600);
    return hours > 1 ? `${hours} hrs` : `${hours} hr`;
  }

  if (timeDifferenceInSeconds < 2592000) {
    // Less than a month ago
    const days = Math.floor(timeDifferenceInSeconds / 86400);
    return days > 1 ? `${days} days` : `${days} day`;
  }

  if (timeDifferenceInSeconds < 31536000) {
    // Less than a year ago
    const months = Math.floor(timeDifferenceInSeconds / 2592000);
    return months > 1 ? `${months} months` : `${months} month`;
  }

  // More than a year ago
  return format(postDate, "MMMM dd, yyyy");
};
