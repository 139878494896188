import React, { useEffect, useRef, useState } from "react";
import { UserAuth } from "../Context";
import { Link, useLocation } from "react-router-dom";
import {
  onSnapshot,
  doc,
  collection,
  query,
  where,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import ProfilePicture from "../UserProfile/ProfilePicture";
import ShareMenu from "../Referral/ShareMenu";
import Notification from "../Notifications/Notification";
import { useFetchUserData } from "../hooks/user/useFetchUserData";
import { Dropdown } from "react-bootstrap";
import HeaderChatNotification from "../Chat/HeaderChatNotification";
import { Gear } from "react-bootstrap-icons";

function UserMenuHeader3() {
  const { user, logOut } = UserAuth();
  const [theme, setTheme] = useState("");
  const location = useLocation();
  const [totalShoppingListCount, setTotalShoppingList] = useState(0);
  const [cartCount, setCartCount] = useState(0);
  const { userData } = useFetchUserData(user?.uid);
  const dropdownRef = useRef(null);
  const [lastSeenNotification, setLastSeenNotification] = useState(
    userData?.lastSeenNotification || null
  );
  const [newNotificationsCount, setNewNotificationsCount] = useState(0);
  // console.log("lastSeenNotification from userMenuHeader3", lastSeenNotification);
  const [lastSeenMsgs, setLastSeenMsgs] = useState(
    userData?.lastSeenMsgs || null
  );
  const [newMessagesCount, setNewMessagesCount] = useState(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    setTheme(localStorage.getItem("theme"));
  }, [theme]);

  const signOutUser = async () => {
    logOut();
  };
  useEffect(() => {
    if (!user) {
      setTotalShoppingList(0);
    }
  }, [user]);

  useEffect(() => {
    if (!userData) return;

    // User document listener
    const userDocRef = doc(db, "users", user.uid);
    const unsubscribeUserDoc = onSnapshot(userDocRef, (docSnapshot) => {
      const data = docSnapshot.data();

      // Update lastSeenNotification and lastSeenMsgs
      const updatedLastSeenNotification = data?.lastSeenNotification;
      const updatedLastSeenMsgs = data?.lastSeenMsgs || new Date();

      setLastSeenNotification(updatedLastSeenNotification);
      setLastSeenMsgs(updatedLastSeenMsgs);

      // Notifications query
      const notificationsRef = collection(
        db,
        "users",
        user.uid,
        "notifications"
      );
      const notificationsQuery = query(
        notificationsRef,
        where("timestamp", ">", updatedLastSeenNotification)
      );

      const unsubscribeNotifications = onSnapshot(
        notificationsQuery,
        (snapshot) => {
          setNewNotificationsCount(snapshot.size);
        }
      );

      // Messages query
      const chatsRef = collection(db, "chats");
      const chatsQuery = query(
        chatsRef,
        where("members", "array-contains", user?.uid),
        where("updatedAt", ">", updatedLastSeenMsgs),
        where("senderId", "!=", user?.uid)
      );

      const unsubscribeMessages = onSnapshot(chatsQuery, (snapshot) => {
        console.log("msg snapshot.size", snapshot.size);
        console.log("msg updatedLastSeenMsgs", updatedLastSeenMsgs);

        setNewMessagesCount(snapshot.size);
      });

      // Cleanup function for sub-collections
      return () => {
        unsubscribeNotifications();
        unsubscribeMessages();
      };
    });

    // Cleanup for user document listener
    return () => {
      unsubscribeUserDoc();
    };
  }, [userData]);

  useEffect(() => {
    const fetchShoppingListCount = async () => {
      if (!user) return;
      const userRef = doc(db, "users", user.uid);
      try {
        const unsubscribeShoppingList = onSnapshot(userRef, (doc) => {
          const userData = doc.data();
          const shoppingListCount = userData?.shoppingListCount || 0;
          setTotalShoppingList(shoppingListCount);
        });

        return () => unsubscribeShoppingList(); // Unsubscribe when the component unmounts
      } catch (error) {
        console.error("Error fetching shopping list count:", error);
      }
    };

    fetchShoppingListCount();
  }, [user]);

  useEffect(() => {
    if (!user) {
      setCartCount(0);
      return;
    }

    const cartRef = collection(db, "users", user.uid, "cart");
    const unsubscribeCart = onSnapshot(cartRef, (snapshot) => {
      setCartCount(snapshot.size);
    });

    return () => unsubscribeCart();
  }, [user]);

  const handleTheme = (mode) => {
    setTheme(mode);
    document.documentElement.setAttribute("data-bs-theme", mode);
  };
  const handleMenuItemClick = () => {
    if (dropdownRef.current) {
      dropdownRef.current.querySelector(".dropdown-toggle").click();
    }
  };
  const handleOutsideClick = () => {
    const brand = document.getElementById("brandsCollapse");
    // const category = document.getElementById("categoryCollapse");
    const menCategory = document.getElementById("menCollapse");
    const womenCategory = document.getElementById("womenCollapse");
    if (brand.classList.contains("show")) {
      brand.classList.remove("show");
    }
    // if (category.classList.contains("show")) {
    //   category.classList.remove("show");
    // }
    if (user && menCategory.classList.contains("show")) {
      menCategory.classList.remove("show");
    }
    if (user && womenCategory.classList.contains("show")) {
      womenCategory.classList.remove("show");
    }
  };
  if (!userData) return null;
  const updateTimestamps = async () => {
    if (!user) return;
    const userDocRef = doc(db, "users", user.uid);
    await updateDoc(userDocRef, {
      lastSeenNotification: serverTimestamp(),
      lastSeenMsgs: serverTimestamp(),
    });

    setLastSeenNotification(new Date());
    setLastSeenMsgs(new Date());
    setNewMessagesCount(0);
    setNewNotificationsCount(0);
  };

  const handleDropdownToggle = (isOpen) => {
    setIsDropdownOpen(isOpen);

    // Update timestamps when dropdown is opened
    if (isOpen && (newNotificationsCount > 0 || newMessagesCount > 0)) {
      setTimeout(() => {
        updateTimestamps();
      }, 7000);
    }
  };
  return (
    <>
      <li
        className="nav-item mx-0 mx-md-2"
        onClick={() => {
          handleOutsideClick();
        }}
      >
        <Link className="nav-link p-0 position-relative " to="/chat">
          {newMessagesCount > 0 && (
            <span
              className={`d-flex justify-content-center align-items-center position-absolute translate-middle badge rounded-circle ${
                newMessagesCount > 9 ? "px-1" : ""
              } bg-danger`}
              style={{
                top: "13%",
                left: "99%",
                fontSize: "10px",
                minHeight: "7.5px",
                minWidth: "7px",
              }}
            >
              {newMessagesCount}
            </span>
          )}
          <i
            className={`bi bi-chat-square-text${
              location.pathname.includes("/chat") ? "-fill" : ""
            } fs-4`}
          ></i>
        </Link>
      </li>
      <li className="nav-item mx-0 mx-md-2">
        <Link
          className="nav-link p-0 shopping-list-link position-relative "
          to="/shoppingLists"
          onClick={() => handleOutsideClick()}
        >
          {totalShoppingListCount > 0 && (
            <span
              className={`d-flex justify-content-center align-items-center position-absolute translate-middle rounded-circle badge bg-danger ${
                totalShoppingListCount > 9 ? "px-1" : ""
              }`}
              style={{
                top: "13%",
                left: "99%",
                fontSize: "10px",
                minHeight: "7.5px",
                minWidth: "7px",
              }}
            >
              {totalShoppingListCount}
            </span>
          )}
          <i
            className={`bi bi-bookmark${
              location.pathname.includes("/shoppingLists") ? "-fill" : ""
            } fs-4`}
          ></i>
        </Link>
      </li>
      <li className="nav-item mx-0 mx-md-2">
        <Link
          className="nav-link p-0 position-relative"
          to="/cart"
          onClick={() => handleOutsideClick()}
        >
          {cartCount > 0 && (
            <span
              className={`d-flex justify-content-center align-items-center position-absolute translate-middle badge rounded-circle bg-danger ${
                cartCount > 9 ? "px-1" : ""
              }`}
              style={{
                top: "13%",
                left: "99%",
                fontSize: "10px",
                minHeight: "7.5px",
                minWidth: "7px",
              }}
            >
              {cartCount}
            </span>
          )}
          <i
            className={`bi bi-cart${
              location.pathname.includes("/cart") ? "-fill" : ""
            } fs-4`}
          ></i>
        </Link>
      </li>

      <li className="nav-item dropdown userMenuHeader3" ref={dropdownRef}>
        <style jsx>
          {`
            .userMenuHeader3 .dropdown-toggle::after {
              display: none;
            }
          `}
        </style>
        <Dropdown
          align="end"
          autoClose
          onToggle={() => {
            handleDropdownToggle();
            handleOutsideClick();
          }}
        >
          <Dropdown.Toggle
            variant="link"
            className="nav-link p-0 position-relative"
          >
            <i className={`bi bi-person fs-3`}></i>
            {newNotificationsCount > 0 && (
              <span
                className={`d-flex justify-content-center align-items-center position-absolute translate-middle badge rounded-circle ${
                  newNotificationsCount > 9 ? "px-1" : ""
                } bg-danger`}
                style={{
                  top: "14%",
                  left: "90%",
                  fontSize: "10px",
                  minHeight: "7.5px",
                  minWidth: "7px",
                }}
              >
                {newNotificationsCount}
              </span>
            )}
          </Dropdown.Toggle>

          <Dropdown.Menu
            className="dropdown-animation pt-3 small me-md-n3"
            style={{ width: "350px" }}
          >
            {/* Notifications */}
            <div className="px-1 " onClick={handleMenuItemClick}>
              <Notification
                user={user}
                userData={userData}
                newNotificationsCount={newNotificationsCount}
              />
            </div>
            {/* <Dropdown.Divider className="pb-1" /> */}

            {/* <div className="px-1" onClick={handleMenuItemClick}>
              <HeaderChatNotification
                user={user}
                userData={userData}
                newMessagesCount={newMessagesCount}
              />
            </div> */}

            {/* <Dropdown.Divider className="mt-2"/> */}

            {/* Profile Links */}
            {/* <Dropdown.Item as="div" className="px-3">
              <Link
                className="dropdown-item btn btn-primary-soft btn-sm my-2 text-center rounded-1"
                to={`/userProfile/${user.uid}/profile/userAbout`}
              >
                View profile
              </Link>
              <Link
                className="dropdown-item btn btn-primary-soft btn-sm text-center rounded-1"
                to="/chat"
              >
                Messages
              </Link>
              <span>
                <ShareMenu />
              </span>
            </Dropdown.Item> */}

            <Dropdown.Divider className="my-2" />
            <Dropdown.Item
              as="div"
              className="d-flex justify-content-center align-items-center p-0 w-100"
            >
              <Dropdown.Item
                as={Link}
                to={`/userProfile/${user.uid}/shoppinglist`}
                className="bg-primary-soft-hover ps-3 p-0 d-flex w-100"
              >
                <i className="bi bi-person fa-fw me-2"></i>
                <div>Profile</div>
              </Dropdown.Item>
              <Dropdown.Item className="p-0 d-flex">
                <ShareMenu />
              </Dropdown.Item>
              {/* Sign Out */}
              <Dropdown.Item
                as={Link}
                to="/SignIn"
                className="bg-danger-soft-hover p-0 d-flex"
                onClick={signOutUser}
              >
                <i className="bi bi-power fa-fw me-2"></i>
                <div>Sign Out</div>
              </Dropdown.Item>
            </Dropdown.Item>
            <Dropdown.Divider />

            {/* Dark Mode Options */}
            <Dropdown.Item
              as="div"
              className="modeswitch-item theme-icon-active d-flex justify-content-between gap-3 align-items-center pb-0 px-3"
            >
              <Link
                to={`/settings/accountSetting`}
                className="d-flex gap-2 align-items-center nav-link p-0"
              >
                <Gear />
                <div>Settings</div>
              </Link>
              <div className="vr border mx-1 nav-link p-0"></div>
              <div
                className="d-flex gap-2 align-items-center nav-link p-0"
                style={{ cursor: "pointer" }}
              >
                <div className="me-2">Mode:</div>
                <div className="d-flex gap-2">
                  <button
                    aria-label="Light Mode"
                    type="button"
                    className={`btn btn-modeswitch nav-link text-primary-hover mb-0 ${
                      theme === "light" ? "active text-white" : ""
                    }`}
                    onClick={() => {
                      handleTheme("light");
                      localStorage.setItem("theme", "light");
                    }}
                  >
                    <i className="bi bi-sun fa-fw"></i>
                  </button>
                  <button
                    aria-label="Dark Mode"
                    type="button"
                    className={`btn btn-modeswitch nav-link text-primary-hover mb-0 ${
                      theme === "dark" ? "active text-white" : ""
                    }`}
                    onClick={() => {
                      handleTheme("dark");
                      localStorage.setItem("theme", "dark");
                    }}
                  >
                    <i className="bi bi-moon-stars fa-fw"></i>
                  </button>
                  <button
                    aria-label="Auto Mode"
                    type="button"
                    className={`btn btn-modeswitch nav-link text-primary-hover mb-0 ${
                      theme === "" || theme === "auto"
                        ? "active text-white"
                        : ""
                    }`}
                    onClick={() => {
                      handleTheme("auto");
                      localStorage.setItem("theme", "auto");
                    }}
                  >
                    <i className="bi bi-circle-half fa-fw"></i>
                  </button>
                </div>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </li>
    </>
  );
}

export default UserMenuHeader3;
