import React from "react";

function Step1() {
  const step1 = process.env.PUBLIC_URL + "/assets/LandingPage/step1.png";
  const step1Mobile =
    process.env.PUBLIC_URL + "/assets/LandingPage/step1Mobile.png";

  return (
    <>
      <div className="d-block d-md-none w-100">
        <img src={step1Mobile} className="mx-auto" alt="Step1 GIF" />
      </div>
      <div className="d-none d-md-block">
        <img src={step1} alt="Step1 GIF" />
      </div>
    </>
  );
}

export default Step1;
