import React, { useEffect, useState } from "react";
import { db } from "../FirebaseConfig";
import {
  collection,
  query,
  orderBy,
  limit,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";
import { Link } from "react-router-dom";
import ProfilePicture from "../UserProfile/ProfilePicture";
import { UserAuth } from "../Context";
import useSocketStore from "../StatesStore/Socket";


export default function RecentlyOnlineUsers({ socket }) {
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const { user } = UserAuth();
  // const socket = useSocketStore((state) => state.socket);

  useEffect(() => {
    socket.emit("get online users");
    socket.on("online users", async (onlineUserIds) => {
      const filteredUserIds = onlineUserIds.filter((uid) => uid !== user.uid);
      setOnlineUsers(filteredUserIds);
      try {
        const userDetailsArray = await Promise.all(filteredUserIds.map(async (uid) => {
          const userDoc = await getDoc(doc(db, "users", uid));
          return userDoc.exists() ? { uid, ...userDoc.data() } : null;
        }));
        setUserDetails(userDetailsArray.filter((user) => user !== null));
      }
      catch (error) { console.error("Error fetching users:", error); }
    });
  }, [user.uid, socket]);


  // If no users are online, don't render anything
  if (userDetails.length === 0) {
    return null;
  }
  return (
    <>
      {/* Card follow START */}
      <div className="col-sm-6 col-lg-12">
        <div className="card m-1">
          {/* Card header START */}
          <div className="d-flex  justify-content-center align-items-center bg-transparent  p-1 border-0">
            {userDetails.length > 0 && <h6 className="pt-2">Currently Online</h6>}
          </div>
          {/* Card header END */}
          {/* Card body START */}
        </div>
        <div className="card-body">
          <ul className="list-group list-group-flush list-unstyled ">
            {userDetails.map((userData) => (
              /* Connection item START */
              <div
                className="d-flex m-1"
                style={{
                  display: "inline-flex",
                  gap: "6px",
                  cursor: "pointer",
                }}
                key={userData.uid}
              >
                {/* Avatar */}
                <div className="avatar status-online">
                  <Link to={`/userProfile/${userData.uid}`}>
                    <ProfilePicture
                      userData={userData}
                      className="avatar-img rounded"
                    />
                  </Link>
                </div>
                {/* Title */}
                <Link
                  to={`/userProfile/${userData.uid}`}
                  className="card w-100 d-flex align-items-center justify-content-center "
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <div className="ms-3 w-100 d-flex justify-content-between">
                    <div className="d-flex flex-grow-1">
                      <p className="small mb-0">
                        <b>{userData?.userName}</b>
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
              /* Connection item END */
            ))}
          </ul>
        </div>
        {/* Card body END */}
      </div>
      {/* Card follow END */}
    </>
  );
}
