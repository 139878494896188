import React, { useState } from "react";
import { serverTimestamp, addDoc } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { collection } from "firebase/firestore";
import ProfilePicture from "../UserProfile/ProfilePicture";
import { useFetchUserData } from "../hooks/user/useFetchUserData";

export default function AddCommentBlog({ blog, setCommentCount, user, type }) {
  const [input, setInput] = useState("");
  const { userData } = useFetchUserData(user?.uid);
  // console.log("blog", blog);
  const handleSubmit = async (e) => {
    if (!user || !userData) return;

    e.preventDefault();

    if (input) {
      if (type === "comment") {
        const blogRef = collection(db, "blogs", blog.id, "comments");

        const commentObj = {
          content: input,
          timestamp: serverTimestamp(),
          userId: user?.uid,
          blogId: blog?.id,
        };
        // Create a new document with a specific id in the comments collection
        await addDoc(blogRef, commentObj);
        setInput("");
        setCommentCount((prev) => prev + 1);
      } else if (type === "reply") {
        const commentRef = collection(blog?.reference, blog?.id, "replies");
        const replyObj = {
          content: input,
          timestamp: serverTimestamp(),
          userId: user.uid,
          parentCommentId: blog?.id,
        };
        await addDoc(commentRef, replyObj);
        setInput("");
        setCommentCount((prev) => prev + 1);
      }
    }
  };

  return (
    <>
      {/* Add comment */}
      <div className="d-flex pe-2 mb-3">
        <div className="avatar avatar-sm me-2">
          <ProfilePicture
            userData={userData}
            className="avatar-img rounded-2"
          />
        </div>
        {/* Comment box  */}
        <form
          className="nav nav-item w-100 position-relative"
          onSubmit={handleSubmit}
          aria-label="comment form" // Add an accessible label here
        >
          <textarea
            data-autoresize
            className="form-control bg-light rounded-2"
            rows="1"
            placeholder="Leave a reply"
            value={input}
            style={{ scrollbarWidth: "none" }}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSubmit(e);
              }
            }}
          ></textarea>

          <button
            className="nav-link bg-transparent px-3 position-absolute top-50 end-0 translate-middle-y border-0"
            type="submit"
          >
            <i className="bi bi-send-fill"> </i>
          </button>
        </form>
      </div>
    </>
  );
}
