import React, { useEffect, useState } from "react";
import { useFetchUserData } from "../../hooks/user/useFetchUserData";
import formatTimestamp from "../../lib/formatTimestamp";
import useSocketStore from "../../StatesStore/Socket";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../FirebaseConfig";
import { UserAuth } from "../../Context";
import ProfilePicture from "../../UserProfile/ProfilePicture";

function PostComment({ notification }) {
  const { userData } = useFetchUserData(notification?.id[0]);
  const [isSeen, setIsSeen] = useState(notification.seen);
  const { user } = UserAuth();
  const navigate = useNavigate();
  const [isRecipientOnline, setIsRecipientOnline] = useState(false);
  const socket = useSocketStore((state) => state.socket);

  useEffect(() => {
    if (userData?.userId && socket) {
      // Listen for status updates for the specific firstUser
      const handleUserStatus = ({ userId, status }) => {
        if (userId === userData?.userId) {
          setIsRecipientOnline(status === 'online');
        }
      };

      // Register the listener
      socket.on('user status', handleUserStatus);

      // Request initial status of the firstUser
      socket.emit('get user status', userData?.userId);

    
    }
  }, [userData?.userId]);

  const handleNavigate = async () => {
    try {
      // Navigate to the dynamic route
      navigate(`/post/${notification.postId}`);

      // Update the 'seen' attribute to true in Firestore
      if (!isSeen) {
        setIsSeen(true); 
        const notificationRef = doc(db, 'users', user?.uid, 'notifications', notification.notificationId);
        await updateDoc(notificationRef, { seen: true });
        
      }
    } catch (error) {
      console.error('Error updating notification: ', error);
    }
  };
  return (
    <li onClick={handleNavigate} style={{ cursor: 'pointer' }}>
      <div
        className={`d-flex m-1 `}
        style={{ display: "inline-flex", gap: "6px", cursor: "pointer" }}
      >
        <div className={`avatar text-center d-none d-sm-inline-block ${isRecipientOnline?"status-online":" "}`}>
          <ProfilePicture userData={userData} className='avatar-img rounded' />
        </div>
        <div className={`card w-100 d-flex align-items-center justify-content-center ${
          isSeen ? '' : 'badge-unread2'
        } `}>
        <div className="ms-2 w-100 d-flex justify-content-between align-items-center p-1">
        <div className="d-flex flex-column flex-grow-1">
              <p className="small mb-1">
                <b>{userData?.userName}</b> commented on your post.
              </p>
              <small  className={`${isSeen ? 'bg-light' : 'bg-white'} rounded p-1`} style={{ 
                  width: 'fit-content',
                  maxWidth: '320px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'block',
  
                }}>
                {notification.comment}
              </small>
            </div>
          <p class="small me-3">{formatTimestamp(notification.timestamp)}</p>
        </div>
        </div>
      </div>
    </li>
  );
}

export default PostComment;
