const InviteUser = async (userDisplayName, displayName, email, code) => {
  const mailOptions = {
    to: email,
    subject: `${userDisplayName} from Lookflock`,
    html: `<p>Hello ${displayName}!</p>
        <p>Your friend ${userDisplayName} is inviting you to join <a href=${code}>Lookflock.com</a>, a social shopping platform for all things fashion.</p>
        <p>As a user, you can:</p>
        <ol>
          <li><b>Discover Fashion Trends:</b> Explore curated collections and find inspiration.</li>
          <li><b>Create & Share Wishlists:</b> Showcase must-haves and get personalized recommendations.</li>
          <li><b>Connect with Our Community:</b> Engage with fellow fashion enthusiasts, bloggers, designers, and stylists.</li>
        </ol>
        <p>Please note that the site is still in development, so you might encounter some incomplete sections and broken functionality.</p>
        <p>We encourage you to share your feedback with us <a href="https://www.lookflock.com/#/contactUs">here</a> or by replying to this email, as it helps us greatly to improve our functionality and provide a better experience for our users.</p>
        <p>Click here to join the fun: ${code}<br>
        <p>Best regards,<br>
        The Lookflock Team<br>
        <a href="https://www.lookflock.com">https://www.lookflock.com</a></p>
        <p>P.S. Don't forget to complete your profile to get the most personalized experience!</p>,`,
  };

  try {
    const response = await fetch(process.env.REACT_APP_API + "/api/sendemail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(mailOptions),
    });

    const data = await response.json();
    console.log("Email sentt:", data.message);
  } catch (error) {
    console.error("Error sending email:", error);
  }
};
const ShoppingListMail = async (
  myName,
  displayName,
  email,
  shoppingListName,
  link,
  productImgUrls,
  profile
) => {
  const mailOptions = {
    to: email,
    subject:
      myName +
      " is inviting you to checkout his " +
      shoppingListName +
      " shopping list",
    html: `<p>Hey ${displayName},</p>
      <p>Checkout my shopping list: ${link}</p>
      <p>Lookflock team</p>
      <a href=${link}>
      <div
    style="margin: 2px;margin-top: 1px;background-color: #f2f4f7;box-shadow: 1px 1px 10px 0px; padding: 20px;border-radius: 10px;width: 50vw;"
  >
    <div style="display: flex;align-items: center;width: 100%;">
      <div style="height: 50px;width: 50px;">
        <a href="${
          link.split("#")[0]
        }#/userProfile/2owZ9Nyd9gauVFtCiGi8W6kHcj72" target="_blank">
          <img
            src=${profile}
            alt=""
            style="cursor: default;object-fit: cover;border-radius: 8px;width: 100%;height: 100%;"
          />
        </a>
      </div>
      <table
        style="background-color: white; width: 100%; border-radius: 10px; border-collapse: collapse; padding: 10px;"
      >
        <tr>
          <td style="padding: 10px; vertical-align: top;">
            <h4 style="margin: 0;">My ${shoppingListName} Shopping List</h4>
            <h5 style="margin: 0; font-weight: lighter;">
              <a
                style="text-decoration: none; color: rgb(103, 106, 121);"
                href="${
                  link.split("#")[0]
                }#/userProfile/2owZ9Nyd9gauVFtCiGi8W6kHcj72"
                >by <i>${myName}</i></a
              >
            </h5>
          </td>
          <td style="padding: 10px; vertical-align: top; text-align: right;">
            <span style="font-size: small;">5 min ago</span>
          </td>
        </tr>
      </table>
    </div>
    <div
      style="padding: 0.5rem;background-color: white;margin: 0.5rem 0;border-radius: 10px;"
    >
      <p>
        Hello! I am posting my favorites shopping list. These are the products
        that I like and want everyone to know about.
      </p>
      <div style="display: flex;overflow-x: auto;">
        ${productImgUrls
          .map(
            (product) =>
              `<div style=" display: inline-block;outline: none;">
          <div
            style="display: flex;flex-direction: column;padding: 0.5rem;padding-bottom: 0;"
          >
            <div style="position: relative;">
              <a
                href="${link.split("#")[0]}#/ProductDetails/${product?.id}"
                style="text-decoration: none;"
              >
                <img
                  style="object-fit: cover;height: 13.5rem;border-radius: 5px;"
                  src=${product?.image}
                />
              </a>
            </div>
          </div>
        </div>`
          )
          .join("")}
      </div>
    </div>
  </div>
  </a>`,
  };

  try {
    const response = await fetch(process.env.REACT_APP_API + "/api/sendemail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(mailOptions),
    });

    const data = await response.json();
    console.log("Email sent:", data.message);
  } catch (error) {
    console.error("Error sending email:", error);
  }
};

const CustomShoppingListMail = async (
  myName,
  displayName,
  email,
  shoppingListName,
  link,
  productImgUrls,
  profile,
  content,
) => {
  const mailOptions = {
    to: email,
    subject:
      myName +
      " is inviting you to checkout his " +
      shoppingListName +
      " shopping list",
    html: `<p>Hey ${displayName} ,</p>
      <p>${content}: ${link}</p>
      <p>Lookflock team</p>
      <a href=${link}>
      <div
    style="margin: 2px;margin-top: 1px;background-color: #f2f4f7;box-shadow: 1px 1px 10px 0px; padding: 20px;border-radius: 10px;width: 50vw;"
  >
    <div style="display: flex;align-items: center;width: 100%;">
      <div style="height: 50px;width: 50px;">
        <a href="${
          link.split("#")[0]
        }#/userProfile/2owZ9Nyd9gauVFtCiGi8W6kHcj72" target="_blank">
          <img
            src=${profile}
            alt=""
            style="cursor: default;object-fit: cover;border-radius: 8px;width: 100%;height: 100%;"
          />
        </a>
      </div>
      <table
        style="background-color: white; width: 100%; border-radius: 10px; border-collapse: collapse; padding: 10px;"
      >
        <tr>
          <td style="padding: 10px; vertical-align: top;">
            <h4 style="margin: 0;">My ${shoppingListName} Shopping List</h4>
            <h5 style="margin: 0; font-weight: lighter;">
              <a
                style="text-decoration: none; color: rgb(103, 106, 121);"
                href="${
                  link.split("#")[0]
                }#/userProfile/2owZ9Nyd9gauVFtCiGi8W6kHcj72"
                >by <i>${myName}</i></a
              >
            </h5>
          </td>
          <td style="padding: 10px; vertical-align: top; text-align: right;">
            <span style="font-size: small;">5 min ago</span>
          </td>
        </tr>
      </table>
    </div>
    <div
      style="padding: 0.5rem;background-color: white;margin: 0.5rem 0;border-radius: 10px;"
    >
      <p>
        Hello! I am posting my favorites shopping list. These are the products
        that I like and want everyone to know about.
      </p>
      <div style="display: flex;overflow-x: auto;">
        ${productImgUrls
          .map(
            (product) =>
              `<div style=" display: inline-block;outline: none;">
          <div
            style="display: flex;flex-direction: column;padding: 0.5rem;padding-bottom: 0;"
          >
            <div style="position: relative;">
              <a
                href="${link.split("#")[0]}#/ProductDetails/${product?.id}"
                style="text-decoration: none;"
              >
                <img
                  style="object-fit: cover;height: 13.5rem;border-radius: 5px;"
                  src=${product?.image}
                />
              </a>
            </div>
          </div>
        </div>`
          )
          .join("")}
      </div>
    </div>
  </div>
  </a>`,
  };

  try {
    const response = await fetch(process.env.REACT_APP_API + "/api/sendemail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(mailOptions),
    });

    const data = await response.json();
    console.log("Email sent:", data.message);
  } catch (error) {
    console.error("Error sending email:", error);
  }
};
const WelcomeUser = async (displayName, email) => {
  const mailOptions = {
    to: email,
    subject: "Welcome to Lookflock - Social Shopping!",
    text: `Hello ${displayName}!\n
        Welcome to Lookflock.com, a social shopping platform for all things fashion. We are thrilled to have you onboard.\n
        As a user, you can:\n
        1. Discover Fashion Trends: Explore curated collections and find inspiration.\n
        2. Create & Share Wishlists: Showcase must-haves and get personalized recommendations.\n
        3. Connect with Our Community: Engage with fellow fashion enthusiasts, bloggers, designers, and stylists.\n
        Please note that the site is still in development, so you might encounter some incomplete sections and broken functionality.\n
        We encourage you to share your feedback with us here or by replying to this email, as it helps us greatly to improve our functionality and provide a better experience for our users.\n
        Best regards,\n
        The Lookflock Team\n
        https://www.lookflock.com\n
        P.S. Don't forget to complete your profile to get the most personalized experience!`,
    html: `<p>Hello ${displayName}!</p>
        <p>Welcome to <a href="https://www.lookflock.com">Lookflock.com</a>, a social shopping platform for all things fashion. We are thrilled to have you onboard.</p>
        <p>As a user, you can:</p>
        <ol>
          <li><b>Discover Fashion Trends:</b> Explore curated collections and find inspiration.</li>
          <li><b>Create & Share Wishlists:</b> Showcase must-haves and get personalized recommendations.</li>
          <li><b>Connect with Our Community:</b> Engage with fellow fashion enthusiasts, bloggers, designers, and stylists.</li>
        </ol>
        <p>Please note that the site is still in development, so you might encounter some incomplete sections and broken functionality.</p>
        <p>We encourage you to share your feedback with us <a href="https://www.lookflock.com/#/contactUs">here</a> or by replying to this email, as it helps us greatly to improve our functionality and provide a better experience for our users.</p>
        <p>Best regards,<br>
        The Lookflock Team<br>
        <a href="https://www.lookflock.com">https://www.lookflock.com</a></p>
        <p>P.S. Don't forget to complete your profile to get the most personalized experience!</p>`,
  };

  try {
    const response = await fetch(process.env.REACT_APP_API + "/api/sendemail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(mailOptions),
    });

    const data = await response.json();
    console.log("Email sent:", data.message);
  } catch (error) {
    console.error("Error sending email:", error);
  }
};

module.exports = { InviteUser, WelcomeUser, ShoppingListMail,CustomShoppingListMail };
