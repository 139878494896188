import React from "react";

function Step1b() {
  const step1Image =
    process.env.PUBLIC_URL + "/assets/LandingPage/step1Image.png";

  return (
    <div
      className="row m-0 align-items-md-center"
      style={{ minHeight: "75vh" }}
    >
      <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
        <h1 className="display-6 fw-bold">Discover Your Style</h1>
        <p className="lead">
          Get inspired by the latest trends and find your unique look.
        </p>
      </div>
      <div className="col-12 col-md-6 mt-4 mt-md-0 p-0 d-flex justify-content-center">
        <div className="d-flex flex-column">
          <h5 className="fw-light mb-3 text-center">Style Recommendations</h5>
          <div className=" gap-2 row mx-3 mx-lg-0">
            <button
              className="btn btn-primary col"
              style={{ cursor: "default", whiteSpace: "nowrap" }}
            >
              Summer Evening Gowns
            </button>
            <button
              className="btn btn-primary col"
              style={{ cursor: "default", whiteSpace: "nowrap" }}
            >
              Trendy Hoodies
            </button>
            <button
              className="btn btn-primary col"
              style={{ cursor: "default", whiteSpace: "nowrap" }}
            >
              Classic White Kurtas for Men
            </button>
            <button
              className="btn btn-primary col"
              style={{ cursor: "default", whiteSpace: "nowrap" }}
            >
              Bohemian-style Tunics for Women
            </button>
            <button
              className="btn btn-primary col"
              style={{ cursor: "default", whiteSpace: "nowrap" }}
            >
              Affordable Bridal Dresses
            </button>
            <button
              className="btn btn-primary col"
              style={{ cursor: "default", whiteSpace: "nowrap" }}
            >
              Party wear
            </button>
            <button
              className="btn btn-primary col"
              style={{ cursor: "default", whiteSpace: "nowrap" }}
            >
              Fashion for Young Boys
            </button>
            <button
              className="btn btn-primary col"
              style={{ cursor: "default", whiteSpace: "nowrap" }}
            >
              Winter Coats
            </button>
          </div>
          <div className="mt-4 d-flex justify-content-center">
            <div className="form-control">
              Search for styles inspirations...
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Step1b;
