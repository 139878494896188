import React, { useState, useEffect } from "react";
import {
  doc,
  getDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
} from "firebase/firestore";
import { Spinner } from "react-bootstrap";
import { db } from "../FirebaseConfig";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import useCartStore from "../StatesStore/CartStore";


const AddToCart = ({ user, product }) => {
  const [loading, setLoading] = useState(false);
  const [inCart, setInCart] = useState(false);
  const { addItem, removeItem, cartItems } = useCartStore();

  // Helper functions for localStorage
  const getLocalCart = () => {
    const cart = localStorage.getItem("cart");
    return cart ? JSON.parse(cart) : {};
  };

  const updateLocalCart = (cart) => {
    localStorage.setItem("cart", JSON.stringify(cart));
  };

  // Check if the product is already in the cart
  const checkCartStatus = async () => {
    if (!product?.id) return;

    if (user?.uid) {
      // Firebase check
      const cartRef = doc(db, "users", user.uid, "cart", product.id);
      const cartDoc = await getDoc(cartRef);
      setInCart(cartDoc.exists());
    } else {
      // LocalStorage check
      const cart = getLocalCart();
      setInCart(!!cart[product.id]);
    }
  };

  useEffect(() => {
    checkCartStatus();
  }, [user, product, cartItems]);

  const handleToggleCart = async () => {
    if (!product) return;

    setLoading(true);

    try {
      if (user?.uid) {
        // Firebase functionality
        const cartRef = doc(db, "users", user.uid, "cart", product.id);

        if (inCart) {
          // Remove from cart
          setInCart(false);
          toast.success(`${product.name} removed from cart`, toastOptions);
          await deleteDoc(cartRef);
          removeItem(product.id);
        } else {
          // Add to cart
          setInCart(true);
          toast.success(`${product.name} added to cart`, toastOptions);
          await setDoc(cartRef, {
            quantity: 1,
            timestamp: serverTimestamp(),
          });
          addItem({ id: product.id, name: product.name, quantity: 1 });
        }
      } else {
        // LocalStorage functionality
        const cart = getLocalCart();

        if (inCart) {
          // Remove from cart
          delete cart[product.id];
          setInCart(false);
          toast.success(`${product.name} removed from cart`, toastOptions);
          removeItem(product.id);
        } else {
          // Add to cart
          cart[product.id] = {
            id: product.id,
            name: product.name,
            quantity: 1,
          };
          setInCart(true);
          toast.success(`${product.name} added to cart`, toastOptions);
          addItem(cart[product.id]);
        }

        updateLocalCart(cart);
      }
    } catch (error) {
      console.error("Error updating cart:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Link
      className={`nav-link ${loading ? "disabled" : ""} `}
      onClick={handleToggleCart}
    >
      {inCart ? (
        <i className="bi bi-cart-fill fs-6"></i> // Filled icon if in cart
      ) : (
        <i className="bi bi-cart fs-6"></i> // Outline icon if not in cart
      )}
    </Link>
  );
};

export default AddToCart;
