import React from "react";

function ShippingFormInputs({ register, errors }) {
  return (
    <>
      <div>
        <label>First Name</label>
        <input {...register("firstName")} className="form-control" />
        {errors.firstName && <small className="text-danger">{errors.firstName.message}</small>}
      </div>

      <div>
        <label>Last Name</label>
        <input {...register("lastName")} className="form-control" />
        {errors.lastName && <small className="text-danger">{errors.lastName.message}</small>}
      </div>

      <div>
        <label>Email</label>
        <input {...register("email")} className="form-control" type="email" />
        {errors.email && <small className="text-danger">{errors.email.message}</small>}
      </div>

      <div>
        <label>Country</label>
        <select {...register("country")} className="form-control" disabled>
          <option value="Pakistan">Pakistan</option>
        </select>
      </div>

      <div>
        <label>Phone Number</label>
        <input {...register("phoneNumber")} className="form-control" type="tel" />
        {errors.phoneNumber && <small className="text-danger">{errors.phoneNumber.message}</small>}
      </div>

      <div>
        <label>Address</label>
        <input {...register("address")} className="form-control" />
        {errors.address && <small className="text-danger">{errors.address.message}</small>}
      </div>

      <div>
        <label>City</label>
        <input {...register("city")} className="form-control" />
        {errors.city && <small className="text-danger">{errors.city.message}</small>}
      </div>

      <div>
        <label>Payment Method</label>
        <select {...register("paymentMethod")} className="form-control" disabled>
          <option value="Cash on Delivery">Cash on Delivery</option>
        </select>
      </div>
    </>
  );
}

export default ShippingFormInputs;
