const groupBySupplier = (products) => {
  return products.reduce((grouped, product) => {
    if (product.checked) {
      const supplier = product.supplier;
      if (!grouped[supplier]) {
        grouped[supplier] = [];
      }
      grouped[supplier].push(product);
    }
    return grouped;
  }, {});
};

export default groupBySupplier;
