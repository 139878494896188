import React from "react";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { toast } from "react-toastify";

import { shippingFormSchema } from "./lib/shippingFormSchema";
import groupBySupplier from "./lib/groupBySupplier";
import ShippingFormInputs from "./ShippingFormInputs";
import { UserAuth } from "../Context";
import { toastOptions } from "../lib/toastOptions";
import useUserDataStore from "../StatesStore/UserData";


function ShippingForm({ products }) {
  const { user } = UserAuth();
  const userData = useUserDataStore((state) => state.userData);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(shippingFormSchema),
    defaultValues: {
      firstName: userData?.firstName || "",
      lastName: userData?.lastName || "",
      email: userData?.email || "",
      phoneNumber: userData?.phoneNumber || "",
      address: "",
      city: "",
      country: "Pakistan",
      paymentMethod: "Cash on Delivery",
    },
  });

  const onSubmit = async (data) => {
    const groupedProducts = groupBySupplier(products);

    for (const supplier in groupedProducts) {
      const supplierProducts = groupedProducts[supplier];
      const orderPayload = {
        order: {
          line_items: supplierProducts.map((product) => ({
            product_id: product.id,
            title: product.name,
            price: product.newPrice,
            quantity: product.quantity,
            vendor: product.supplier,
            properties: {
              "Product URL": `${product.url}`,
            },
          })),
          transactions: [
            {
              kind: "authorization",
              status: "pending",
              amount: supplierProducts.reduce(
                (total, product) => total + product.newPrice * product.quantity,
                0
              ),
            },
          ],
          currency: "PKR",
          customer: {
            first_name: data.firstName,
            last_name: data.lastName,
            email: data.email,
            phone: data.phoneNumber,
            addresses: [
              {
                address1: data.address,
                city: data.city,
                country: "Pakistan",
                phone: data.phoneNumber,
                first_name: data.firstName,
                last_name: data.lastName,
              },
            ],
          },
          shipping_address: {
            first_name: data.firstName,
            last_name: data.lastName,
            address1: data.address,
            city: data.city,
            country: "Pakistan",
          },
        },
      };

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API}/api/createOrder/${user?.uid}`,
          orderPayload
        );
        console.log(`Order for ${supplier} successfully created:`, response.data);
        toast.success(`Order for ${supplier} successfully created`, toastOptions);
      } catch (error) {
        console.error(`Error creating order for ${supplier}:`, error);
        toast.error(`Failed to create order for ${supplier}`, toastOptions);
      }
    }

    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="vstack gap-3 p-3" style={{ border: "1px solid #ddd", borderRadius: "8px" }}>
      <ShippingFormInputs register={register} errors={errors} />
      <button type="submit" className="btn btn-primary mt-3">
        Complete Order
      </button>
    </form>
  );
}

export default ShippingForm;
