import React, { useEffect, useState } from 'react';
import formatTimestamp from '../../lib/formatTimestamp';
import { useNavigate } from 'react-router-dom';
import ProfilePicture from '../../UserProfile/ProfilePicture';
import { db } from "../../FirebaseConfig";
import { doc, updateDoc } from 'firebase/firestore';
import useSocketStore from '../../StatesStore/Socket';

function NotificationItem({ notification, message, route,firstUserData,user }) {

  const navigate = useNavigate();
  const [isSeen, setIsSeen] = useState(notification.seen);
  const [isRecipientOnline, setIsRecipientOnline] = useState(false);
  const socket = useSocketStore((state) => state.socket);

  useEffect(() => {
    if (firstUserData?.userId && socket) {
      // Listen for status updates for the specific firstUser
      const handleUserStatus = ({ userId, status }) => {
        if (userId === firstUserData?.userId) {
          setIsRecipientOnline(status === 'online');
        }
      };

      // Register the listener
      socket.on('user status', handleUserStatus);

      // Request initial status of the firstUser
      socket.emit('get user status', firstUserData?.userId);

    
    }
  }, [firstUserData?.userId]);

  const handleNavigate = async () => {
    try {
      // Navigate to the dynamic route
      navigate(route);

      // Update the 'seen' attribute to true in Firestore
      if (!isSeen) {
        setIsSeen(true); 
        const notificationRef = doc(db, 'users', user?.uid, 'notifications', notification.notificationId);
        await updateDoc(notificationRef, { seen: true });
        
      }
    } catch (error) {
      console.error('Error updating notification: ', error);
    }
  };

  return (
    <li onClick={handleNavigate} style={{ cursor: 'pointer' }}>
         <div
        className={`d-flex m-1 `}
        style={{ display: "inline-flex", gap: "6px", cursor: "pointer" }}
      >
        <div className={`avatar text-center d-none d-sm-inline-block ${isRecipientOnline?"status-online":" "}`} >
          <ProfilePicture userData={firstUserData} className='avatar-img rounded' />
        </div>
        <div className={`card w-100 d-flex align-items-center justify-content-center ${
          isSeen ? '' : 'badge-unread2'
        } `}>
          <div className="ms-2 w-100 d-flex justify-content-between align-items-center p-1">
          <div className="d-flex flex-grow-1">
            <p className="small mb-0">
              <b>{firstUserData?.userName}</b>{message}
            </p>
          </div>
         
          <div className="small me-3">{formatTimestamp(notification.timestamp)}</div>
          </div>
        </div>
      </div>
    </li>
  );
}

export default NotificationItem;
