import React, { useEffect, useState } from "react";
import { collection, getDocs, query, where, orderBy } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import OneItemNews from "../NewsFeed/OneItemNews";
import ThreeItemNews from "../NewsFeed/ThreeItemNews";
import { useParams } from "react-router-dom";
import EmptyBox from "../components/EmptyBox";
import LoadingSpinner from "./LoadingSpinner";

export default function UserPosts({ userData, user, componentName }) {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  const { uid } = useParams();
  // console.log("posts", posts);
  useEffect(() => {
    if (uid) {
      const fetchPosts = async () => {
        try {
          const postsRef = collection(db, "posts");
          const q = query(
            postsRef,
            where("userId", "==", uid),
            orderBy("dateCreated", "desc")
          );
          const querySnapshot = await getDocs(q);
          const posts = [];

          for (const docSnap of querySnapshot.docs) {
            const postData = { ...docSnap.data() };

            // If the post type is image, fetch images from the media subcollection
            if (postData.type === "image") {
              const mediaRef = collection(db, "posts", docSnap.id, "media");
              const mediaSnapshot = await getDocs(mediaRef);
              const images = mediaSnapshot.docs
                .slice(1, mediaSnapshot.size)
                .map((mediaDoc) => mediaDoc.data().url);
              postData.images = [postData.thumbnailUrl, ...images];
            }

            posts.push({ ...postData, id: docSnap.id });
          }
          // console.log("posts", posts);
          if (posts.length > 0) {
            setPosts(posts);
          }
        } catch (error) {
          console.error("Error fetching posts:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchPosts();
    }
  }, [uid]);

  return (
    <>
      <div className={`${componentName === "Posts" ? "" : "mt-2"}`}>
        {loading ? (
          <LoadingSpinner />
        ) : posts.length === 0 ? (
          <EmptyBox
            filename={
              user?.uid === uid
                ? "Your posts will be shown here"
                : `Stay tuned for ${userData?.userName}'s first post!`
            }
          />
        ) : (
          posts.map((post, index) => (
            <div key={index}>
              {post.images?.length === 1 && <OneItemNews post={post} />}
              {post.images?.length >= 2 && (
                <ThreeItemNews post={post} componentName={"Posts"} />
              )}
            </div>
          ))
        )}
      </div>
    </>
  );
}
