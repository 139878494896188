import React, { useEffect, useState } from "react";
import { Adsense } from "@ctrl/react-adsense";

const GoogleAdCard4 = () => {



  // return (
  //   <div
  //     style={{
  //       width: "224px",
  //       height: "260px",
  //       display: "block",
  //       // marginBottom: "4px",
  //       // marginTop: "4px",
  //       // padding: "4px",
  //       // paddingLeft:'7px',
  //       marginLeft:'9px',
  //       marginBottom:'12px',
  //       backgroundColor: "#f1f1f1", // Light gray background
  //       // border: "1px solid #ddd", // Subtle border
  //       borderRadius: "8px", // Rounded corners
  //       boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
  //     }}
  //     className=""
  //   >
  //     {/* Blank space */}
  //   </div>
  // );
  return (
    <Adsense
      style={{
        width: "224px",
        height: "255px",
        display: "block",
        marginLeft:'9px',
        marginBottom:'12px',
      }}
      client="ca-pub-6917679702799024"
      slot="4646940608"
      format=""
    />
  );
};

export default GoogleAdCard4;
