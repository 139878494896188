import React from "react";

function Step4() {
  const step4 = process.env.PUBLIC_URL + "/assets/LandingPage/step4.png";
  const step4Mobile =
    process.env.PUBLIC_URL + "/assets/LandingPage/step4Mobile.png";

  return (
    <>
      <div className="d-block d-md-none w-100">
        <img src={step4Mobile} className="mx-auto" alt="Step4 GIF" />
      </div>
      <div className="d-none d-md-block">
        <img src={step4} alt="Step4 GIF" />
      </div>
    </>
  );
}

export default Step4;
