import React, { useState } from "react";
import useModalStore from "../StatesStore/ModalStore";

import {
  Whatsapp,
  Facebook,
  PlusSquare,
  DashSquare,
  EnvelopeCheck,
  Link45deg,
  CheckSquare,
  CheckSquareFill,
} from "react-bootstrap-icons";
import { Form, InputGroup, Modal, Button, FormControl } from "react-bootstrap";
import { message } from "antd";
import { UserAuth } from "../Context";
import { saveInviteToDb } from "../lib/saveInviteToDb";
import { checkIfEmailExists } from "../lib/checkIfEmailExists";
import { Link } from "react-router-dom";
import ProfilePicture from "../UserProfile/ProfilePicture";
const { InviteUser } = require("../EmailSystem/sendEmails");

function Invite() {
  const { data, title, closeModal } = useModalStore();
  const [Error, setError] = useState("");
  const { user } = UserAuth();
  const [emailUsers, setEmailUsers] = useState([{ name: "", email: "" }]);
  const [phoneUsers, setPhoneUsers] = useState([{ cc: "", phone: "" }]);
  const text = "Invite Friends";
  const regex = /^[a-zA-Z\s]*$/;
  const myEmail = user?.providerData[0].email;
  const isFirstEmailUserEmpty =
    emailUsers[0].name === "" &&
    emailUsers[0].email === "" &&
    emailUsers.length === 1;
  const isFirstPhoneUserEmpty =
    phoneUsers[0].cc === "" &&
    phoneUsers[0].phone === "" &&
    phoneUsers.length === 1;
  const modified = data.replace(
    "http://localhost:3000",
    "https://lookflock.com"
  );
  const [inviteOption, setInviteOption] = useState("email");
  const googleLogoPath =
    process.env.PUBLIC_URL + "/assets/images/logo/google-logo.svg";
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  // DummyContacts
  const [contacts, setContacts] = useState([
    {
      photo: "",
      userName: "Asad M.Furqan",
      email: "muhammadasad98980@gmail.com",
      phoneNumber: "+923001234567",
      isSelected: false,
    },
    {
      photo: "",
      userName: "Osama Siddique",
      email: "osama.siddiqui2017@gmail.com",
      phoneNumber: "+923001234567",
      isSelected: false,
    },
    {
      photo: "",
      userName: "Abdul Sami",
      email: "abdulsami786125@gmail.com",
      phoneNumber: "+923001234567",
      isSelected: false,
    },
    {
      photo: "",
      userName: "Fahad Ausaf",
      email: "fahad.ausaf@gmail.com",
      phoneNumber: "+923001234567",
      isSelected: false,
    },
  ]);
  const onFinishPhone = (values) => {
    console.log("onFinishPhone values", values);
    // Logic to handle phone number invitations
    values.forEach(async (userData) => {
      const { name, cc, phone } = userData;
      console.log(`Invite to phone number: ${phone} for ${name}`);
      // Add the phone invite sending logic here
      await saveInviteToDb(
        {
          phone: `${cc}${phone}`,
          type: "phone",
        },
        user
      );
    });
    message.success("Invitations sent successfully!");
    closeModal();
  };
  const checkEmails = async (emailUsers) => {
    const emailSet = new Set();
    emailSet.add(myEmail);
    console.log("emailSet", emailUsers);
    for (const person of emailUsers) {
      if (person.email === myEmail) {
        setError("Cannot email yourself");
        return false; // Email is same as user's email
      }
      if (emailSet.has(person.email)) {
        setError("Emails should be unique");
        return false; // Duplicate found
      }
      const emailExists = await checkIfEmailExists(person.email);
      if (emailExists.exists) {
        const handleLinkClick = () => {
          closeModal(); // Close the modal when the link is clicked
        };

        const userLink = (
          <Link
            to={`/userProfile/${emailExists.userId}`}
            onClick={handleLinkClick}
          >
            {emailExists.firstName}
          </Link>
        );

        // Set the error state with the clickable link
        setError(
          <>
            {person.email} is already on Lookflock. Add {userLink}.
          </>
        );

        return false;
      }

      emailSet.add(person.email);
    }
    return true;
  };
  const onEmailFinish = (values) => {
    values.forEach(async (userData) => {
      const { name, email } = userData;
      // console.log(name, email);
      InviteUser(user?.displayName, name, email, data).then((response) => {
        message.success({
          content: `Email sent successfully to ${email}`,
          key: "success",
        });
        closeModal();
      });
      // Save email invite to Firestore
      await saveInviteToDb(
        {
          name,
          email,
          type: "email",
        },
        user
      ).catch((error) => {
        message.error(`Error sending email to ${email}`);
        console.error("Error sending email:", error);
      });
    });
  };

  const handleCopyLink = () => {
    const fullLink = data.replace(
      "http://localhost:3000",
      "https://lookflock.com"
    );
    navigator.clipboard.writeText(fullLink); // Copy link to clipboard
    message.success({
      content: `Link copied`,
      key: "success",
    });
  };

  const handleFacebookShare = () => {
    const shareQuote = encodeURIComponent(text);
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=https://lookflock.com/#/ProductDetails/${text}&quote=${shareQuote}`
    );
  };

  const handleWhatsappShare = () => {
    window.open(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(
        `${text}: ${data}`
      )}`
    );
  };
  const handleAddEmailUser = () => {
    setEmailUsers([...emailUsers, { name: "", email: "" }]);
  };

  const handleRemoveEmailUser = (index) => {
    const newEmailUsers = [...emailUsers];
    if (newEmailUsers.length > 1) {
      newEmailUsers.splice(index, 1);
      setEmailUsers(newEmailUsers);
    } else if (newEmailUsers.length === 1) {
      setEmailUsers([{ name: "", email: "" }]);
    }
  };
  const handleEmailChange = (index, field, value) => {
    const newEmailUsers = [...emailUsers];
    setError("");
    newEmailUsers[index][field] = value;
    setEmailUsers(newEmailUsers);
  };
  const handleAddPhoneUser = () => {
    setPhoneUsers([...phoneUsers, { cc: "", phone: "" }]);
  };

  const handleRemovePhoneUser = (index) => {
    const newPhoneUsers = [...phoneUsers];
    if (newPhoneUsers.length > 1) {
      newPhoneUsers.splice(index, 1);
      setPhoneUsers(newPhoneUsers);
    } else if (newPhoneUsers.length === 1) {
      setPhoneUsers([{ cc: "", phone: "" }]);
    }
  };

  const handlePhoneChange = (index, field, value) => {
    const newPhoneUsers = [...phoneUsers];
    newPhoneUsers[index][field] = value;
    setEmailUsers(newPhoneUsers);
  };
  const handleCheckboxChange = (index) => {
    // Create a new array to avoid mutating state directly
    const updatedContacts = [...contacts];
    // Toggle the checked value for the specific user
    updatedContacts[index].isSelected = !updatedContacts[index].isSelected;
    // Update the state with the new user list
    setContacts(updatedContacts);
  };
  const handleSubmitUsingGoogleContacts = async () => {
    let values = [];
    if (inviteOption === "email") {
      contacts.map((contact) => {
        if (contact.isSelected) {
          values.push({ name: contact.userName, email: contact.email });
        }
      });
      setEmailUsers(values);
      if (await checkEmails(values)) {
        onEmailFinish(values);
      }
    } else if (inviteOption === "phone") {
      // contacts.map((contact) => {
      //   if (contact.isSelected) {
      //     values.push({ cc: contact.userName, phone: contact.phoneNumber });
      //   }
      // });
      // setPhoneUsers(values);
      // if (await checkEmails(values)) {
      //   onEmailFinish(values);
      // }
    }
  };
  return (
    <Modal show={true} centered onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{text}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-0 pb-0">
        {/* Invite via email */}
        <ul className="nav nav-bottom-line px-3">
          <li className="nav-item">
            <Link
              className={`nav-link ${inviteOption === "email" ? "active" : ""}`}
              onClick={() => {
                setInviteOption("email");
                setIsContactModalOpen(false);
              }}
            >
              <span className="d-none d-sm-block">Invite by Email</span>
              <span className="d-block d-sm-none">Using Email</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${inviteOption === "link" ? "active" : ""}`}
              onClick={() => {
                setInviteOption("link");
                setIsContactModalOpen(false);
              }}
            >
              <span className="d-none d-sm-block">Invite by Link</span>
              <span className="d-block d-sm-none">Copy link</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${inviteOption === "phone" ? "active" : ""}`}
              onClick={() => {
                setInviteOption("phone");
                setIsContactModalOpen(false);
              }}
            >
              <span className="d-none d-sm-block">Invite by Phone number</span>
              <span className="d-block d-sm-none">Using Phone</span>
            </Link>
          </li>
        </ul>
        <div className="mt-4 px-3 pb-3">
          {inviteOption === "email" ? (
            <div>
              <Form
                onSubmit={async (e) => {
                  e.preventDefault();
                  if (await checkEmails(emailUsers)) {
                    onEmailFinish(emailUsers);
                  }
                }}
              >
                {emailUsers.map((user, index) => (
                  <InputGroup
                    key={index}
                    className="mb-1 d-flex justify-content-center align-items-center"
                  >
                    <Form.Control
                      className="me-1 rounded h-50"
                      placeholder="Name"
                      value={user.name}
                      onChange={(e) => {
                        if (regex.test(e.target.value)) {
                          handleEmailChange(index, "name", e.target.value);
                          setError("");
                        } else {
                          setError(
                            "Name cannot contain numbers or special characters"
                          );
                        }
                      }}
                      required
                    />
                    <Form.Control
                      className="ms-1 rounded h-50"
                      type="email"
                      placeholder="Email"
                      value={user.email}
                      onChange={(e) => {
                        handleEmailChange(index, "email", e.target.value);
                      }}
                      required
                    />
                    <button
                      className="btn border-0 mx-2 px-0 py-1"
                      onClick={() => handleRemoveEmailUser(index)}
                      disabled={isFirstEmailUserEmpty}
                    >
                      <DashSquare className="fs-4" />
                    </button>
                    <button
                      className="btn border-0 mx-2 px-0 py-1"
                      onClick={handleAddEmailUser}
                    >
                      {/* <PlusCircle type="submit" /> */}
                      <PlusSquare type="submit" className="fs-4 " />
                    </button>
                  </InputGroup>
                ))}
                <span className="ms-2 text-danger">{Error}</span>

                <div className="d-flex justify-content-end">
                  <Button
                    className="rounded d-flex align-items-center"
                    type="submit"
                    variant="primary-soft"
                    size="sm"
                  >
                    <EnvelopeCheck className="fs-6 me-2" />
                    <span>Invite</span>
                  </Button>
                </div>
              </Form>
            </div>
          ) : inviteOption === "phone" ? (
            <div>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  onFinishPhone(phoneUsers);
                }}
              >
                {phoneUsers.map((user, index) => (
                  <InputGroup
                    key={index}
                    className="mb-3 flex justify-content-center align-items-center"
                  >
                    <Form.Control
                      type="number"
                      value={user.cc}
                      className="me-1 rounded h-50"
                      style={{ width: "0.5rem" }}
                      name="cc"
                      placeholder="+92"
                      onChange={(e) =>
                        handlePhoneChange(index, "cc", e.target.value)
                      }
                      required
                    />
                    <Form.Control
                      type="number"
                      className="mx-1 rounded h-50 w-50"
                      name="phone"
                      placeholder="Phone"
                      value={user.phone}
                      onChange={(e) =>
                        handlePhoneChange(index, "phone", e.target.value)
                      }
                    />
                    <button
                      className="btn border-0 mx-2 px-0 py-1"
                      onClick={() => handleRemovePhoneUser(index)}
                      disabled={isFirstPhoneUserEmpty}
                    >
                      <DashSquare className="fs-4 " />
                    </button>
                    <button
                      className="btn border-0 mx-2 px-0 py-1"
                      onClick={handleAddPhoneUser}
                    >
                      <PlusSquare className="fs-4 " />
                    </button>
                  </InputGroup>
                ))}
                <div className="d-flex justify-content-end">
                  <Button
                    className="rounded d-flex align-items-center"
                    type="submit"
                    variant="primary-soft"
                    size="sm"
                  >
                    <EnvelopeCheck className="fs-6 me-2" />
                    <span>Invite</span>
                  </Button>
                </div>
              </Form>
            </div>
          ) : (
            <div>
              <InputGroup className="mb-3">
                <Form.Control
                  className="rounded me-1 text-mute"
                  value={modified}
                  readOnly
                />
                <Button
                  className="rounded mx-1 "
                  variant="primary-soft"
                  size="sm"
                  onClick={handleCopyLink}
                >
                  <Link45deg className="fs-6 me-1" />
                  <span>Copy Link</span>
                </Button>
              </InputGroup>
              <p
                className="mb-0"
                style={{
                  textAlign: "center",
                  color: "gray",
                }}
              >
                Anyone with this link can join Lookflock
              </p>
            </div>
          )}
        </div>
      </Modal.Body>
      {/* {(inviteOption === "email" || inviteOption === "phone") && (
        <div>
          <div className="d-flex justified-content-center align-items-center">
            <hr className="w-50 m-0" />
            <div className="mx-2">OR</div>
            <hr className="w-50 m-0" />
          </div>
          {!isContactModalOpen ? (
            <div className="mb-3 mt-2 d-flex justify-content-center">
              <button
                className="btn mx-4 border border-primary"
                onClick={() => setIsContactModalOpen(true)}
              >
                <img
                  src={googleLogoPath}
                  className="mx-1"
                  alt="Google"
                  style={{
                    width: "25px",
                    height: "25px",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
                <span>Invite Using Google Contacts</span>
              </button>
            </div>
          ) : (
            <div className="mb-3 mt-2 px-3">
              <div className="d-flex justify-content-between align-items-center">
                <div className="googleName">
                  <img
                    src={googleLogoPath}
                    className="mx-1"
                    alt="Google"
                    style={{
                      width: "25px",
                      height: "25px",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  />
                  <span>Google Contacts</span>
                </div>
                <div className="searchBar">
                  <div className="position-relative">
                    <FormControl
                      className="ps-5"
                      type="search"
                      name="input"
                      placeholder="Search..."
                      aria-label="Search"
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                    <Button
                      variant="mode"
                      type="submit"
                      className="bg-transparent pe-0 ps-3 py-0 position-absolute top-50 start-0 translate-middle-y"
                    >
                      <i className="bi bi-search fs-5"></i>
                    </Button>
                  </div>
                </div>
              </div>
              <div
                className="my-2"
                style={{ maxHeight: "14.5rem", overflowY: "scroll" }}
              >
                {contacts.map((contact, index) => {
                  if (
                    contact.userName
                      .toLowerCase()
                      .includes(searchInput.toLowerCase())
                  )
                    return (
                      <div className="d-flex my-3 justify-content-between align-items-center me-3">
                        <div className="d-flex">
                          <div className="avatar">
                            <ProfilePicture
                              userData={contact}
                              className={"rounded-2"}
                            />
                          </div>
                          <div className="mx-2 d-flex flex-column justify-content-center">
                            <h6 className="fw-semibold mb-0">
                              {contact.userName}
                            </h6>
                            <h6 className="fw-light mb-0">
                              {inviteOption === "email"
                                ? contact.email
                                : contact.phoneNumber}
                            </h6>
                          </div>
                        </div>
                        <div className="checkBox">
                          <button
                            class="btn p-0"
                            for="btncheck1"
                            onClick={() => handleCheckboxChange(index)}
                          >
                            {contact.isSelected ? (
                              <CheckSquareFill />
                            ) : (
                              <CheckSquare />
                            )}
                          </button>
                        </div>
                      </div>
                    );
                })}
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  className="rounded d-flex align-items-center"
                  type="submit"
                  variant="primary-soft"
                  size="sm"
                  onClick={handleSubmitUsingGoogleContacts}
                >
                  <EnvelopeCheck className="fs-6 me-2" />
                  <span>Invite</span>
                </Button>
              </div>
            </div>
          )}
        </div>
      )} */}
      {inviteOption === "link" && (
        <Modal.Footer className="d-flex justify-content-center m-0 pb-2 p-0">
          <div>
            <div className="btn me-2" onClick={handleWhatsappShare}>
              <Whatsapp style={{ fontSize: "28px", color: "#00CF08" }} />
            </div>
            <div className="btn" onClick={handleFacebookShare}>
              <Facebook style={{ fontSize: "28px", color: "#0057DA" }} />
            </div>
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default Invite;
