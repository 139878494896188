import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ThreeDotsVertical } from "react-bootstrap-icons";
import { Dropdown } from "react-bootstrap";
import HeaderMenu from "./HeaderMenu";
import { UserAuth } from "../Context";
import useModalStore from "../StatesStore/ModalStore";
import BrandsMenu from "./BrandsMenu";
import HeaderMenu4 from "./HeaderMenu4";
import CategoryMenu3 from "./CategoryMenu3";

function SecondHeader2() {
  const location = useLocation();
  const [option, setOption] = useState("NewsFeed");
  const [openBrand, setOpenBrand] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const { user } = UserAuth();
  const { openModal } = useModalStore();

  const handleOutsideClick = () => {
    const brand = document.getElementById("brandsCollapse");
    if (brand.classList.contains("show")) {
      brand.classList.remove("show");
    }
    const menCategory = document.getElementById("menCollapse");
    if (menCategory.classList.contains("show")) {
      menCategory.classList.remove("show");
    }
    const womenCategory = document.getElementById("womenCollapse");
    if (womenCategory.classList.contains("show")) {
      womenCategory.classList.remove("show");
    }
  };
  return (
    <>
      <nav className="navbar navbar-light navbar-expand-md d-block shadow-sm mb-0 secondHeader2">
        <style jsx>
          {`
            .secondHeader2 .navbar-nav .nav-link {
              line-height: 2.5;
            }
            .secondHeader2 {
              background-color: var(--bs-heading-color, inherit);
            }
            .secondHeader2 .nav-link {
              color: var(--bs-body-bg, inherit) !important;
            }
            .secondHeader2 .nav-link.active {
              color: var(--bs-navbar-active-color) !important;
            }
            .secondHeader2 .nav-link:hover {
              color: var(--bs-navbar-active-color) !important;
            }
            .allLinks::-webkit-scrollbar {
              display: none;
            }
            .allLinks {
              -ms-overflow-style: none; /* IE and Edge */
              scrollbar-width: none; /* Firefox */
            }
          `}
        </style>
        <div className="container w-100">
          {/* <div className="col-lg-20"></div> */}
          <ul
            className="d-flex d-md-none flex-row justify-content-between align-items-center gap-4 navbar-nav w-100 allLinks"
            style={{ overflow: "scroll", whiteSpace: "nowrap" }}
          >
            {/* <li className="nav-item">
              <Link
                className={`nav-link text-white2 ${location.pathname === "/" &&
                  "active"}`}
                to={user ? "/" : "#"}
                style={{ fontSize: "0.9rem" }}
                onClick={(e) => {
                  if (!user) {
                    e.preventDefault(); // Prevent routing
                    openModal("SignInModal", "", ""); // Show the SignIn modal
                    return;
                  }
                  setOption("NewsFeed");
                  setOpenCategory(false);
                  setOpenBrand(false);
                  handleOutsideClick();
                }}
              >
                News Feed
              </Link>
            </li> */}
            <li className="nav-item">
              <Link
                className={`nav-link text-white2 ${location.pathname ===
                  "/discover" && "active"}`}
                to={"/discover"}
                style={{ fontSize: "0.9rem" }}
                aria-current="page"
                onClick={() => {
                  setOption("Discover");
                  setOpenBrand(false);
                  setOpenCategory(false);
                  handleOutsideClick();
                }}
              >
                Discover
              </Link>
            </li>
            <li className="nav-item">
              <button
                type="button"
                style={{ fontSize: "0.9rem" }}
                data-bs-toggle="collapse"
                data-bs-target="#menCollapse"
                aria-label="Brands & Categories navigation"
                className={`nav-link text-white2 btn text-decoration-none"
                aria-controls="menCollapse`}
                aria-expanded="false"
                onClick={() => {
                  setOption("Men");
                  handleOutsideClick();
                }}
              >
                Men
              </button>
              <div
                id="menCollapse"
                className="collapse navbar-collapse rounded-2 rounded-top-0"
                style={{ zIndex: 1 }}
              >
                <HeaderMenu4 category={"Men"} />
                {/* <HeaderMenu type={"category"} /> */}
              </div>
            </li>
            <li className="nav-item">
              <button
                type="button"
                style={{ fontSize: "0.9rem" }}
                data-bs-toggle="collapse"
                data-bs-target="#womenCollapse"
                aria-label="Brands & Categories navigation"
                className={`nav-link text-white2 btn text-decoration-none"
                aria-controls="womenCollapse`}
                aria-expanded="false"
                onClick={() => {
                  setOption("Women");
                  handleOutsideClick();
                }}
              >
                Women
              </button>
              <div
                id="womenCollapse"
                className="collapse navbar-collapse rounded-2 rounded-top-0"
                style={{ zIndex: 1 }}
              >
                <HeaderMenu4 category={"Women"} />
                {/* <HeaderMenu type={"category"} /> */}
              </div>
            </li>
            <li className="nav-item">
              <button
                type="button"
                style={{ fontSize: "0.9rem" }}
                data-bs-toggle="collapse"
                data-bs-target="#brandsCollapse"
                aria-label="Brands & Categories navigation"
                className={`nav-link text-white2 btn text-decoration-none`}
                aria-controls="brandsCollapse"
                aria-expanded="false"
                onClick={() => {
                  setOption("Brands");
                  handleOutsideClick();
                }}
              >
                Brands
              </button>
              <div
                id="brandsCollapse"
                className="pb-0 collapse navbar-collapse rounded-2 rounded-top-0"
                style={{ zIndex: 1 }}
              >
                <HeaderMenu type={"brand"} />
              </div>
            </li>
            <li className="nav-item">
              <Link
                style={{ fontSize: "0.9rem" }}
                className={`dropdown-item nav-link fw-light text-white2 ${
                  location.pathname === "/staticPages/termsAndConditions"
                    ? "active"
                    : ""
                }`}
                to={"/staticPages/termsAndConditions"}
              >
                Terms of Service
              </Link>
            </li>
            <li className="nav-item">
              <Link
                style={{ fontSize: "0.9rem" }}
                className={`dropdown-item nav-link fw-light text-white2 ${
                  location.pathname.includes("/contactUs") ? "active" : ""
                }`}
                to={"/staticPages/contactUs"}
              >
                Contact us
              </Link>
            </li>
            <li className="nav-item">
              <Link
                style={{ fontSize: "0.9rem" }}
                className={`dropdown-item nav-link fw-light text-white2 ${
                  location.pathname === "/staticPages/privacyPolicy"
                    ? "active"
                    : ""
                }`}
                to={"/staticPages/privacyPolicy"}
              >
                Privacy Policy
              </Link>
            </li>
          </ul>
          <ul className="d-none d-md-flex flex-row justify-content-between align-items-center navbar-nav w-100 ">
            <div className="d-flex gap-4">
              {/* <li className="nav-item">
                <Link
                  className={`nav-link text-white2 ps-0 ${location.pathname ===
                    "/" && "active"}`}
                  to={user ? "/" : "#"}
                  onClick={(e) => {
                    if (!user) {
                      e.preventDefault(); // Prevent routing
                      openModal("SignInModal", "", ""); // Show the SignIn modal
                      return;
                    }
                    setOption("NewsFeed");
                    setOpenCategory(false);
                    setOpenBrand(false);
                    handleOutsideClick();
                  }}
                >
                  News Feed
                </Link>
              </li> */}
              <li className="nav-item">
                <Link
                  className={`nav-link text-white2 ${location.pathname ===
                    "/discover" && "active"}`}
                  to={"/discover"}
                  aria-current="page"
                  onClick={() => {
                    setOption("Discover");
                    setOpenBrand(false);
                    setOpenCategory(false);
                    handleOutsideClick();
                  }}
                >
                  Discover
                </Link>
              </li>
              <li className="nav-item dropdown ">
                <div
                  className="nav-link text-white2 dropdown-toggle"
                  style={{ cursor: "pointer" }}
                  id="menMenu"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Men
                </div>
                <CategoryMenu3 category={"Men"} />
              </li>
              <li className="nav-item dropdown ">
                <div
                  className="nav-link text-white2 dropdown-toggle"
                  style={{ cursor: "pointer" }}
                  id="womenMenu"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Women
                </div>
                <CategoryMenu3 category={"Women"} />
              </li>
              <li className="nav-item dropdown ">
                <div
                  className="nav-link text-white2 dropdown-toggle"
                  style={{ cursor: "pointer" }}
                  id="brandMenu"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Brands
                </div>
                <BrandsMenu />
              </li>
              {/* <style jsx>
              {`
                .navDropDown .dropdown-toggle {
                  background: none;
                  border: none;
                }
                .navDropDown .dropdown-toggle:after {
                  display: none;
                }
              `}
            </style>
            <Dropdown align="end" className="nav-item navDropDown">
              <Dropdown.Toggle className="nav-link text-white2 px-1">
                <ThreeDotsVertical onClick={() => handleOutsideClick()} />
              </Dropdown.Toggle>
              <Dropdown.Menu className="h6 bg-white">
                <Link
                  className={`dropdown-item text-white2 ${
                    location.pathname === "/staticPages/termsAndConditions"
                      ? "active"
                      : ""
                  }`}
                  to={"/staticPages/termsAndConditions"}
                >
                  Terms of Service
                </Link>
                <Link
                  className={`dropdown-item text-white2 ${
                    location.pathname === "/staticPages/contactUs"
                      ? "active"
                      : ""
                  }`}
                  to={"/staticPages/contactUs"}
                >
                  Contact us
                </Link>
                <Link
                  className={`dropdown-item text-white2 ${
                    location.pathname === "/staticPages/privacyPolicy"
                      ? "active"
                      : ""
                  }`}
                  to={"/staticPages/privacyPolicy"}
                >
                  Privacy Policy
                </Link>
              </Dropdown.Menu>
            </Dropdown> */}
            </div>
            <div className="d-flex">
              <li className="nav-item">
                <Link
                  style={{ fontSize: "0.8rem" }}
                  className={`px-2 nav-link text-white2 fw-light pt-1 ${location.pathname ===
                    "/staticPages/contactUs" && "active"}`}
                  to={"/staticPages/contactUs"}
                  aria-current="page"
                  onClick={() => {
                    setOption("ContactUs");
                    setOpenBrand(false);
                    setOpenCategory(false);
                  }}
                >
                  Contact us
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  style={{ fontSize: "0.8rem" }}
                  className={`px-2 nav-link text-white2 fw-light pt-1 ${location.pathname ===
                    "/staticPages/termsAndConditions" && "active"}`}
                  to={"/staticPages/termsAndConditions"}
                  aria-current="page"
                  onClick={() => {
                    setOption("TOS");
                    setOpenBrand(false);
                    setOpenCategory(false);
                  }}
                >
                  Terms & Conditions
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  style={{ fontSize: "0.8rem" }}
                  className={`ps-2 nav-link text-white2 fw-light pt-1 ${location.pathname ===
                    "/staticPages/privacyPolicy" && "active"}`}
                  to={"/staticPages/privacyPolicy"}
                  aria-current="page"
                  onClick={() => {
                    setOption("PP");
                    setOpenBrand(false);
                    setOpenCategory(false);
                  }}
                >
                  Privacy Policy
                </Link>
              </li>
            </div>
          </ul>
          {/* <div className="col-lg-20"></div> */}
        </div>
      </nav>
    </>
  );
}

export default SecondHeader2;
