import React, { useEffect, useRef, forwardRef } from "react";
import { doc, updateDoc, increment } from "firebase/firestore"; // Firestore imports
import { db } from "../FirebaseConfig";

const VideoCard2 = forwardRef(({ url, inView, post }, ref) => {
  const videoRef = useRef(null);
  const timeoutRef = useRef(null);
  const hasViewBeenIncremented = useRef(false);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      if (videoRef.current) {
        if (inView) {
          videoRef.current.play().catch((error) => {
            console.log("Error playing video:", error);
          });
        } else {
          videoRef.current.pause();
        }
      }
    }, 100); // debounce time in ms

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [inView]);

  useEffect(() => {
    const handleFullscreenChange = async () => {
      if (document.fullscreenElement === videoRef.current) {
        try {
          const postRef = doc(db, "posts", post.id); // Firestore document reference
          await updateDoc(postRef, {
            views: increment(1),
          });
          console.log("Post view count updated (fullscreen)");
        } catch (error) {
          console.error("Error updating post view count:", error);
        }
      }
    };

    const handleTimeUpdate = async () => {
      const video = videoRef.current;
      if (video && !hasViewBeenIncremented.current) {
        const playedPercentage = (video.currentTime / video.duration) * 100;
        if (playedPercentage >= 50 ) {
          hasViewBeenIncremented.current = true; 
          try {
            const postRef = doc(db, "posts", post.id); 
            await updateDoc(postRef, {
              views: increment(1),
            });
            console.log("Post view count updated (50% watched)",post.id,hasViewBeenIncremented.current);
            
          } catch (error) {
            console.error("Error updating post view count:", error);
          }
        }
      }
    };

    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener("fullscreenchange", handleFullscreenChange);
      videoElement.addEventListener("timeupdate", handleTimeUpdate);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("fullscreenchange", handleFullscreenChange);
        videoElement.removeEventListener("timeupdate", handleTimeUpdate);
      }
    };
  }, [post.id]);

  // Inline styles
  const wrapperStyle = {
    position: "relative",
  };

  const videoStyle = {
    width: "100%",
    borderRadius: "7px",
    display: "inline",
  };

  return (
    <div style={wrapperStyle} ref={ref}>
      <video
        ref={videoRef}
        style={videoStyle}
        controls
        muted
        crossOrigin="anonymous"
        poster=""
      >
        <source src={url} type="video/mp4" />
      </video>
    </div>
  );
});

export default VideoCard2;
