import React, { useState, useEffect, useRef } from "react";
import {
  collection,
  addDoc,
  query,
  orderBy,
  getDoc,
  doc,
  where,
  onSnapshot,
  serverTimestamp,
  getDocs,
  updateDoc,
  setDoc,
  increment,
} from "firebase/firestore";
import Picker from "emoji-picker-react";
import { Link } from "react-router-dom";
import { db } from "../FirebaseConfig";
import { formatTimestampForPost } from "../lib/formatTimestampForPost";
import { UserAuth } from "../Context";
import ProfilePicture from "../UserProfile/ProfilePicture";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import useNotification from "../hooks/useNotification";
import ProductCard3 from "../Products/ProductCard3";
import useSocketStore from "../StatesStore/Socket";
import { saveListMessageToDb } from "./lib/saveListMessageToDb";
import useUserDataStore from "../StatesStore/UserData";
import { formatTimestampChat } from "../Chat/lib.js/formatTimestampChat";
import { decryptChat, encryptChat } from "./Encryption";
import useChatClient from "../StatesStore/ChatClient";
import { Chat, Channel, ChannelHeader, MessageInput, MessageList, Thread, Window, ChannelList } from "stream-chat-react";
const ListComments = ({
  shoppingListData,
  setShoppingListData,
  selectedShopList,
  userId,
  listId,
  role,
  notify,
  invitedBy,
  ownerId,
  show,
  toggleChat
}) => {
  console.log("selectedShopList from listComments", selectedShopList);
  console.log("selectedShopList from listComments invitedBy", selectedShopList, invitedBy);
  console.log("shoppingListData from listComments", shoppingListData);
  console.log("shoppingListData", shoppingListData);
  // console.log("role from listComments", role);
  const endRef = useRef();
  const emojiPickerRef = useRef();
  const commentRef = useRef();
  const list = shoppingListData.find((list) => list.name === selectedShopList);
  const dateCreated = new Date(list?.dateCreated * 1000);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const formattedDate = dateCreated.toLocaleDateString("en-US", options);
  // const [recentCommentTime, setRecentCommentTime] = useState(null);
  const [commentText, setCommentText] = useState("");
  const [comments, setComments] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const { user } = UserAuth();
  const [showComments, setShowComments] = useState(false);
  const { addNotification } = useNotification();
  const [productData, setProductData] = useState(null);
  const [listMembers, setListMembers] = useState(null);
  const socket = useSocketStore((state) => state.socket);
  const userData = useUserDataStore((state) => state.userData);
  const client = useChatClient((state) => state.client);
  const [channel, setChannel] = useState(null);

  useEffect(() => {
    const initializeChannel = async () => {
      if (!client || !listId || !listMembers) return;
      console.log('listMembers', listMembers);

      try {
        const channel = client.channel('team', listId, {
          name: selectedShopList,
          members: listMembers
        });

        await channel.watch();

        // Listen for new messages
        channel.on('message.new', (event) => {
          const newMessage = {
            content: event.message.text,
            senderId: event.user.id,
            userName: event.user.name,
            photo: event.user.image,
            timestamp: new Date(event.created_at).getTime(),
            type: event.message.type || "text",
            productData: event.message.productData,
            productDocID: event.message.productDocID
          };

          setComments(prevComments => [...prevComments, newMessage]);
        });

        setChannel(channel);
      } catch (error) {
        console.error("Error initializing channel:", error);
      }
    };

    initializeChannel();

    return () => {
      if (channel) {
        channel.stopWatching();
      }
    };
  }, [client, listId, listMembers]);




  useEffect(() => {
    const fetchMemberDocumentIds = async () => {
      if (!userId || !selectedShopList) return;

      try {
        // Reference to the members collection for the selected shopping list of the user
        const membersRef = collection(
          db,
          "users",
          ownerId,
          "shoppingList",
          selectedShopList,
          "members"
        );

        // Fetch all documents in the members collection
        const membersSnapshot = await getDocs(membersRef);

        if (!membersSnapshot.empty) {
          // Get all the document IDs
          const memberDocumentIds = membersSnapshot.docs.map((doc) => doc.id);
          setListMembers([...memberDocumentIds, ownerId]);
          console.log("Member document IDs:", memberDocumentIds);
          // You can now do something with these IDs (e.g., set them in state, etc.)
        } else {
          setListMembers([ownerId]);
          console.log("No members found in this shopping list.");
        }
      } catch (error) {
        console.error("Error fetching member document IDs:", error);
      }
    };

    fetchMemberDocumentIds();
  }, [userId, selectedShopList]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const text = commentText;
    setCommentText("");

    if (role === "viewer") {
      toast.warn("You do not have permission to post a comment.", toastOptions);
      return;
    }

    try {
      const encryptedChat = encryptChat(text);
      const msgData = {
        text: text,
        // user_id: userId,
        // user: {
        //   id: userId,
        //   name: userData?.userName,
        //   image: userData?.photo
        // },
        // type: productData ? "product" : "text",
      };

      if (productData) {
        msgData.productData = productData;
      }

      if (channel) {
        const response = await channel.sendMessage(msgData);

        if (productData) {
          const product = await addDoc(
            collection(db, "chats", listId, "messages", response.message.id, "product"),
            { ...productData }
          );

          await channel.updateMessage({
            ...response.message,
            productDocID: product.id
          });

          setProductData(null);
        }


        if (notify) {
          await addNotification({
            userId: invitedBy,
            id: user?.uid,
            timestamp: serverTimestamp(),
            type: "listChat",
            by: "user",
            fields: {
              listId: listId,
              shopList: selectedShopList,
              commentId: response.message.id,
            },
          });
        }
      }
    } catch (error) {
      console.error("Error adding comment: ", error);
    }
  };
  const fetchComments = async () => {
    if (!channel) return;

    try {
      const response = await channel.query({ messages: { limit: 100 } });

      const formattedMessages = await Promise.all(
        response.messages.map(async (message) => {
          let data = {};
          if (message.type === "product" && message.productDocID) {
            try {
              const productRef = doc(
                db,
                "chats",
                listId,
                "messages",
                message.id,
                "product",
                message.productDocID
              );
              const productSnap = await getDoc(productRef);
              data = productSnap.data();
            } catch (error) {
              console.error(error);
            }
          }

          return {
            id: message.id,
            content: message.text,
            senderId: message.user.id,
            userName: message.user.name,
            photo: message.user.image,
            timestamp: new Date(message.created_at).getTime(),
            type: message.type || "text",
            productData: data,
            productDocID: message.productDocID
          };
        })
      );

      setComments(formattedMessages);
    } catch (error) {
      console.error("Error fetching comments: ", error);
    }
  };


  useEffect(() => {
    function handleClickOutside(event) {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target)
      ) {
        setShowEmojiPicker(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const checkConditions = async () => {
      if (listId) {
        // First condition: Check the 'members' subcollection
        const membersRef = collection(
          db,
          "users",
          userId,
          "shoppingList",
          selectedShopList,
          "members"
        );
        const membersSnapshot = await getDocs(membersRef);
        console.log("membersSnapshot.empty ", membersSnapshot.empty);
        if (!membersSnapshot.empty) {
          setShowComments(true);
          return; // No need to check further if this condition is true
        }

        // Second condition: Check for messages in the subcollection
        const messagesRef = collection(db, "chats", listId, "messages"); // This is a subcollection under the chat document
        const querySnapshot = await getDocs(messagesRef); // Fetch the messages
        console.log("querySnapshot.empty", querySnapshot.empty);
        if (!querySnapshot.empty) {
          setShowComments(true);
          return;
        }
      }
    };

    checkConditions();
    setShowComments(false);
  }, [listId]);
  const handleAddProductToList = async (productID, product) => {
    const userRef = doc(
      db,
      "users",
      userId,
      "shoppingList",
      selectedShopList,
      "products",
      productID
    );
    await setDoc(userRef, {
      size: "",
      timestamp: serverTimestamp(),
      likeCount: 0,
    });
    // Increment the shoppingListCount
    await updateDoc(doc(db, "users", user.uid), {
      shoppingListCount: increment(1),
    });

    let tempListData = shoppingListData;
    tempListData.forEach((list) => {
      if (list.name === selectedShopList) {
        list.products.push(product);
      }
    });
    setShoppingListData(tempListData);
    toast.success(`Product Added to ${selectedShopList} list`, toastOptions);
  };
  useEffect(() => {
    if (commentRef && commentRef.current) {
      commentRef.current.scrollTop = commentRef.current.scrollHeight;
    }
    console.log("comments", comments);
  }, [comments]);

  useEffect(() => {
    if (showComments) fetchComments();
    // eslint-disable-next-line
  }, [listId, showComments, channel]);
  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const addEmoji = (emojiObject) => {
    // Implement logic to add emoji to comment text
    const emoji = emojiObject.emoji;
    setCommentText(commentText + emoji);
  };
  const handleCommentInput = async (e) => {
    setCommentText(e.target.value);
    if (e.target.value.includes("/#/ProductDetails/")) {
      let text = e.target.value;
      // Extract the product code
      text = text.split("/#/ProductDetails/");
      // test case checking for: if no code is written is added after ProductDetails/
      if (text[1] !== "") {
        console.log("FOUNDD", text);
        //If some extra text is used after link then do not fetch again and again for product
        if (text[1].split(" ").length === 1) {
          const productRef = doc(db, "products", text[1].split(" ")[0]);
          const productSnap = await getDoc(productRef);
          if (productSnap.exists()) {
            console.log("FOUNDD", productSnap.data());
            setProductData(productSnap.data());
            if (commentRef && commentRef.current) {
              commentRef.current.scrollTop = commentRef.current.scrollHeight;
            }
          } else setProductData(null);
        }
      }
    } else {
      setProductData(null);
    }
  };
  // const compareTime = (timestamp) => {
  //   if (!recentCommentTime || recentCommentTime !== timestamp) {
  //     setRecentCommentTime(timestamp);
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };
  // if (!showComments) {
  //   return <div></div>;
  // }
  const containerStyle = {
    display: "flex",
    height: "50vh",
    width: "100%",
    overflow: "hidden",
    gap: "10px",
  };

  const channelListStyle = {
    flex: 1,
    maxWidth: "300px",
    borderLeft: "1px solid rounded",
    overflowY: "auto",
    backgroundColor: "#f8f9fa",
    borderRadius: "4px 0 0 4px",
  };

  const channelWindowStyle = {
    flex: 3,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    backgroundColor: "#ffffff",
    borderRadius: "0px 4px 4px 0px",
  };
  return (
    <>
      <style>
        {`
        .str-chat {
          --str-chat__border-radius-circle: 3px; /* Reduced the border radius */
        }
      `}
      </style>
      <Chat client={client} theme="messaging light">
        <div style={containerStyle}>

          <div style={channelWindowStyle}>
            <Channel channel={channel}>
              <Window>
                {/* <ChannelHeader /> */}

                <h5 className="m-0 px-4 pt-2 ">{selectedShopList} Chat</h5>

                <MessageList />
                <MessageInput />
              </Window>
              <Thread />
            </Channel>
          </div>
        </div>
      </Chat>
    </>
  );
};

export default ListComments;
