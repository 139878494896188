import { createContext, useContext, useEffect, useState } from "react";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { auth, db } from "./FirebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import { useLocation, useNavigate } from "react-router-dom";
import io from "socket.io-client";
import useSocketStore from "./StatesStore/Socket";
import useUserDataStore from "./StatesStore/UserData";
import { StreamChat } from 'stream-chat';
import useChatClient from "./StatesStore/ChatClient";
export const InterestContext = createContext({});

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(() => auth.currentUser);
  const [loading, setLoading] = useState(true);
  const [underAge, setUnderAge] = useState(null);
  const [incompleteProfile, setIncompleteProfile] = useState(false);
  const [parentalConsent, setParentalConsent] = useState(null);
  // const setSocket = useSocketStore((state) => state.setSocket);
  // const socket = useSocketStore((state) => state.socket);
  const client = useChatClient((state) => state.client);
  const setClient = useChatClient((state) => state.setClient);
  const setUserData = useUserDataStore((state) => state.setUserData);
  const userData = useUserDataStore((state) => state.userData); 
  const location = useLocation();
  const navigate = useNavigate();
  const [noGenderOrBirthday, setNoGenderOrBirthday] = useState(false);
  const apiKey = 'cd9ebw6rns44';
  
  useEffect(() => {
    if(userData.length === 0) return
    
    const userClient = {
      id: user?.uid,
      name: userData?.userName,
      image: userData?.photo ?? `https://ui-avatars.com/api/?name=${userData?.userName}`,
    };
    // Fetch token from the backend API
    const fetchToken = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/api/msgToken`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({userId:user?.uid} ),
        });

        if (!response.ok) {
          throw new Error(`Error fetching token: ${response.statusText}`);
        }

        const { token } = await response.json();
        
        // Initialize the Stream Chat client using the token
        const chatClient = StreamChat.getInstance(apiKey);
        await chatClient.connectUser(userClient, token);
        
        setClient(chatClient);

      } catch (error) {
        console.error('Error initializing chat client:', error);
      }
    };

    fetchToken();

    // Clean up the chat client on unmount
    return () => {
      if (client) {
        client.disconnectUser();
        console.log('Client disconnected on component cleanup');
        setClient(null);
      }
    };
 
  }, [user,userData]);


  const googleSignIn = () => {
    const googleProvider = new GoogleAuthProvider();
    googleProvider.addScope(
      "https://www.googleapis.com/auth/user.birthday.read"
    );
    signInWithPopup(auth, googleProvider);
  };

  const facebookSignIn = () => {
    const facebookProvider = new FacebookAuthProvider();
    facebookProvider.addScope(
      "https://www.googleapis.com/auth/user.birthday.read"
    );
    signInWithPopup(auth, facebookProvider);
  };

  const logOut = () => {
    if (client) {
      client.disconnectUser();
      setClient(null);
    }
    signOut(auth);
    setUser(null);
    setUnderAge(null);
    setParentalConsent(null);
    setIncompleteProfile(false);
  };

  const fetchUserData = async (uid) => {
    const userDocRef = doc(db, "users", uid);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      setUnderAge(userData.underAge);
      setParentalConsent(userData.parentalConsent);
      setUserData(userData);

      const { location, favBrands, favCategories } = userData;
      console.log("userdoc in context", userData);
      if (!userData?.gender || !userData?.birthday) setNoGenderOrBirthday(true);
      const isLocationIncomplete = !location || location === "";
      const isFavBrandsIncomplete = !favBrands || favBrands.length === 0;
      const isFavCategoriesIncomplete =
        !favCategories || favCategories.length === 0;

      setIncompleteProfile(
        isLocationIncomplete ||
          isFavBrandsIncomplete ||
          isFavCategoriesIncomplete
      );
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      setLoading(false);

      if (currentUser) {
        await fetchUserData(currentUser.uid);

        if (
          !location?.pathname?.includes("staticPages") &&
          !currentUser.emailVerified
        ) {
          console.log("VerifyEmail is calling me byeeee");
          navigate("/VerifyEmail");
        }
        const userDocRef = doc(db, "users", currentUser?.uid);
        const userDoc = await getDoc(userDocRef);
        const userData = userDoc.data();
        if (!userData?.gender || !userData?.birthday) navigate("/SignInInput");
      } else {
      
        setUnderAge(null);
        setParentalConsent(null);
        setIncompleteProfile(false);
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const contextValue = {
    user,
    underAge,
    incompleteProfile,
    parentalConsent,
    googleSignIn,
    facebookSignIn,
    logOut,
    loading,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(AuthContext);
};
