import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ProfilePicture from "../UserProfile/ProfilePicture";
import { doc, getDoc, serverTimestamp, writeBatch } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import formatTimestamp, { formatTimestampForPost } from "../lib/formatTimestamp";
import useNotification from "../hooks/useNotification";

export const HandleAcceptConnectionReq = async (senderId, receiverId) => {
  const { addNotification, deleteNotification } = useNotification();
  try {
    // Start a batched write to perform multiple updates atomically
    const batch = writeBatch(db);

    // Reference to the connections subcollection for both the sender and receiver
    const senderConnectionRef = doc( db,"users",senderId,"connections",receiverId);
    const receiverConnectionRef = doc(db,"users",receiverId,"connections",senderId);
    const timestamp = serverTimestamp();
    // Update the connection status for the sender
    batch.update(senderConnectionRef, {
      type: "active",
      timestamp,
    });

    // Update the connection status for the receiver
    batch.update(receiverConnectionRef, {
      type: "active",
      timestamp,
    });

    // Commit the batched write
    await batch.commit();
    console.log("receiverId "+receiverId+"and senderId from handle accept req ",senderId);
    
    await deleteNotification({
      userId: senderId,
      id: receiverId,
      type: "friendRequest",
      by: "user",
    });
    await addNotification({
      userId: senderId,
      id: receiverId,
      type: "acceptFriendRequest",
      by: "user",
    });
    console.log("Friend request accepted successfully!");
  } catch (error) {
    console.error("Error accepting connection request: ", error);
  }
};

export const HandleRejectConnectionReq = async (senderId, receiverId) => {
  const { deleteNotification } = useNotification();
  try {
    // Start a batched write to perform multiple updates atomically
    const batch = writeBatch(db);

    // Reference to the connections subcollection for both the sender and receiver
    const senderConnectionRef = doc( db,"users",senderId,"connections",receiverId);
    const receiverConnectionRef = doc(db,"users",receiverId,"connections",senderId);

    // Delete the pending connection documents from both users
    batch.delete(senderConnectionRef);
    batch.delete(receiverConnectionRef);

    // Commit the batched write
    await batch.commit();
    await deleteNotification({
      userId: senderId,
      id: receiverId,
      type: "friendRequest",
      by: "user",
    });
    console.log("Connection request rejected successfully!");
  } catch (error) {
    console.error("Error rejecting connection request: ", error);
  }
};

export function ConnectionRequest({ pendingList, loggedInUser,connectionsTimestamps }) {
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    if (!pendingList || pendingList.length === 0) {
      // If it's null or empty, you can either set the userData to an empty array or keep the existing data
      setUserData([]);
      return; // Prevents further execution of the useEffect
    }

    const fetchUserData = async () => {
      try {
        const userList = await Promise.all(
          pendingList.map(async (userId) => {
            const docSnap = await getDoc(doc(db, "users", userId));
            if (docSnap.exists()) {
              return { ...docSnap.data(), uid: docSnap.id };
            } else {
              console.log("No such document!");
              return null;
            }
          })
        );

        // Filter out any null values if a document wasn't found
        const filteredUserList = userList.filter((user) => user !== null);
        console.log("Fetched users:", filteredUserList);
        setUserData(filteredUserList);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUserData();
  }, [pendingList]);

  return (
    <>
   <h5 className="card-title px-4 p-0 mb-2">
  {pendingList.length || 0} Connection Request{pendingList.length === 1 ? "" : "s"}
</h5>

      {/* Connections Item */}
      {userData.map((user) => {
        return (
          <div className="d-md-flex align-items-center mb-4 px-4">
            {/* Avatar */}
            <div className="avatar me-3 mb-3 mb-md-0">
              <Link className="avatar-img rounded-circle">
                <ProfilePicture userData={user} avatarSize={42} />
              </Link>
            </div>
            {/* Info */}
            <div className="w-100">
              <div className="d-sm-flex align-items-start">
                <h6 className="mb-0">
                  <Link to={`/userProfile/${user.uid}`} className="h6 mb-0">
                    {user.userName
                      ? user.userName
                          .split(" ")
                          .slice(0, 2)
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")
                      : ""}
                  </Link>
                </h6>
                <p className="small ms-sm-2 mb-0">
                  {connectionsTimestamps[user.uid]
                    ? formatTimestamp(connectionsTimestamps[user.uid])
                    : "N/A"}
                </p>
              </div>
              {/* Connections START */}
            </div>
            {/* Button */}
            <div className="ms-md-auto d-flex">
              <button
                className="btn btn-danger-soft btn-sm mb-0 me-2"
                onClick={() =>
                  HandleRejectConnectionReq(user.uid, loggedInUser)
                }
              >
                Reject
              </button>
              <button
                className="btn btn-primary-soft btn-sm mb-0"
                onClick={() =>
                  HandleAcceptConnectionReq(user.uid, loggedInUser)
                }
              >
                Accept
              </button>
            </div>
          </div>
        );
      })}
    </>
  );
}
