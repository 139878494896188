import React, { useEffect, useState } from "react";
import { Adsense } from "@ctrl/react-adsense";

const GoogleAdCard3 = () => {



  return (
    <Adsense
      style={{
        width: "238px",
        height: "250px",
        display: "block",
        marginBottom: "4px",
        marginTop: "4px",
      }}
      client="ca-pub-6917679702799024"
      slot="1093374879"
      format=""
    />
  );
};

export default GoogleAdCard3;
