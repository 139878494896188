import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../FirebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import BrandsMenu from "./BrandsMenuDropdown";
import useSearchInputStore from "../StatesStore/SearchInputStore";
import HeaderMenuSideBar from "./HeaderMenuSideBar";

function HeaderMenu({ type }) {
  const [womenCategories, setWomenCategories] = useState([]);
  const [menCategories, setMenCategories] = useState([]);
  const [activeAccordion, setActiveAccordion] = useState(null);
  const { resetSearchInput } = useSearchInputStore();
  useEffect(() => {
    const getMenu = async () => {
      const data = collection(db, "universalCategories");
      const querySnapshot = await getDocs(data);
      const categoriesData = querySnapshot.docs.map(
        (doc) => doc.data().subCategories
      );
      setWomenCategories(categoriesData[1]);
      setMenCategories(categoriesData[0]);
    };

    getMenu();
  }, []);

  const renderDropdownItems = (categories, gender) => {
    return Object.keys(categories)
      ?.sort()
      ?.map((categoryName) => (
        <li className="dropdown-submenu dropstart " key={categoryName}>
          <button
            className="dropdown-item dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            onClick={(e) => {
              resetSearchInput();
              e.stopPropagation();
            }}
          >
            {categoryName}
          </button>
          <ul className="dropdown-menu dropdown-menu-end" data-bs-popper="none">
            {categories[categoryName]
              .filter(
                (subCategory) =>
                  subCategory.name !== "None" &&
                  subCategory.displayName !== "None"
              )
              .map((subCategory, index) => (
                <li key={index}>
                  <Link
                    className="dropdown-item"
                    to={`/AllProducts/${gender}/${categoryName}/${subCategory.name}`}
                  >
                    {subCategory.displayName}
                  </Link>
                </li>
              ))}
          </ul>
        </li>
      ));
  };
  // console.log(type);
  return (
    <ul className="navbar-nav navbar-nav-scroll w-100 py-2">
      {type === "brand" ? (
        <li className="nav-item h6 fw-light w-100 px-3 py-lg-2  border-0">
          <BrandsMenu />
        </li>
      ) : (
        <li className="nav-item p-2 w-100 categoryMenu p-1 p-lg-3 border-0">
          <HeaderMenuSideBar
            activeAccordion={activeAccordion}
            setActiveAccordion={setActiveAccordion}
          />
        </li>
      )}
    </ul>
  );
}

export default HeaderMenu;
