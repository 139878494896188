// ProfilePicture.js
import React from "react";
import { UserAuth } from "../Context";

const ProfilePicture = ({
  userData,
  avatarSize,
  className,
  location = "",
  onPictureClick,
  isHovered, // Receive isHovered as a prop
  onMouseEnter, // Receive onMouseEnter as a prop
  onMouseLeave, // Receive onMouseLeave as a prop
}) => {
  const { user } = UserAuth();
  if (!userData) {
    return null; // or return a loading state/component
  }
  // console.log("userData?.logoUrl", userData?.logoUrl);
  const avatarInitials = userData?.userName
    ? userData?.userName[0]?.toUpperCase()
    : userData?.displayName?.toUpperCase();

  // Function to handle image load failure
  const handleImageError = (e) => {
    e.target.onerror = null; // Prevents infinite loop in case fallback also fails
    e.target.src = `https://ui-avatars.com/api/?name=${avatarInitials}`;
  };

  const imageStyle = {
    cursor: location === "header" ? "pointer" : "default",
    objectFit: "cover", // Ensures the image covers the area
    borderRadius: "8px", // Applies border radius
  };
  // console.log("userData", userData);
  // console.log("userData2", user);
  const isCurrentUserProfile =
    user?.uid === userData?.uid || user?.uid === userData?.userId;
  if (
    (isCurrentUserProfile ||
      userData.privacySetting === undefined ||
      userData.privacySetting.profilePicture === true) &&
    !userData?.logoUrl
  ) {
    return (
      <img
        className={`avatar-img rounded-2 ${className}`}
        src={
          userData?.photo ||
          `https://ui-avatars.com/api/?name=${avatarInitials}`
        }
        alt=""
        loading="lazy"
        onError={handleImageError}
        onMouseEnter={onMouseEnter} // Use passed down onMouseEnter
        onMouseLeave={onMouseLeave} // Use passed down onMouseLeave
        onClick={location === "header" ? onPictureClick : null}
        // style={isHovered ? { ...imageStyle, opacity: 0.4 } : imageStyle} // Changes opacity on hover
        style={{ cursor: "pointer" }}
      />
    );
  } else if (userData?.logoUrl) {
    return (
      <img
        className={`avatar-img rounded-2 ${className}`}
        // src={process.env.PUBLIC_URL + "/assets/images/brands/" + userData?.logo}
        src={userData?.logoUrl}
        alt=""
        style={{ cursor: "pointer" }}
      />
    );
  } else {
    // Default initials avatar
    return (
      <img
        className={`avatar-img rounded-2 ${className}`}
        src={`https://ui-avatars.com/api/?name=${avatarInitials}`}
        alt=""
        loading="lazy"
        onError={handleImageError}
        onMouseEnter={onMouseEnter} // Use passed down onMouseEnter
        onMouseLeave={onMouseLeave} // Use passed down onMouseLeave
        onClick={location === "header" ? onPictureClick : null}
        style={isHovered ? { ...imageStyle, opacity: 0.4 } : imageStyle} // Changes opacity on hover
      />
    );
  }
};

export default ProfilePicture;
