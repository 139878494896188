import React, { useEffect, useState } from "react";
import { UserAuth } from "../../Context";
import formatTimestamp from "../../lib/formatTimestamp";
import ProfilePicture from "../../UserProfile/ProfilePicture";
import useFollowUser from "../../hooks/useFollowUser";
import { Navigate, useNavigate } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../FirebaseConfig";

function UserFollowedMe({ notification, firstUserData, route }) {
  const navigate = useNavigate();
  const { user } = UserAuth();
  const [isSeen, setIsSeen] = useState(notification.seen);
  const { isFollowed, isFollowBack, followUser, unfollowUser } = useFollowUser(user?.uid, firstUserData?.userId);

  const handleNavigate = async () => {
    try {
      // Navigate to the dynamic route
      navigate(route);

      // Update the 'seen' attribute to true in Firestore
      if (!isSeen) {
        setIsSeen(true);
        const notificationRef = doc(db, 'users', user?.uid, 'notifications', notification.notificationId);
        await updateDoc(notificationRef, { seen: true });

      }
    } catch (error) {
      console.error('Error updating notification: ', error);
    }
  };
  return (
    <li onClick={handleNavigate} style={{ cursor: 'pointer' }} >
      <div
        className={`d-flex m-1 `}
        style={{ display: "inline-flex", gap: "6px", cursor: "pointer" }}
      >
        <div className="avatar text-center d-none d-sm-inline-block"style={{ cursor: 'pointer' }}>
          <ProfilePicture userData={firstUserData} className='avatar-img rounded' />
        </div>
        <div className={`card w-100 d-flex align-items-center justify-content-center ${isSeen ? '' : 'badge-unread2'
          } `}>
          <div className="ms-2 w-100 d-flex justify-content-between align-items-center p-1">
            <div className="d-flex flex-grow-1">
              <p className="small mb-1">
                <b>{firstUserData?.userName}</b> started following you.

                <button onClick={(e) => {
                  e.stopPropagation();
                  isFollowed ? unfollowUser() : followUser();
                }}
                  variant={isFollowed ? 'danger' : 'primary'} className="btn btn-sm py-0 btn-primary m-0"> {isFollowed ? 'Following' : isFollowBack ? 'Follow back' : 'Follow'} </button>
              </p>
        
            </div>
            <div className="small me-3">{formatTimestamp(notification.timestamp)}</div>
          </div>
        </div>
      </div>
    </li>
  );
}

export default UserFollowedMe;
