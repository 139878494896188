import {
  collection,
  doc,
  getCountFromServer,
  getDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../FirebaseConfig";
import BlogComment from "./BlogComment";
import { UserAuth } from "../Context";
import AddCommentBlog from "./AddCommentBlog";
import { formatTimestampForBlog } from "../lib/formatTimestampForBlog";

function BlogDetails() {
  const { id } = useParams();
  const [blog, setBlog] = useState({});
  const { user } = UserAuth();
  const [commentCount, setCommentCount] = useState(0);
  const fetchBlog = async () => {
    const blogRef = doc(db, "blogs", id);
    const blogSnap = await getDoc(blogRef);
    if (blogSnap.exists()) {
      setBlog({ id: id, ...blogSnap.data() });
    }
  };
  function calculateReadingTimeFromHtml(htmlContent, wordsPerMinute = 200) {
    // Create a temporary div element to store the HTML
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;

    // Extract text content from the HTML (strips out the tags)
    const textContent = tempDiv.textContent || tempDiv.innerText || "";

    // Split the text content by spaces to get the word count
    const wordCount = textContent.trim().split(/\s+/).length;

    // Calculate reading time in minutes
    const readingTime = Math.ceil(wordCount / wordsPerMinute);
    const minutes = readingTime > 1 ? "minutes" : "minute";
    return `${readingTime} ${minutes}`;
  }
  useEffect(() => {
    if (!blog) return;
    const getCount = async () => {
      try {
        const blogRef = doc(db, "blogs", blog?.id);
        const commentsCollectionRef = collection(blogRef, "comments");
        const snapshot = await getCountFromServer(commentsCollectionRef);
        setCommentCount(snapshot.data()?.count);
      } catch (error) {
        console.error("Error getting comment count:", error);
      }
    };
    getCount();
  }, [blog]);

  useEffect(() => {
    if (!id) return;
    fetchBlog();
  }, [id]);
  useEffect(() => {
    console.log("blog", blog);
  }, [blog]);

  return (
    // <div className="col-md-8 col-lg-6 vstack gap-2 text-body">
    <div className="vstack gap-2 text-body px-0 ms-0 ms-lg-1">
      <style jsx>
        {`
          .blog-content img {
            width: 50% !important;
          }
        `}
      </style>
      <div className="vstack gap-2 p-0">
        <div className="card card-body">
          <img
            className="rounded-2 w-50 "
            // style={{ height: "20rem" }}
            src={blog?.image}
            alt=""
          />
          <div className="mt-4">
            <a
              href="#"
              className="badge bg-danger bg-opacity-10 text-danger mb-2 fw-bold"
            >
              {blog?.category}
            </a>
            <h1 className="mb-2 h2">{blog?.title}</h1>
            <ul className="nav nav-stack gap-3 align-items-center">
              <li className="nav-item">
                <div className="nav-link">
                  by{" "}
                  <a href="#" className="text-reset btn-link">
                    Lookflock
                  </a>
                </div>
              </li>
              <li className="nav-item">
                {" "}
                <i className="bi bi-calendar-date pe-1"></i>
                {formatTimestampForBlog(blog?.timestamp)}
              </li>
              <li className="nav-item">
                {" "}
                <i className="bi bi-clock pe-1"></i>
                {calculateReadingTimeFromHtml(blog?.blogData)} read
              </li>
            </ul>

            {/* Description in HTML format */}
            <div
              className="blog-content"
              dangerouslySetInnerHTML={{ __html: blog?.blogData }}
            />
          </div>
        </div>
        <div className="card">
          {/* Comment Count */}
          <div className="card-header pb-0 border-0">
            <h4>
              {commentCount > 0 ? `${commentCount} comments` : `0 comment`}{" "}
            </h4>
          </div>
          <div className="card-body pb-1">
            {user && (
              <AddCommentBlog
                blog={blog}
                setCommentCount={setCommentCount}
                user={user}
                type={"comment"}
              />
            )}
            {commentCount > 0 && (
              <BlogComment
                blog={blog}
                commentCount={commentCount}
                setCommentCount={setCommentCount}
                user={user}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogDetails;
