import Glightbox from "glightbox";
import { useEffect } from "react";
import "./Glight.css";

import { Link } from "react-router-dom";


const UserGlightbox = ({ post, image }) => {

  //glight box
  useEffect(() => {
    if(post?.id){
      const lightbox = Glightbox({
        selector: `[data-morepostglightbox1="${post?.id}"]`,
      });
      return () => {
        lightbox.destroy();
      };
    }
  }, [post?.id]);

  return (
    <div>
      <Link
        to={image ? image : ""}
        data-gallery="image-popup"
        className="glightbox"
        data-morepostglightbox1={post?.id}
      >
        <div>
          <img
            className="card-img-top img-fluid rounded-top"
            // style={{ height: "80%" }}
            src={image}
            alt=""
          />  
        </div>
      </Link>
    </div>
  );
};

export default UserGlightbox;
