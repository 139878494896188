import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Image } from "react-bootstrap";
import { doc, getDoc, deleteDoc, setDoc, serverTimestamp, getDocs, collection } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import useShoppingListProductsStore from "../StatesStore/ShoppingListProducts";
import { toastOptions } from "../lib/toastOptions";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const CartModal = ({ listName, userId, listProducts }) => {
  const products = useShoppingListProductsStore((state) => state.getProductData(listName));
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [cartProducts, setCartProducts] = useState({});
  const [selectAll, setSelectAll] = useState(false); // Tracks "Select All" status
  const [isCartModalOpen, setIsCartModalOpen] = useState(false);

  const handleOpenCartModal = () => setIsCartModalOpen(true);
  const handleCloseCartModal = () => setIsCartModalOpen(false);

  useEffect(() => {
    const fetchCartStatus = async () => {
      if (!userId) return;

      try {
        const cartCollectionRef = collection(db, "users", userId, "cart");
        const querySnapshot = await getDocs(cartCollectionRef);
        const cartProductIds = querySnapshot.docs.map((doc) => doc.id);

        const statusMap = listProducts.reduce((acc, product) => {
          acc[product.id] = cartProductIds.includes(product.id);
          return acc;
        }, {});

        setCartProducts(statusMap);
        setSelectedProducts(cartProductIds);
      } catch (error) {
        console.error("Error fetching cart status:", error);
      }
    };

    fetchCartStatus();
  }, [userId, listProducts]);

  const handleSelectProduct = (productId) => {
    setSelectedProducts((prev) =>
      prev.includes(productId) ? prev.filter((id) => id !== productId) : [...prev, productId]
    );
  };

  const handleSelectAll = () => {
    if (selectedProducts.length === products.length) {
      setSelectedProducts([]);
      setSelectAll(false);
    } else {
      setSelectedProducts(products.map((product) => product.id));
      setSelectAll(true);
    }
  };

  const handleMoveToCart = async () => {
    if (!userId) {
      toast.error("User not logged in", toastOptions);
      return;
    }

    try {
      const cartPromises = listProducts.map(async (product) => {
        const cartRef = doc(db, "users", userId, "cart", product.id);
        if (selectedProducts.includes(product.id)) {
          await setDoc(cartRef, { quantity: 1, timestamp: serverTimestamp() });
        } else {
          await deleteDoc(cartRef);
        }
      });

      await Promise.all(cartPromises);

      const updatedCartProducts = products.reduce((acc, product) => {
        acc[product.id] = selectedProducts.includes(product.id);
        return acc;
      }, {});
      setCartProducts(updatedCartProducts);

      toast.success("Cart updated successfully", toastOptions);
      handleCloseCartModal();
    } catch (error) {
      console.error("Error updating cart:", error);
      toast.error("Failed to update cart. Please try again.", toastOptions);
    }
  };

  return (
    <>
      <li className="nav-item my-2" onClick={handleOpenCartModal}>
        <Link className="nav-link icon-md btn btn-light p-0" to="">
          <i className="bi bi-cart-fill fs-6"></i>
        </Link>
        <span className="mx-2 h6 fw-light">Add to cart</span>
      </li>
      <Modal show={isCartModalOpen} onHide={handleCloseCartModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Move Products to Cart</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "60vh", overflowY: "auto" }}>
          <Form onClick={(e) => e.stopPropagation()}>
            <Form.Check
              type="checkbox"
              label="Select All"
              checked={selectedProducts.length === products.length}
              onChange={handleSelectAll}
            />
            <div className="mt-3">
              {products.map((product, index) => (
                <div
                  key={product.id}
                  className={`d-flex align-items-center mb-2 pb-2 ${
                    index !== products.length - 1 ? "border-bottom" : ""
                  }`}
                >
                  <Image
                    src={product.imageUrl || "placeholder-image-url"}
                    alt={product.name}
                    rounded
                    style={{ width: "50px", height: "50px", objectFit: "cover" }}
                    className="me-3"
                  />
                  <div className="flex-grow-1" onClick={(e) => e.stopPropagation()}>
                    <Form.Check
                      type="checkbox"
                      label={product.name}
                      checked={selectedProducts.includes(product.id)}
                      onChange={() => handleSelectProduct(product.id)}
                    />
                  </div>
                </div>
              ))}
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-danger-soft me-2 btn-sm"
            onClick={handleCloseCartModal}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary-soft btn-sm"
            onClick={handleMoveToCart}
          >
            Update cart
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CartModal;

