import React, { useEffect, useState } from "react";
import AllProductsFilterSideNav from "./AllProductsFilterSideNav";

function SideNavAllProducts() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 900);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 900);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="ms-2" style={{ paddingRight: "0px" }}>
      {/* <div
        className="d-flex align-items-center d-lg-none position-fixed opacity-75 m-2"
        style={{ zIndex: "3" }}
      >
        <button
          className="border-0 rounded-3  "
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasAllProductsSideNav"
          aria-controls="offcanvasAllProductsSideNav"
        >
          <span className="btn btn-mode p-2">
            <i className="fa-solid fa-sliders-h"></i>
          </span>
        </button>
      </div> */}
      {/* Advanced filter responsive toggler END */}

      {/* Navbar START */}
      <nav className="navbar mx-0">
        <div
          className="offcanvas offcanvas-end"
          style={{ width: isSmallScreen ? "15rem" : "30rem" }}
          tabIndex="-1"
          id="offcanvasAllProductsSideNav"
        >
          {/* Offcanvas header */}
          <div className="offcanvas-header">
            <button
              type="button"
              className="btn-close text-reset ms-auto"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <AllProductsFilterSideNav />

          {/* Offcanvas body */}
          {/* <div className="offcanvas-body d-block px-2 px-lg-0">
            <BrandsMenu />
          </div> */}
        </div>
      </nav>
      {/* Navbar END */}
    </div>
  );
}

export default SideNavAllProducts;
