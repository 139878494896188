import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ProductCardShoppingList from "../Products/ProductCardShoppingList";
import Comments from "../Comments";
import { db } from "../FirebaseConfig";
import { UserAuth } from "../Context";
import Empty from "../components/Empty";
import {
  getDoc,
  doc,
  updateDoc,
  setDoc,
  collection,
  getDocs,
  serverTimestamp,
  increment,
  where,
  query,
} from "firebase/firestore";
import uuid4 from "uuid4";
import InviteOnList from "../ShoppingList/InviteOnList";
import Settings from "../ShoppingList/Settings";
import SideNavUserProfile from "../SideNav/SideNavUserProfile";

function InvitedList() {
  const [shoppingListData, setShoppingListData] = useState([]);
  const [selectedShopList, setSelectedShopList] = useState("");
  const [listId, setListId] = useState("");
  const [newListName, setNewListName] = useState("");
  const { user } = UserAuth();

  console.log("Shopping list id", listId);

  useEffect(() => {
    const fetchShoppingLists = async () => {
      if (!user) return;

      // Fetch the invited list document
      const invitedListRef = collection(db, "users", user.uid, "invitedList");
      const invitedListDocs = await getDocs(invitedListRef);
      const invitedLists = invitedListDocs.docs.map((doc) => {
        return { listId: doc.id, userId: doc.data().userId };
      });
      console.log("invitedLists", invitedLists);
      // Fetch shopping list details for each invited list
      const shoppingLists = await Promise.all(
        invitedLists.map(async ({ listId, userId }) => {
          // Query to find the document in the shoppingList subcollection where listId matches
          const shoppingListRef = collection(
            db,
            "users",
            userId,
            "shoppingList"
          );
          const q = query(shoppingListRef, where("listId", "==", listId));
          const querySnapshot = await getDocs(q);

          if (querySnapshot.empty) {
            console.log(`No shopping list found for listId: ${listId}`);
            return null;
          }

          const shoppingListSnapshot = querySnapshot.docs[0];
          const shoppingListData = shoppingListSnapshot.data();

          const products = shoppingListData.products
            ? await Promise.all(
                shoppingListData.products.map(async (productId) => {
                  const productRef = doc(db, "products", productId);
                  const productSnapshot = await getDoc(productRef);
                  return {
                    id: productId, // Include the product ID
                    ...productSnapshot.data(), // Include other product details
                  };
                })
              )
            : [];

          return {
            name: shoppingListSnapshot.id,
            dateCreated:
              shoppingListData.dateCreated?.seconds ||
              new Date().getTime() / 1000,
            products,
            listId: shoppingListData.listId,
            sizes: shoppingListData.sizes,
          };
        })
      );

      //   // Filter out any null values from the result
      const filteredShoppingLists = shoppingLists.filter(
        (list) => list !== null
      );

      //   // Sort shopLists by dateCreated in descending order
      filteredShoppingLists.sort((a, b) => b.dateCreated - a.dateCreated);
      setShoppingListData(filteredShoppingLists);

      // Sort shopLists by dateCreated in descending order
      //   shoppingLists.sort((a, b) => b.dateCreated - a.dateCreated);
      //   setShoppingListData(shoppingLists);
    };

    window.scrollTo(0, 0);
    fetchShoppingLists();
  }, [user]);

  useEffect(() => {
    if (shoppingListData.length > 0 && !selectedShopList) {
      // Set selectedShopList only if it's not already set
      setSelectedShopList(shoppingListData[0].name);
      setListId(shoppingListData[0].listId);
    }
  }, [shoppingListData, selectedShopList]);

  const handleshopListClick = (shopListName) => {
    setSelectedShopList(shopListName);
    const selectedShopListData = shoppingListData?.find(
      (shopList) => shopList.name === shopListName
    );
    if (selectedShopListData) {
      setListId(selectedShopListData?.listId);
    }
  };

  const addToSavedList = async (listName) => {
    if (!user) return;

    try {
      const shoppingListRef = collection(db, "users", user.uid, "shoppingList");
      const newListId = listName; // Assuming listName will be the document ID

      // Check if the document already exists with the given listName
      const existingListDoc = await getDoc(doc(shoppingListRef, newListId));
      if (!existingListDoc.exists()) {
        // If the document does not exist, create it with the product ID
        await setDoc(doc(shoppingListRef, newListId), {
          products: [],
          sizes: [],
          dateCreated: serverTimestamp(),
          listId: uuid4(),
          visibility: "private",
        });

        const newShoppingList = {
          name: newListId,
          dateCreated: new Date().getTime() / 1000,
          products: [],
          listId: uuid4(),
          sizes: [],
        };

        setShoppingListData((prevLists) => [...prevLists, newShoppingList]);
        setSelectedShopList(newShoppingList.name);
        setListId(newShoppingList.listId);

        // Update the user's shoppingListCount in Firestore
        const userRef = doc(db, "users", user.uid);
        await updateDoc(userRef, {
          shoppingListCount: increment(1),
        });
      } else {
        console.log("List already exists");
      }
    } catch (error) {
      console.error("Error creating new list: ", error);
    }
  };

  const handleCreateNewList = () => {
    if (!newListName.trim()) {
      console.log("Please enter a valid list name.");
      return;
    }
    addToSavedList(newListName.trim());
    setNewListName("");
  };

  // const handleDeleteList = async () => {
  //   if (!user || !selectedShopList) return;

  //   try {
  //     const shoppingListRef = doc(
  //       db,
  //       "users",
  //       user.uid,
  //       "shoppingList",
  //       selectedShopList
  //     );

  //     await deleteDoc(shoppingListRef);

  //     setShoppingListData((prevLists) =>
  //       prevLists.filter((list) => list.name !== selectedShopList)
  //     );

  //     if (shoppingListData.length > 1) {
  //       setSelectedShopList(shoppingListData[1].name);
  //       setListId(shoppingListData[1].listId);
  //     } else {
  //       setSelectedShopList(null);
  //       setListId(null);
  //     }

  //     // Update the user's shoppingListCount in Firestore
  //     const userRef = doc(db, "users", user.uid);
  //     await updateDoc(userRef, {
  //       shoppingListCount: increment(-1),
  //     });
  //   } catch (error) {
  //     console.error("Error deleting list: ", error);
  //   }
  // };

  return (
    <>
      {/* <SideNavUserProfile /> */}

      <div
        className="col-md-8 col-lg-6 vstack gap-4"
        style={{ paddingLeft: "0px" }}
      >
        <div className="card">
          <div className="card-header d-sm-flex text-center align-items-center justify-content-between border-0 pb-0">
            <div className="">
              <h1 className="card-title h4">Invited Lists</h1>

              <div className="d-flex mt-3">
                <ul className="nav nav-bottom-line align-items-center justify-content-center justify-content-md-start mb-0 border-0">
                  {shoppingListData.map((shopList) => (
                    <li className="nav-item" key={shopList.name}>
                      <Link
                        className={`px-2 nav-link ${
                          selectedShopList === shopList.name ? "active" : ""
                        }`}
                        to=""
                        onClick={() => handleshopListClick(shopList.name)}
                      >
                        {shopList.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              {selectedShopList && (
                <div className="card mt-3">
                  <div className="m-4 d-flex justify-content-between">
                    <h5>{selectedShopList}</h5>
                  </div>

                  <div className="row g-4">
                    {shoppingListData
                      .filter((shopList) => shopList.name === selectedShopList)
                      .flatMap((shopList) =>
                        shopList.products.map((product, index) => (
                          <ProductCardShoppingList
                            key={index}
                            product={product}
                            listName={shopList.name}
                            size={shopList.sizes}
                          />
                        ))
                      )}

                    {!shoppingListData?.find(
                      (shopList) => shopList.name === selectedShopList
                    )?.products?.length && (
                      <div className="text-center p-5">
                        <Empty
                          heading={`Your ${selectedShopList} shopping list is empty`}
                          description="Browse the product categories and click the heart icon to save your favorite items to your shopping list."
                        />
                      </div>
                    )}
                  </div>

                  <Comments listId={listId} userId={user?.uid} />
                </div>
              )}
            </div>

            <div
              className="modal fade"
              id="modalCreateEvents"
              tabIndex="-1"
              aria-labelledby="modalLabelCreateFolder"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header bg-dark">
                    <h5
                      className="modal-title text-white"
                      id="modalLabelCreateFolder"
                    >
                      Create Shopping List
                    </h5>
                    <button
                      type="button"
                      className="btn btn-sm btn-secondary-soft-hover py-1 lh-1"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <i className="fa-solid fa-xmark"></i>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="mb-3">
                        <label className="form-label">Shopping List Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Type shopping list name"
                          value={newListName}
                          onChange={(e) => setNewListName(e.target.value)}
                        />
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-danger-soft me-2"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          className="btn btn-success-soft"
                          data-bs-dismiss="modal"
                          onClick={handleCreateNewList}
                        >
                          Create
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="offcanvas offcanvas-end"
              tabIndex="-1"
              id="offcanvasRight"
              aria-labelledby="offcanvasRightLabel"
            >
              <div className="offcanvas-header">
                <h5 id="offcanvasRightLabel">Edit List</h5>
                <button
                  type="button"
                  className="btn-close text-reset"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body">
                <Settings listId={listId} userId={user?.uid} />
              </div>
            </div>
            <div
              className="offcanvas offcanvas-end"
              tabIndex="-1"
              id="offcanvasRightInvite"
              aria-labelledby="offcanvasRightInviteLabel"
            >
              <div className="offcanvas-header">
                <h5 id="offcanvasRightInviteLabel">
                  Invite Users to Shopping List
                </h5>
                <button
                  type="button"
                  className="btn-close text-reset"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body">
                <InviteOnList listId={listId} userId={user?.uid} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InvitedList;
