import React, { useState, useEffect } from "react";
import { UserAuth } from "../Context";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import { db } from "../FirebaseConfig";
import {
  getDocs,
  collection,
  setDoc,
  doc,
  serverTimestamp,
  getDoc, // Import getDoc to retrieve individual friend data
} from "firebase/firestore";
import uuid4 from "uuid4";
import useModalStore from "../StatesStore/ModalStore";
import { saveMessageToDB } from "../Chat/lib.js/saveMsgToDb";
import useSocketStore from "../StatesStore/Socket";
import { encryptChat } from "../ShoppingList/Encryption";
import useChatClient from "../StatesStore/ChatClient";
import { generateChatId } from "../Chat/lib.js/generateChatId";
import { getBaseURL } from "../lib/getBaseURL";

function AddToChat({ product, socket }) {
  const { user } = UserAuth();
  const [friends, setFriends] = useState([]);
  const [content, setContent] = useState("");
  const [selectedFriends, setSelectedFriends] = useState([]);
  const { openModal } = useModalStore();
  // const socket = useSocketStore((state) => state.socket);
  // Fetch user's active friends and their names
  const client = useChatClient((state) => state.client);
  const fetchFriends = async () => {
    if (!user) return;

    const connectionsRef = collection(db, "users", user.uid, "connections");
    const activeFriendsSnapshot = await getDocs(connectionsRef);
    const activeFriends = activeFriendsSnapshot.docs
      .filter((doc) => doc.data().type === "active")
      .map((doc) => doc.id); // Get only the friend's document ID (friendId)

    // Fetch the userName for each active friend
    const friendsWithNames = await Promise.all(
      activeFriends.map(async (friendId) => {
        const friendDocRef = doc(db, "users", friendId); // Reference to the friend's doc
        const friendDoc = await getDoc(friendDocRef);
        if (friendDoc.exists()) {
          const friendData = friendDoc.data();
          return {
            id: friendId,
            name: friendData.userName, // Get the userName from the document
          };
        } else {
          console.error("No such friend document!");
          return null;
        }
      })
    );

    // Filter out null values (if any friend documents were not found)
    setFriends(friendsWithNames.filter((friend) => friend !== null));
  };

  useEffect(() => {
    if (user) {
      fetchFriends();
    }
  }, [user]);

  // Handle friend selection
  const handleFriendSelect = (friendId) => {
    setSelectedFriends((prevSelected) => {
      if (prevSelected.includes(friendId)) {
        return prevSelected.filter((id) => id !== friendId);
      } else {
        return [...prevSelected, friendId];
      }
    });
  };

  // Save chat message when friends are selected
  const handleSendMessage = async () => {
    if (!selectedFriends.length) {
      toast.warning("Please select at least one friend.", toastOptions);
      return;
    }
    const baseURL = getBaseURL();
    try {
      await Promise.all(
        selectedFriends.map(async (friendId) => {
          const msgData = {
            senderId: user.uid,
            receiverId: friendId,
            content: encryptChat(content),
            productId: product.id,
            type: "product",
          };
          const msgToBeSent = {
            senderId: user.uid,
            receiverId: friendId,
            content: encryptChat(content),
            productId: product.id,
            type: "product",
            details: product,
          };
          // if (socket) {
          //   socket.emit("chat msg", msgToBeSent);
          //   // setMsgs((prevMsgs) => [...prevMsgs, msgToBeSent]);
          // }
          const channelId =  generateChatId(user.uid, friendId)
        
        // Initialize the channel
        const channel = client.channel('messaging', channelId, {
          members: [user.uid, friendId],
        });
        await channel.sendMessage({
          text:` ${baseURL}/#/ProductDetails/${product.id}\n ${content}`,
        });
          await saveMessageToDB(msgData);
        })
      );

      toast.success("Message sent!", toastOptions);
      setSelectedFriends([]); // Reset after sending
    } catch (error) {
      console.error("Error sending message:", error);
      toast.error("Failed to send message. Try again.", toastOptions);
    }
  };

  const handleClick = () => {
    if (!user) {
      openModal("SignInModal", "", "");
    }
  };

  return (
    <div className="d-flex justify-content-between">
      <li className={`nav-link btn  nav-item`}>
        {user ? (
          <>
            <button
              className={`nav-link btn d-flex gap-1 align-items-center bg-default list-unstyled`}
              id="chatActionDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="bi bi-person-plus fs-6"></i>
              {"Ask a Friend"}
              {/* {"Share with friends"} */}
            </button>
            <ul
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="chatActionDropdown"
            >
              <div className="p-2 py-0 text-center">
                <h6>Select Friends</h6>
              </div>
              {friends?.length > 0 ? (
                <>
                  {friends.map((friend) => (
                    <li key={friend.id}>
                      <label className="dropdown-item d-flex gap-2">
                        <input
                          type="checkbox"
                          checked={selectedFriends.includes(friend.id)}
                          onChange={() => handleFriendSelect(friend.id)}
                        />
                        {friend.name} {/* Display the fetched friend name */}
                      </label>
                    </li>
                  ))}
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <div className="input-group d-flex gap-2 p-2">
                      <input
                        type="text"
                        className="form-control form-control-sm focus-ring focus-ring-secondary py-1 px-2 text-decoration-none border rounded-2"
                        placeholder="Type your message"
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                      />
                      <button
                        type="button"
                        className="btn btn-sm btn-primary border rounded-2"
                        onClick={handleSendMessage}
                      >
                        Send
                      </button>
                    </div>
                  </li>
                  {/* <li className="text-center">
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={handleSendMessage}
                >
                  Send Message
                </button>
              </li> */}
                </>
              ) : (
                <>
                  <div className="text-center">You have no friends</div>
                </>
              )}
            </ul>
          </>
        ) : (
          <button
            className={`nav-link btn d-flex gap-1 align-items-center bg-default list-unstyled`}
            onClick={handleClick}
          >
            <i className="bi bi-person-plus fs-6"></i>
            {"Ask a Friend"}
          </button>
        )}
      </li>
    </div>
  );
}

export default AddToChat;
