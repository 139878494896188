import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ShareMenu2 from "../../components/ShareMenu2";
import AddToList from "../../components/AddToList";
import AddOrRemoveLike from "../../Products/AddOrRemoveLike";
import { Card, Spinner } from "react-bootstrap";
import "./style.css";
import { UserAuth } from "../../Context";
import AddOrRemoveFavourites from "../../Products/AddOrRemoveFavourites";
import BrandDisplay from "../../NewsFeed/BrandDisplay";
import formatPrice from "../../lib/formatPrice";
import { getBrandDetails } from "../../lib/getBrandDetails";
import useFetch from "../../hooks/useFetch";
import ProductCardExpanded from "./ProductCardExpanded";
import AddToChat from "../../components/AddToChat";

const TestProductCard2 = ({ product, isStrip, handleClick, isExpanded }) => {
  const [likeCount, setLikeCount] = useState(product?.likes);
  const [brandData, setBrandData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { user } = UserAuth();
  const { data: activeBrands } = useFetch(
    `${process.env.REACT_APP_API}/api/activeBrands`
  );

  const fetchBrandData = async () => {
    try {
      const result = await getBrandDetails(product?.supplier, activeBrands);
      setBrandData(result);
    } catch (error) {
      console.error("Error getting brand data:", error);
    }
  };

  useEffect(() => {
    if (!product || !activeBrands) return;
    fetchBrandData();
  }, [product, activeBrands]);

  const handleImageLoad = () => {
    setLoading(false);
  };
  // If expanded, render the expanded view
  if (isExpanded) {
    return (
      <ProductCardExpanded
        product={product}
        brandData={brandData}
        handleClick={handleClick}
      />
    );
  }
  return (
    <div className={`m-1 m-sm-1 ${isExpanded ? "expanded-card" : ""}`}>
      <Card onClick={handleClick} className="position-relative">
        <div className={`d-flex ${isExpanded ? "flex-row" : "flex-column"}`}>
          <div
            style={{
              position: "relative",
              height: isExpanded ? "195px" : "auto",
              width: isExpanded ? "123px" : "auto",
              overflow: "hidden",
            }}
          >
            {loading && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#eef0f2",
                }}
              >
                <Spinner
                  animation="border"
                  style={{ color: "grey", width: "20px", height: "20px" }}
                />
              </div>
            )}
            <Card.Img
              src={product.imageUrl}
              style={{
                height: "100%",
                objectFit: "cover",
                opacity: loading ? 0 : 1,
                transition: "opacity 0.5s ease-in-out",
                cursor: "pointer",
              }}
              onLoad={handleImageLoad}
              loading="lazy"
            />
          </div>


        </div>

        <div
          className="position-absolute rounded-pill top-0 start-0 m-2"
          style={{
            fontSize: "10px",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          }}
        >
          {product?.newPrice && (
            <p className="m-0 text-white px-2 py-1">
              PKR {formatPrice(product.newPrice)}
            </p>
          )}
        </div>

        {(isStrip === undefined || isStrip === true) && (
          <>
            <div
              className="d-inline-flex ps-1 pe-2 align-items-center justify-content-center rounded-pill text-light position-absolute bottom-0 end-0"
              style={{
                fontSize: "12px",
                marginBottom: "2.4rem",
                marginRight: "0.5rem",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
              }}
            >

              <span

              >
                <AddToList product={product} componentName={"strip2"} />
              </span>

            </div>
            <div
              className="d-inline-flex px-2 align-items-center justify-content-center rounded-pill text-light position-absolute bottom-0 end-0 m-2"
              style={{
                fontSize: "12px",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
              }}
            >

              <span

              >
                <AddToChat product={product} />
              </span>

            </div>
            <div
              className="align-items-center justify-content-center rounded-pill position-absolute bottom-0 start-0 m-2"
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.6)",
              }}
            >
              <Link
                className="text-center text-light d-flex align-items-center gap-1 px-2"

              >
                <AddOrRemoveLike
                  user={user}
                  product={product}
                  setLikeCount={setLikeCount}
                  componentName={"strip2"}
                />
                <div style={{ fontSize: "10px" }}>({likeCount})</div>
              </Link>
            </div>
          </>
          //   <Link className="text-center d-flex flex-column nav-link" to="">
          //     <i className="bi bi-eye"></i>
          //     <div
          //       style={{
          //         fontSize: "10px",
          //         marginTop: "-5px",
          //         marginBottom: "-2px",
          //       }}
          //     >
          //       {product?.views ?? 0}
          //     </div>
          //   </Link>

          //   <Link
          //     className="text-center text-light"
          //     onClick={handleDismiss}
          //     onMouseLeave={handleDismiss}
          //     onMouseEnter={() => setShowToolTipFavorites(true)}
          //   >
          //     <AddOrRemoveFavourites
          //       product={product}
          //       user={user}
          //       setFavouritesCount={setFavouritesCount}
          //     />
          //     <div style={{ fontSize: "10px", marginTop: "-7px" }}>
          //       {favouritesCount ?? 0}
          //     </div>
          //   </Link>

          //   <span
          //     onClick={handleDismiss}
          //     onMouseLeave={handleDismiss}
          //     onMouseEnter={() => setShowToolTipChat(true)}
          //   >
          //     <ShareMenu2 product={product} />
          //   </span>

          //   <span
          //     onClick={handleDismiss}
          //     onMouseLeave={handleDismiss}
          //     onMouseEnter={() => setShowToolTipAddToList(true)}
          //   >
          //     <AddToList product={product} componentName={"strip2"} />
          //   </span>

          //   <span
          //     onClick={handleDismiss}
          //     onMouseLeave={handleDismiss}
          //     onMouseEnter={() => setShowToolTipAddToList(true)}
          //   >
          //     <AddToChat product={product} />
          //   </span>
          // </div>
        )}
      </Card>

      <div className="pe-1 pb-1">
        <div className="d-flex align-items-center">
          <div className="avatar me-2" style={{ width: "35px", height: "35px" }}>
            <Link to={"/BrandHome/" + product?.supplier}>
              <img
                className="avatar-img rounded"
                src={brandData?.logo}
                alt=""
              />
            </Link>
          </div>
          <div
            className="d-flex flex-column justify-content-start"
            style={{ width: "78%" }}
          >
            <div className="mb-0">
              <BrandDisplay
                brandData={brandData}
                post={product}
                type={"ProductCard"}
              />
            </div>
            <div
              className="fw-bold h6 position-relative"
              style={{
                marginTop: "2px",
                fontSize: "12px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {product?.name}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestProductCard2;