import Glightbox from "glightbox";
import { useEffect, useState } from "react";
import "../../NewsFeed/Glight.css";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  writeBatch,
} from "firebase/firestore";
import { db } from "../../FirebaseConfig";
import { Link } from "react-router-dom";

import useActiveBrandsStore from "../../StatesStore/ActiveBrands";
import { getDisplayName } from "../../lib/getSupplierDisplayName";
import formatPrice from "../../lib/formatPrice";
import formatTimestamp from "../../lib/formatTimestamp";

const GlightImage = ({ product, image, imageIndex,brandData }) => {


  const dynamicDescClass = `custom-desc${product?.id + imageIndex}`;


  const activeBrands = useActiveBrandsStore((state) => state.activeBrands);
console.log('product from glight',product);


  //glight box
  useEffect(() => {
    if (brandData && product ) {
      const lightbox = Glightbox({
        selector: `[data-morepostglightbox="${product?.id}"]`,
      });

      const batch = writeBatch(db);
      const incrementViews = {
        views: product?.views ? product?.views + 1 : 1,
      };
      batch.update(doc(db, "products", product?.id), incrementViews);
      batch.commit();

      return () => {
        lightbox.destroy();
      };
    }
  }, [product?.id, brandData, imageIndex]);

  return (
    <div>
      {[product.imageUrl, ...(product.secondaryImages || [])].map(
        (imageUrl, index) => (
          <Link
            key={index}
            to={imageUrl}
            data-gallery="image-popup"
            className="glightbox"
            data-morepostglightbox={product?.id}
            data-glightbox={`description: .${dynamicDescClass}; descPosition: left;`}
            style={{
              display: index === 0 ? "block" : "none",
            }}
          >
            <div className="card">
              <img
                className="card-img-top img-fluid rounded-top"
                style={{ height: "80%" }}
                src={imageUrl}
                alt={`Product image ${index + 1}`}
              />
            </div>
          </Link>
        )
      )}
      <div className={`glightbox-desc ${dynamicDescClass}`}>
        {/**Top Bar in Sidebar */}
        <div className="d-flex align-items-center justify-content-between">
          {/**Post Owner */}
          <div className="d-flex align-items-center">
            {/**Post owner Image */}
            <div className="avatar me-2" style={{ width: "35px", height: "35px" }}>
              <Link to={"/BrandHome/" + product?.supplier}>
                <img
                  className="avatar-img rounded"
                  src={brandData?.logo}
                  alt="Brand profile"
                />
              </Link>
            </div>
            {/**Post owner details */}
            <div>
              <div className="nav nav-divider">
                {/**Post owner Name */}
                <h6 className="nav-item card-title mb-0">
                  {getDisplayName(product?.supplier, activeBrands)}
                </h6>
              </div>
              {/**Any tagline / status line if there is in bio of product owner  */}
              <p className="mb-0 small">
                <span className="nav-item small">
                  {formatTimestamp(product?.dateCreated)}
                </span>
              </p>
            </div>
          </div>
        </div>
        {/**Image name */}
        <h6 className="mt-3 text-wrap "> {product?.name} </h6>
        {/**Like comment Share options */}
        <div className="pt-1">
          {/* Price Row */}
          {product?.discount ? (
            <ul className="nav nav-stack small mt-1">
              {/*If sdiscount  */}
              <li className="nav-item" style={{ fontSize: "0.85rem" }}>
                Rs. {formatPrice(product.newPrice)}Pkr
              </li>
              <li className="nav-item" style={{ fontSize: "0.85rem" }}>
                <strike
                  style={{
                    textDecoration: "line-through",
                    textDecorationColor: "red",
                  }}
                >
                  Rs. {formatPrice(product?.oldPrice)}Pkr
                </strike>
              </li>
              <li
                className="nav-item badge bg-danger text-white ms-auto"
                style={{ fontSize: "0.85rem" }}
              >
                {product?.discount}% off
              </li>
            </ul>
          ) : (
            <ul
              className="nav nav-stack small mt-1"
              style={{ fontSize: "0.85rem" }}
            >
              {/*If no discount  */}
              <li className="nav-item">
                Price: Rs. {formatPrice(product?.newPrice)}
              </li>
            </ul>
          )}
          {/* Category and subcategory */}
          <ul className="nav nav-stack small mx-auto mt-1">
            {/*If sdiscount  */}
            {product?.category ? (
              <li className="nav-item" style={{ fontSize: "0.85rem" }}>
                Category: {product?.category}
                <span style={{ fontSize: "0.80rem" }}>
                  {" "}
                  {product?.subCategory},
                  <span style={{ fontSize: "0.75rem" }}>
                    {" "}
                    {product?.subSubCategory}
                  </span>{" "}
                </span>
              </li>
            ) : null}
          </ul>
          {/* Category and subcategory */}
          <ul className="nav nav-stack small mt-1">
            <li className="nav-item d-flex flex-grow-1">
              <Link
                to={`/ProductDetails/${product?.id}`}
                className="dropdown-item btn btn-primary-soft btn-sm my-0 text-center py-2"
              >
                Visit
              </Link>
            </li>
          </ul>
          {/**Like comment Share options */}
          <ul className="nav nav-stack mt-1 small">
            {/**Like Button */}
            <li className="nav-item">
              <i className="bi bi-hand-thumbs-up-fill pe-1"></i>(
              {product?.likes ?? 0})
            </li>
            {/**Comment button  */}
            <li className="nav-item">
              <i className="bi bi-eye-fill pe-1"></i>({product?.views ?? 0})
            </li>
            <li className="nav-item">
              <i className="bi bi-star-fill pe-1"></i>(
              {product?.favourites ?? 0})
            </li>
          </ul>
        </div>

   
      </div>
    </div>
  );
};

export default GlightImage;
