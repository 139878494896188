import React, { useEffect } from 'react';
import { Adsense } from '@ctrl/react-adsense';


const GoogleAdCard = () => {

  return (
    <Adsense
    style={{ width: "240px", height: "240px", display: 'block',marginBottom:"2px",marginTop:"5px" }}
        client="ca-pub-6917679702799024"
        slot="4021956392"
          format="auto"
    />
)

};

export default GoogleAdCard;
