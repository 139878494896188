import React, { useState, useEffect } from "react";
import { Link, Route, Routes, useLocation, useParams } from "react-router-dom";
import uuid4 from "uuid4";
import { toast } from "react-toastify";
import {
  getDoc,
  doc,
  setDoc,
  onSnapshot,
  collection,
  getDocs,
  serverTimestamp,
  where,
  query,
} from "firebase/firestore";
import SideNavUserProfile from "../../SideNav/SideNavUserProfile";
import { db } from "../../FirebaseConfig";
import { toastOptions } from "../../lib/toastOptions";
import { UserAuth } from "../../Context";
import { Modal, Form, Button } from "react-bootstrap";

import InviteLists from "../../ShoppingList/InviteLists";
import MyList from "./MyList";
import LoadingSpinner from "../LoadingSpinner";

const ShoppingList = () => {
  // My Shopping list Data
  const [shoppingListData, setShoppingListData] = useState([]);
  // Invited ShoppingList Data
  const [invitedshoppingListData, setInvitedShoppingListData] = useState([]);
  // Current Selected Category
  const [selectedListCategory, setSelectedListCategory] = useState(
    "MyShoppingList"
  );

  // Two categories: true for MyShoppingList, false for Invited Shopping List
  const checkMyShoppingList = selectedListCategory === "MyShoppingList";
  // Used when createing new list
  const [newListName, setNewListName] = useState("");
  // Used to separate the invited list by listId and userId
  // const [invitedList, setInvitedList] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const handleClose = () => setShow(false);
  const { user } = UserAuth();
  const location = useLocation();
  const { uid } = useParams();
  console.log("user auth", user);
  console.log("user shopping list uid", uid);

  useEffect(() => {
    const fetchShoppingLists = async () => {
      if (!user) return;
      setLoading(true);
      const userRef = doc(db, "users", uid);
      const shoppingListRef = collection(userRef, "shoppingList");
      let q;

      if (user?.uid === uid) {
        q = query(shoppingListRef); // Fetch all shopping lists
      } else {
        q = query(shoppingListRef, where("visibility", "==", "Public")); // Fetch only public shopping lists
      }
      let unsubscribe;
      try {
        unsubscribe = onSnapshot(userRef, async () => {
          const shoppingListDocs = await getDocs(q);
          const shoppingListData = shoppingListDocs.docs.map(
            async (document) => {
              const shoppingList = document.data();
              const dateCreated =
                shoppingList.dateCreated?.seconds ||
                new Date().getTime() / 1000;

              // Fetch products from the subcollection
              const productRef = collection(
                shoppingListRef,
                document.id,
                "products"
              );
              const productDocs = await getDocs(productRef);

              const products = productDocs.docs.map((productDoc) => {
                const productData = productDoc.data();
                return {
                  id: productDoc.id,
                  ...productData,
                };
              });

              return {
                name: document.id,
                dateCreated,
                products,
                listId: shoppingList.listId,
                sizes: shoppingList.sizes,
              };
            }
          );

          const shoppingListDataArray = await Promise.all(shoppingListData);
          // shoppingListDataArray.sort((a, b) => a.dateCreated - b.dateCreated);
          shoppingListDataArray.sort(
            (a, b) =>
              b.products.length - a.products.length ||
              b.dateCreated - a.dateCreated
          );
          setShoppingListData(shoppingListDataArray);
        });
      } catch (error) {
        console.error("Error setting up snapshot listener:", error);
      } finally {
        setLoading(false);
      }

      return unsubscribe;
    };

    window.scrollTo(0, 0);
    fetchShoppingLists();
  }, [user]);

  const handleCreateNewList = async () => {
    const listName = newListName.trim();

    if (listName) {
      const capitalizedListName =
        listName.charAt(0).toUpperCase() + listName.slice(1);

      await addToSavedList(capitalizedListName);
      const newListId = uuid4();

      setShoppingListData([
        ...shoppingListData,
        {
          name: capitalizedListName,
          dateCreated: new Date(),
          products: [],
          listId: newListId,
          visibility: "Private",
        },
      ]);

      toast.success(
        ` ${capitalizedListName} Shopping List has been created`,
        toastOptions
      );
      setNewListName("");
      handleClose();
    }
  };

  const addToSavedList = async (listName) => {
    if (!user) return;

    try {
      const shoppingListRef = collection(db, "users", user.uid, "shoppingList");
      const newListId = listName;

      const existingListDoc = await getDoc(doc(shoppingListRef, newListId));
      if (!existingListDoc.exists()) {
        await setDoc(doc(shoppingListRef, newListId), {
          dateCreated: serverTimestamp(),
          listId: uuid4(),
          visibility: "Private",
        });
      }
      console.log("New shopping list added successfully!");
    } catch (error) {
      console.error("Error creating New shopping list:", error.message);
    }
  };
  console.log("shopping list loading is", loading);

  return (
    <>
      {/* Nav Strip under Header */}

      <Routes>
        <Route
          path="/"
          element={
            <MyList
              shoppingListData={shoppingListData}
              setShoppingListData={setShoppingListData}
              loading={loading}
            />
          }
        />
      </Routes>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title id="modalLabelCreateEvents">Create new list</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form
            className="d-flex"
            onSubmit={(e) => {
              e.preventDefault();
              handleCreateNewList();
            }}
          >
            <div className="p-0 w-75 me-1">
              <Form.Control
                type="text"
                placeholder="Shopping list name"
                value={newListName}
                onChange={(e) => setNewListName(e.target.value)}
              />
            </div>
            <div className="p-0 w-25">
              <Button
                variant="primary-soft"
                type="submit"
                className="container"
                data-bs-dismiss="modal"
              >
                <span className="d-none d-sm-block">Create now</span>
                <span className="d-sm-none">Create</span>
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ShoppingList;
