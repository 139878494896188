import React from "react";

function PrivateProfile() {
  return (
    <div
      className="card text-center d-flex align-items-center justify-content-center"
      style={{ height: "19rem", maxHeight: "19rem", overflowY: "scroll" }}
    >
      <div className="card-header border-0 pb-0">
        <h5 className="card-title">Profile Info</h5>
      </div>
      <div className="card-body pt-0">
        <p>This profile is private.</p>
      </div>
    </div>
  );
}

export default PrivateProfile;
