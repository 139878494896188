import React from "react";

function Step4() {
  const step4Image =
    process.env.PUBLIC_URL + "/assets/LandingPage/step4Image.png";

  return (
    <div
      className="row mt-0 mt-md-3"
      style={{ minHeight: "65vh", alignItems: "center" }}
    >
      <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
        <h1 className="text-center text-md-start ms-3 display-6">
          Invite Friends to View Your Shopping List
        </h1>
        <p className="lead text-center text-md-start ms-3">
          Get instant feedback and make shopping more fun!
        </p>
      </div>
      <div className="col-12 col-md-6 d-flex justify-content-center align-items-center mt-4 mt-md-0">
        <img
          className="img-fluid"
          src={step4Image}
          alt="Invite Friends"
          style={{ maxWidth: "70%" }}
        />
      </div>
    </div>
  );
}

export default Step4;
