import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { db } from "../FirebaseConfig";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import SideNavUserProfile from "../SideNav/SideNavUserProfile";
import { Card } from "react-bootstrap";
import LoadingSpinner from "../UserProfile/LoadingSpinner";
function BrandsPage() {
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);
  const refs = useRef({});
  const letterRef = useRef();
  useEffect(() => {
    const getBrands = async () => {
      try {
        const brandsCollectionRef = collection(db, "brands");
        const brandsQuery = query(brandsCollectionRef, orderBy("name", "asc"));
        const brandsSnapshot = await getDocs(brandsQuery);
        const brandsData = brandsSnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setBrands(brandsData);
      } catch (error) {
        console.error("Error fetching brands:", error);
      } finally {
        setLoading(false);
      }
    };

    getBrands();
  }, []);

  // Extract unique first letters from brand names
  const uniqueFirstLetters = Array.from(
    new Set(brands.map((brand) => brand.name[0]))
  ).sort();
  // Generate an array containing all English alphabets
  const allAlphabets = Array.from({ length: 26 }, (_, i) =>
    String.fromCharCode("A".charCodeAt(0) + i)
  );

  const scrollToLetter = (l, id) => {
    let targetLetter = l.toUpperCase();
    const availableLetters = uniqueFirstLetters.map((l) => l.toUpperCase());
    if (!availableLetters.includes(targetLetter)) {
      // Find the nearest available l
      const nearestLetter = availableLetters.reduce((a, b) => {
        return Math.abs(b.charCodeAt(0) - targetLetter.charCodeAt(0)) <
          Math.abs(a.charCodeAt(0) - targetLetter.charCodeAt(0))
          ? b
          : a;
      });

      // Update the targetLetter to the nearest available letter
      targetLetter = nearestLetter;
    }
    const element = refs.current[id];
    if (element) {
      const yOffset = 0; // Adjust this value as needed
      // const yPosition =
      //   element.getBoundingClientRect().top + window.scrollY + yOffset;
      const yPosition = element.offsetTop + yOffset;

      element.scrollIntoView({ top: yPosition, behavior: "smooth" });
      // letterRef.current.scrollTo({ top: yPosition, behavior: "smooth" });
    }
  };
  return (
    <>
      {/* <SideNavUserProfile /> */}
      <style jsx>
        {`
          // .thinScrollBar {
          // scrollbar-width: thin;
          // }
          .thinScrollBar::-webkit-scrollbar {
            display: none;
          }
          .thinScrollBar {
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
          }
        `}
      </style>
      {/* <div className="col-md-8 col-lg-6 vstack p-0 mt-0 mt-lg-5 ms-1 "> */}
      <div className="vstack px-0 ms-0 ms-lg-1 col-12 col-lg-8">
        <Card style={{ height: "100vh" }}>
          <Card.Header className="px-3 pt-3 pb-0">
            <h3 className="mb-3 text-center">Brands Directory</h3>
            <div
              className="alphabet-list navbar thinScrollBar"
              style={{ overflowX: "auto" }}
            >
              <ul className="nav d-flex flex-row flex-nowrap list-unstyled gap-2 pb-3">
                {allAlphabets.map((l, index) => (
                  <li className="nav-item">
                    <button
                      to={`#${l}`}
                      key={l}
                      className={`btn ${
                        uniqueFirstLetters.includes(l)
                          ? "btn-light"
                          : "border-0"
                      } nav-link text-uppercase `}
                      onClick={() => scrollToLetter(l, index)}
                      disabled={!uniqueFirstLetters.includes(l)}
                    >
                      {l}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </Card.Header>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <Card.Footer
              className="p-0 thinScrollBar"
              ref={letterRef}
              style={{
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              {allAlphabets.map((letter, index) =>
                uniqueFirstLetters.includes(letter) ? (
                  <div
                    // className="px-3 "
                    key={letter}
                    id={letter}
                    ref={(el) => (refs.current[index] = el)} // Store refs by item ID
                  >
                    <div className="row align-items-center justify-content-center">
                      <div className="col-1 px-0 text-center">
                        <h4 className="m-0">{letter}</h4>
                      </div>
                      <div className="col-10 col-md-11 px-0 my-1 ps-3">
                        <div className="row w-100">
                          {brands
                            .filter((brand) => brand.name[0] === letter)
                            .map((brand) => (
                              <div
                                key={brand.id}
                                className="col-6 col-md-4 my-1 "
                              >
                                <ul className="list-unstyled m-0">
                                  <li>
                                    <Link
                                      to={`/BrandHome/${brand.name}`}
                                      className="d-flex justify-content-start align-items-center"
                                    >
                                      <img
                                        src={brand?.logoUrl}
                                        className="rounded-2"
                                        alt={`${brand.displayName} logo`}
                                        style={{
                                          width: "40px",
                                          height: "40px",
                                          borderRadius: "2px",
                                        }}
                                      />
                                      <span className="ms-2">
                                        {brand.displayName}
                                      </span>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            ))}
                        </div>
                        {/* {brands.filter((brand) => brand.name[0] === letter)
                        .length %
                        2 !==
                        0 && (
                        <div className="row">
                          <div className="col-md-6"></div>
                          <div className="col-md-6 border-right"></div>
                        </div>
                      )} */}
                      </div>
                    </div>
                    <hr className="border-top border-2 my-0" />
                  </div>
                ) : (
                  <div
                    // className="px-3 "
                    key={letter}
                    id={letter}
                    ref={(el) => (refs.current[index] = el)} // Store refs by item ID
                  ></div>
                )
              )}
            </Card.Footer>
          )}
        </Card>
      </div>
    </>
  );
}

export default BrandsPage;
