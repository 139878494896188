import React, { useEffect, useRef, useState } from "react";
import {
  collection,
  query,
  startAfter,
  limit,
  orderBy,
  doc,
  getDoc,
  deleteDoc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import ProfilePicture from "../UserProfile/ProfilePicture";
import { Link } from "react-router-dom";
import { formatTimestampForPost } from "../lib/formatTimestampForPost";
import AddCommentBlog from "./AddCommentBlog";
import { Accordion } from "react-bootstrap";

function Comment({ comment, user, blog, handleDeleteComment }) {
  // console.log("newComments comment", comment);
  // console.log("newComments comment length", comment.replies.length);
  const [replyCount, setReplyCount] = useState();
  useEffect(() => {
    setReplyCount(comment.replies.length || 0);
  }, [comment]);

  return (
    <li className="comment-item mb-2 mt-0">
      <div className="d-flex">
        {/* Avatar */}
        <div className="avatar avatar-sm ">
          <ProfilePicture userData={comment} className="avatar-img rounded-2" />
        </div>
        {/* Comment by */}
        <div className="ms-2 w-100">
          <div className="bg-light rounded-start-top-0 px-3 py-2 rounded">
            <div className="d-flex justify-content-between mb-1 w-100">
              <h6 className="mb-0">
                {" "}
                <Link
                  to={
                    comment ? `/userProfile/${comment.userId}` : "/userProfile"
                  }
                >
                  {" "}
                  {comment?.userName
                    .split(" ")
                    .slice(0, 3)
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}{" "}
                </Link>{" "}
              </h6>
              <span className="mb-0">
                {formatTimestampForPost(comment?.timestamp)}
              </span>
            </div>
            <p className="mb-0 h6 fw-light text-body">{comment?.content}</p>
          </div>

          <>
            <div className="mx-1 pe-2 w-100 d-flex justify-content-between commentSection">
              <Accordion flush className="w-100">
                <style jsx>
                  {`
                    .commentSection .accordion-button::after {
                      display: none !important; /* Hides the default arrow */
                    }
                  `}
                </style>
                <Accordion.Item className="bg-transparent" eventKey={0}>
                  <Accordion.Header className="d-inline-block">
                    <Link to="" className="fw-light h6 m-0 p-0 ps-2">
                      Reply {replyCount > 0 && `(${replyCount})`}
                    </Link>
                  </Accordion.Header>
                  <Accordion.Body className="p-0 m-0 mt-1">
                    {comment?.replies.length !== 0 &&
                      comment?.replies?.map((com, index) => {
                        return (
                          <Comment
                            comment={com}
                            key={index}
                            blog={blog}
                            user={user}
                            handleDeleteComment={handleDeleteComment}
                          />
                        );
                      })}
                    <div className="ms-2">
                      <AddCommentBlog
                        blog={comment}
                        setCommentCount={setReplyCount}
                        user={user}
                        type={"reply"}
                      />
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              {(blog?.userId === user?.uid ||
                comment?.userId === user?.uid) && (
                <Link
                  to=""
                  className=" fw-light h6 m-0 pt-1 position-absolute"
                  style={{ right: "1%" }}
                  onClick={() =>
                    handleDeleteComment(comment?.reference, comment.id)
                  }
                >
                  Delete
                </Link>
              )}
            </div>
          </>
        </div>
      </div>
    </li>
  );
}

export default function BlogComment({
  blog,
  commentCount,
  setCommentCount,
  user,
}) {
  // console.log("blog ", blog);
  const [comments, setComments] = useState([]);
  const [visibleComments, setVisibleComments] = useState(3);
  const [loading, setLoading] = useState(false);
  const [lastVisible, setLastVisible] = useState(null);
  const commentRef = useRef();
  //const [countLeft, setCountLeft] = useState(0);

  const fetchComments = async () => {
    const commentsCollectionRef = collection(db, "blogs", blog?.id, "comments");
    let q;
    if (lastVisible) {
      q = query(
        commentsCollectionRef,
        orderBy("timestamp", "desc"),
        startAfter(lastVisible),
        limit(3)
      );
    } else {
      q = query(commentsCollectionRef, orderBy("timestamp", "desc"), limit(3));
    }

    // Fetch replies recursively with onSnapshot
    const fetchReplies = (collectionRef, docID) => {
      const replyRef = collection(collectionRef, docID, "replies");
      const q = query(replyRef, orderBy("timestamp", "desc"));

      // Use onSnapshot for replies
      const unsubscribeReplies = onSnapshot(q, async (replySnapshot) => {
        const replies = await Promise.all(
          replySnapshot.docs.map(async (reply) => {
            let returnObj = {
              id: reply?.id,
              ...reply?.data(),
              userName: "",
              photo: "",
              reference: replyRef,
              replies: [], // Default to an empty array for nested replies
            };

            const userDocRef = doc(db, "users", reply?.data().userId);
            const userSnapshot = await getDoc(userDocRef);

            if (userSnapshot.exists()) {
              returnObj.userName = userSnapshot.data().userName;
              returnObj.photo = userSnapshot.data().photo;
            } else {
              console.error(
                "User document not found for userId:",
                reply?.data().userId
              );
            }

            // Recursively fetch nested replies
            returnObj.replies = await fetchReplies(replyRef, reply?.id);

            return returnObj;
          })
        );

        // Update the replies within the comment
        setComments((prevComments) =>
          prevComments.map((comment) => {
            if (comment.id === docID) {
              return { ...comment, replies }; // Update replies for the correct comment
            }
            return comment;
          })
        );
      });

      return unsubscribeReplies;
    };

    // Use onSnapshot for comments
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      const newComments = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          return {
            id: doc.id,
            ...doc.data(),
            userName: "",
            photo: "",
            reference: commentsCollectionRef,
            replies: [], // Initialize replies to an empty array
          };
        })
      );

      // Fetch user details and replies for each comment
      Promise.all(
        newComments.map(async (comment) => {
          const userDocRef = doc(db, "users", comment.userId);
          const userSnapshot = await getDoc(userDocRef);

          if (userSnapshot.exists()) {
            comment.userName = userSnapshot.data().userName;
            comment.photo = userSnapshot.data().photo;
          } else {
            console.error(
              "User document not found for userId:",
              comment.userId
            );
          }

          // Fetch replies for this comment using onSnapshot
          fetchReplies(commentsCollectionRef, comment.id);
        })
      )
        .then(() => {
          setComments((prevComments) => {
            if (lastVisible) {
              return [...newComments, ...prevComments];
            } else {
              return newComments;
            }
          });
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });

      if (newComments.length > 0) {
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  };

  useEffect(() => {
    if (commentRef && commentRef.current) {
      commentRef.current.scrollTop = commentRef.current.scrollHeight;
    }
    // console.log("comments", comments);
  }, [comments]);
  useEffect(() => {
    fetchComments();
  }, [blog]);

  const loadMoreComments = () => {
    setLoading(true);
    fetchComments();
    setVisibleComments((prev) => prev + 3);
  };
  const handleDeleteComment = async (reference, commentUID) => {
    // console.log(commentUID);
    const commentDoc = doc(reference, commentUID);
    await deleteDoc(commentDoc);
    setCommentCount((prev) => prev - 1);
  };
  return (
    <>
      {commentCount > visibleComments && !loading && (
        <div className="card-footer border-0 py-1">
          <button
            className="btn btn-link btn-link-loader btn-sm text-secondary d-flex align-items-center"
            onClick={loadMoreComments}
            disabled={loading}
          >
            <div className="spinner-dots me-2">
              <span className="spinner-dot"></span>
              <span className="spinner-dot"></span>
              <span className="spinner-dot"></span>
            </div>
            View older comments
          </button>
        </div>
      )}
      <ul
        className="comment-item-nested list-unstyled pe-2 mb-0"
        ref={commentRef}
        style={{
          maxHeight: "19rem",
          overflowY: "scroll",
          overflowX: "hidden",
          scrollbarWidth: `${
            commentCount >= visibleComments ? "none" : "auto"
          }`,
        }}
      >
        {comments?.map((comment, index) => {
          return (
            <Comment
              comment={comment}
              user={user}
              blog={blog}
              handleDeleteComment={handleDeleteComment}
              key={index}
            />
          );
        })}
      </ul>
      {/* Comment wrap END */}
    </>
  );
}
