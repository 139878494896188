import React, { useEffect } from "react";
import InvitesList from "./InvitesList";
import { useParams } from "react-router-dom";
import { UserAuth } from "../Context";
import InviteClicks from "./InviteClicks";
import PrivateProfile from "../UserProfile/PrivateProfile";

function InviteStats({ userData }) {
  const { uid } = useParams();
  const { user } = UserAuth();

  useEffect(() => {
    if (!uid) {
      // Handle the case where the user is not logged in or the data is not yet loaded
      return;
    }
  }, [uid]);

  // Render a loading message or similar if the user data is not yet available
  if (!uid) {
    return <div>Loading...</div>;
  }

  const Friendlist = userData?.friendRequests?.active || [];
  const privacy = userData?.privacySettings?.accountSetting || "private";
  const isCurrentUserProfile = user?.uid === uid;
  const isUserInFriendList = Friendlist.includes(user?.uid);

  return (
    <>
      {/* Card Connections START */}
      {(isCurrentUserProfile || privacy === "public" || isUserInFriendList) && (
        <>
       
            <div className="card-header border-0 pb-0 pt-0"></div>
            <div className="card-body pt-0">
              <InviteClicks userId={user?.uid} />
              <div className="my-2">
                <hr />
              </div>
              {<InvitesList userId={user?.uid} />}
            </div>
       
        </>
      )}

      {!isCurrentUserProfile && privacy === "private" && <PrivateProfile />}
    </>
  );
}

export default InviteStats;
