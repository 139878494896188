import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { db } from "../FirebaseConfig";
import { collection, getDocs, query, where } from "firebase/firestore";
import { UserAuth } from "../Context";
import { calculateScores } from "../lib/calculateBrandScores";

function BrandsMenu() {
  const { user } = UserAuth();
  const [brands, setBrands] = useState([]);
  const brandsCollectionRef = collection(db, "brands");

  useEffect(() => {
    const getBrands = async () => {
      // let brandsLimit = user ? 5 : 10; // Show 5 brands when logged in, 10 when not logged in
      // let brandsLimit = 99;

      const qry = query(
        brandsCollectionRef,
        where("active", "==", 1)
        // where("region", "==", country)
      );

      const data = await getDocs(qry);

      const brandsWithScores = calculateScores(
        data.docs.map((doc) => doc.data())
      );

      const sortedBrands = brandsWithScores.sort((a, b) => {
        const nameA = a.displayName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.displayName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });

      setBrands(sortedBrands);
    };

    getBrands();
  }, [user]);

  return (
    <ul className="dropdown-menu" aria-labelledby="brandMenu">
      <li>
        <Link
          to={"/brands"}
          className="dropdown-item fw-bold text-center pt-0 pb-1 my-0"
        >
          <span>View All Brands</span>
        </Link>
      </li>
      <div
        className="scrollbar scrollbar-black me-1 "
        style={{ maxHeight: "260px", border: "none", overflowY: "scroll" }}
      >
        {brands.map((brand) => (
          <li>
            <Link
              className="dropdown-item py-1"
              to={`/BrandHome/${brand?.name}`}
            >
              {brand?.displayName}
            </Link>
          </li>
        ))}
      </div>
    </ul>
  );
}

export default BrandsMenu;
