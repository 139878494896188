import React, { useState } from "react";
import { Link, Route, Routes } from "react-router-dom";
import AccountSetting from "./AccountSetting";
import BrandSelection from "./BrandSelection";
import CategorySelection from "./CategorySelection";
import PrivacySetting from "./PrivacySetting";
import BrandsAndCategories from "./BrandsAndCategories";
import Network from "../UserProfile/Network";
import { UserAuth } from "../Context";
import Posts from "../UserProfile/Posts";
import PointsStats from "../Points/PointsStats";
import { useFetchUserData } from "../hooks/user/useFetchUserData";
function UserSettings3() {
  const { user } = UserAuth();
  const { userData } = useFetchUserData(user?.uid);
  const [activeTab, setActiveTab] = useState("account");
  return (
    // <div className="col-md-8 col-lg-6 vstack gap-3 text-body mt-0 mt-lg-5 px-3 px-lg-0 me-2">
    <div className="vstack text-body px-0 ms-0 ms-lg-1">
      <ul className="card card-header nav nav-tabs nav-bottom-line2 d-flex flex-row active justify-content-center justify-content-md-start my-0 ps-4 pe-2 py-0 rounded-2 mb-2">
        <li className="nav-item">
          <Link
            to={"/testSettings/accountSetting"}
            className={`nav-link pt-3 pb-2 ${
              activeTab === "account" ? "active" : ""
            }`}
            onClick={() => setActiveTab("account")}
          >
            Account Settings
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to={"/testSettings/stylePreferences"}
            className={`nav-link pt-3 pb-2 ${
              activeTab === "style" ? "active" : ""
            }`}
            onClick={() => setActiveTab("style")}
          >
            Style Preferences
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to={`/testSettings/network/${user?.uid}`}
            className={`nav-link pt-3 pb-2 ${
              activeTab === "network" ? "active" : ""
            }`}
            onClick={() => setActiveTab("network")}
          >
            Network
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to={`/testSettings/posts/${user?.uid}`}
            className={`nav-link pt-3 pb-2 ${
              activeTab === "posts" ? "active" : ""
            }`}
            onClick={() => setActiveTab("posts")}
          >
            Posts
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to={`/testSettings/privacySetting`}
            className={`nav-link pt-3 pb-2 ${
              activeTab === "privacy" ? "active" : ""
            }`}
            onClick={() => setActiveTab("privacy")}
          >
            Privacy Settings
          </Link>
        </li>
      </ul>
      <Routes>
        <Route path="/accountSetting" element={<AccountSetting />} />
        {/* <Route path="/brandsAndCategories" element={<BrandsAndCategories />} /> */}
        <Route path="/stylePreferences" element={<BrandsAndCategories />} />
        <Route path="/brandSelection" element={<BrandSelection />} />
        <Route path={`/network/:uid`} element={<Network />} />
        <Route path={`/posts/:uid`} element={<Posts />} />
        <Route path="/categorySelection" element={<CategorySelection />} />
        <Route path="/privacySetting" element={<PrivacySetting />} />
        <Route
          path="/pointStats/:uid"
          element={<PointsStats userData={userData} />}
        />
      </Routes>
    </div>
  );
}

export default UserSettings3;
