import React, { useState } from "react";
import UserConnections from "./UserConnections";
import UserFollowing from "./UserFollowingTab";
import UserFollower from "./UserFollowerTab";
import InviteStats from "../Referral/InviteStats";
import useUserDataStore from "../StatesStore/UserData";

function Network() {
  const [activeTab, setActiveTab] = useState("friends");
  const userData = useUserDataStore((state) => state.userData);
  console.log("userData from network", userData);

  // Function to render active tab content
  const renderTabContent = () => {
    switch (activeTab) {
      case "friends":
        return <UserConnections userData={userData} componentName={"Network"}/>;
      case "following":
        return <UserFollowing userData={userData} componentName={"Network"} />;
      case "followers":
        return <UserFollower userData={userData} componentName={"Network"} />;
      case "inviteStats":
        return <InviteStats userData={userData} />;
      default:
        return <UserConnections userData={userData} />;
    }
  };

  return (
    <div className="card" style={{ minHeight: "38rem" }}>
      {/* Tab Navigation */}
      <ul className="card-header nav nav-tabs nav-bottom-line2 active justify-content-center justify-content-md-start mb-2 mt-0 pe-3 py-0 ps-4">
        <li className="nav-item">
          <button
            className={`nav-link pt-3 pb-2 ${
              activeTab === "friends" ? "active" : ""
            }`}
            onClick={() => setActiveTab("friends")}
          >
            Friends
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link pt-3 pb-2 ${
              activeTab === "following" ? "active" : ""
            }`}
            onClick={() => setActiveTab("following")}
          >
            Following
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link pt-3 pb-2 ${
              activeTab === "followers" ? "active" : ""
            }`}
            onClick={() => setActiveTab("followers")}
          >
            Followers
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link pt-3 pb-2 ${
              activeTab === "inviteStats" ? "active" : ""
            }`}
            onClick={() => setActiveTab("inviteStats")}
          >
            Invitations
          </button>
        </li>
      </ul>
      <div className="py-2">
        {/* Tab Content */}
        {renderTabContent()}
      </div>
    </div>
  );
}

export default Network;
