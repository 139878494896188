import React from 'react';
import NotificationItem from './NotificationItem';
import { useFetchUserData } from '../hooks/user/useFetchUserData';
import { UserAuth } from '../Context';
import UserFollowedMe from './UserFollowedMe';



function Notification({ notification,firstUserData,message,route }) {
  const { user } = UserAuth();
  return (
    <NotificationItem  
      notification={notification} 
      message={message} 
      route={route}
      firstUserData={firstUserData}
      user={user}
    />
  )
}




export function FollowedMe({ notification}) {
  const message = ` started following you.`;
  const route = `/userProfile/${notification?.id[0]}`
  const userIds = notification?.id || [];
  const firstUserId = userIds[0];
  const { userData } = useFetchUserData(firstUserId);
  return <Notification notification={notification} message={message}  firstUserData={userData} route={route} />;
}

export function PostLike({ notification }) {
  const message = ` liked your post.`
  const route = `/post/${notification.postId}`;
  const userIds = notification?.id || [];
  const firstUserId = userIds[0];
  const { userData } = useFetchUserData(firstUserId);
  return <Notification notification={notification} message={message} firstUserData={userData} route={route} />;
}

export function PostComment({ notification }) {
  const message = ` commented on your post.`
  const route = `/post/${notification.postId}`;
  const userIds = notification?.id || [];
  const firstUserId = userIds[0];
  const { userData } = useFetchUserData(firstUserId);
  return <Notification notification={notification} message={message} firstUserData={userData} route={route}/>;
}

export function JoinedUser({ notification }) {
  const message = ` is now on lookflock.`
  const route = `/userProfile/${notification.id}`;
  const userId = notification?.id || [];
  const { userData } = useFetchUserData(userId);
  return <Notification notification={notification}  message={message} firstUserData={userData} route={route}/>;
}

export function InviteAccepted({ notification }) {
  const message =  ` accepted your invitation to join lookflock.`
  const route = `/userProfile/${notification.id}`;
  const userId = notification?.id || [];
  const { userData } = useFetchUserData(userId);
  return <Notification notification={notification}  message={message}  firstUserData={userData} route={route}/>;
}

export function ShoppingListChatMessage({ notification,user }) {
  const message = ` sent a message in your ${notification.shopList} list chat.` 
  const route = `/shoppingList/${notification.listId}/${user.uid}`;
  const userIds = notification?.id || [];
  const firstUserId = userIds[0];
  
  const { userData } = useFetchUserData(firstUserId);
  return <Notification notification={notification} message={message}  firstUserData={userData} route={route}/>;
}
export function ShoppingListInvite({ notification,user }) {
  const message = ` invited you to ${notification.shopList} list.` 
  const route = `/shoppingLists/inviteLists/${notification.listId}`;
  const userIds = notification?.id || [];
  const firstUserId = userIds[0];
  
  const { userData } = useFetchUserData(firstUserId);
  return <Notification notification={notification} message={message}  firstUserData={userData} route={route}/>;
}

export function FirstTimeListView({ notification,user }) {
  const message = ` viewed your ${notification.shopList} list for the first time.`
  const route = `/shoppingLists?name=${encodeURIComponent(notification.shopList)}&id=${notification.listId}`;
  const userIds = notification?.id || []; 
  const firstUserId = userIds[0];
  const { userData } = useFetchUserData(firstUserId);
  return <Notification notification={notification} message={message}  firstUserData={userData} route={route}/>;
}

export function ListProductLike({ notification,user }) {
  const message = ` liked your product in ${notification.shopList} list.`
  const route = `/shoppingList/${notification.listId}/${user.uid}`;
  const userIds = notification?.id || [];
  const firstUserId = userIds[0];
  const { userData } = useFetchUserData(firstUserId);
  return <Notification notification={notification}  message={message}  firstUserData={userData} route={route}/>;
}

export function FriendRequest({ notification }) {
  const message = ` sent you a friend request.`
  const route = `/userProfile/${notification.id}`;
  const userIds = notification?.id || [];
  const firstUserId = userIds[0];
  const { userData } = useFetchUserData(firstUserId);
  return <Notification notification={notification}  message={message}  firstUserData={userData} route={route}/>;
}

export function AcceptFriendRequest({ notification }) {
  const message = ` accepted your friend request.`
  const route = `/userProfile/${notification.id}`;
  const userIds = notification?.id || [];
  const firstUserId = userIds[0];
  const { userData } = useFetchUserData(firstUserId);
  return <Notification notification={notification}  message={message}  firstUserData={userData} route={route}/>;
}


