import React, { useEffect, useState } from "react";
import UserCard from "../UserProfile/UserDisplay";
import FeedReaction from "./FeedReaction";
import GlightImage from "./GlightImage";
import FeedDropdown from "./FeedDropdown";
import { Link } from "react-router-dom";
import sanitizeHtml from "sanitize-html";
import BrandCard from "./BrandDisplay";
import BrandFeedDropdown from "./BrandFeedDropdown";
import { UserAuth } from "../Context";
import UserGlightbox from "../components/UserGlightbox";
import { useFetchUserData } from "../hooks/user/useFetchUserData";
import UserDisplay from "../UserProfile/UserDisplay";

export default function ThreeItemNews({ post, componentName }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const { user } = UserAuth();
  const maxCharactersPerLine = 67; // Adjust based on your font size and container width
  const maxLines = 3;
  const maxCharacters = maxCharactersPerLine * maxLines;
  const clean = sanitizeHtml(post?.title || "", {
    allowedTags: ["br"],
    disallowedTagsMode: "discard",
  });

  const { userData, loading } = useFetchUserData(post?.userId);

  return (
    <>
      {/* Card feed item START */}
      <div className={`card ${componentName === "Posts" ? "border-0" : ""}`}>
        {/* Card header START */}
        <div className="card-header border-0 ps-3 pt-3 p-0">
          <div className="d-flex align-items-center justify-content-between">
            {userData && <UserDisplay userData={userData} post={post} />}

            {!userData && <BrandCard post={post} />}

            {!userData && user && user.uid && <BrandFeedDropdown post={post} />}

            {/* Card share action START */}
            {/* {userData && <FeedDropdown post={post} />} */}
          </div>
        </div>
        {/* Card header START */}
        {/* Card body START */}
        <div className="card-body pb-0 pt-2 p-3">
          <div className="pb-2" style={{ whiteSpace: "pre-wrap" }}>
            {isExpanded ? (
              <div dangerouslySetInnerHTML={{ __html: clean }} />
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: clean?.slice(0, maxCharacters),
                }}
                style={{ whiteSpace: "pre-wrap" }}
              />
            )}
            {!isExpanded && post?.title?.length > maxCharacters && (
              <>
                <div
                  onClick={() => setIsExpanded(true)}
                  style={{
                    color: "#477ded",
                    cursor: "pointer",
                    paddingBottom: "12px",
                  }}
                >
                  ...see more  
                </div>
              </>
            )}
          </div>

          {/* Card feed grid START */}
          <div className="d-flex justify-content-between">
            <div className="row g-3">
              {post?.images?.map((image, imageIndex) => (
                <div
                  className={`col-6 ${
                    post?.images?.length > 2 && imageIndex === 1
                      ? "position-relative"
                      : imageIndex >= 2
                      ? "d-none"
                      : ""
                  }`}
                  key={imageIndex}
                >
                  {post?.by === "user" ? (
                    <UserGlightbox
                      post={post}
                      image={image}
                      imageIndex={imageIndex}
                    />
                  ) : (
                    <GlightImage
                      post={post}
                      image={image}
                      imageIndex={imageIndex}
                    />
                  )}

                  {post?.images.length > 2 && imageIndex === 1 && (
                    <div
                      className="overlay rounded"
                      style={{
                        position: "absolute",
                        top: 1,
                        right: 8,
                        bottom: 0,
                        left: 8,
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        pointerEvents: "none",
                      }}
                    >
                      <div className="hover-actions-item position-absolute top-50 start-50 translate-middle z-index-9">
                        <Link className="btn btn-link text-white">
                          View all
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          {/* Card feed grid END */}
          {post.id && <FeedReaction post={post} />}
        </div>
        {/* Card body END */}
      </div>
      {/* Card feed item END */}
    </>
  );
}
