import React, { useState, useEffect } from "react";
import { collection, doc, getDoc, getDocs, onSnapshot } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import UserDetailsModal from "./UserDetailsModal ";


const InvitedMembersList = ({ listName, userId,listId }) => {
  const [memberDetails, setMemberDetails] = useState([]);
  const [friendsCount, setFriendsCount] = useState(0);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!userId || !listName) return;

    // Real-time listener for members subcollection
    const membersRef = collection(
      db,
      "users",
      userId,
      "shoppingList",
      listName,
      "members"
    );

    const unsubscribe = onSnapshot(
      membersRef,
      async (snapshot) => {
        try {
          const memberIds = snapshot.docs.map((doc) => ({
            id: doc.id,
            role: doc.data().role,
          }));

          // Fetch user documents for all members
          const members = await Promise.all(
            memberIds.map(async (member) => {
              const userDocRef = doc(db, "users", member.id);
              const userDoc = await getDoc(userDocRef);
              return {
                ...userDoc.data(),
                role: member.role,
                id: member.id,
              };
            })
          );

          setMemberDetails(members);
          setFriendsCount(members.length);
        } catch (error) {
          console.error("Error fetching member details:", error);
        }
      },
      (error) => {
        console.error("Error listening to members subcollection:", error);
      }
    );

    // Cleanup on unmount
    return () => unsubscribe();
  }, [listName, userId]);


  const handleUserClick = () => {
    setShowModal(true);
  };

  return (
    <>
      {friendsCount > 0 && (
        <ul
          className="d-flex gap-1 list-unstyled ms-4 mt-4 pt-4 pb-1 position-absolute mb-1"
          onClick={(e) => e.stopPropagation()}
        >
          <span style={{ cursor: "pointer" }} onClick={handleUserClick}>Shared with </span>
          {memberDetails.slice(0, 3).map((member, index) => (
            <li key={member.id} onClick={handleUserClick} style={{ cursor: "pointer" }}>
               {member.userName ? member.userName.split(" ")[0] : "Unknown"}
              {index < memberDetails.slice(0, 3).length - 1 && ","}
            </li>
          ))}
          {friendsCount > 3 && (
            <li onClick={handleUserClick} style={{ cursor: "pointer" }}>and {friendsCount - 3} more</li>
          )}
          <li
            className="cursor-pointer text-primary"
            onClick={handleUserClick}
          >
         
          </li>
        </ul>
      )}

      <UserDetailsModal
        show={showModal}
        onClose={() => setShowModal(false)}
        members={memberDetails}
        listId={listId}
        userId={userId}
        listName={listName}
        setMemberDetails={setMemberDetails}
        setShowModal={setShowModal}
        rolesOptions={[
          { name: "commentor", label: "View & Chat" },
          { name: "editor", label: "Editor" },
        ]}
      />
    </>
  );
};

export default InvitedMembersList;
