import React, { useRef, useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import AddComment from "../components/AddComment";
import ShowComment from "../components/ShowComment";
function CommentModal({
  user,
  show,
  handleClose,
  post,
  commentCount,
  setCommentCount,visibleComments, setVisibleComments
}) {

  // const ModalRef = useRef(null)
  // const [modalHeight, setModalHeight] = useState()
  // useEffect(() => {
  //     if (show && ModalRef.current) {
  //         console.log(ModalRef.current.offsetHeight)
  //         setModalHeight(ModalRef.current.offsetHeight)
  //         ModalRef.current.style.maxHeight = `${modalHeight * 3}px`; // Example: half of its original height
  //         ModalRef.current.style.overflowY = 'scroll'; // Enable vertical scrolling
  //     }
  // }, [show]);
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title centered>Comment Section</Modal.Title>
      </Modal.Header>
      <Modal.Body
        id="modalBody"
        // ref={ModalRef}
      >
        <div className="">
          {user && (
            <AddComment
              user={user}
              post={post}
              setCommentCount={setCommentCount}
              type={"comment"}
            />
          )}
          {commentCount > 0 && (
            <ShowComment
              post={post}
              commentCount={commentCount}
              setCommentCount={setCommentCount}
              user={user}
              visibleComments={visibleComments}
              setVisibleComments={setVisibleComments}
            />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CommentModal;
