import { z } from "zod";

export const shippingFormSchema = z.object({
  firstName: z.string().min(1, "First name is required"),
  lastName: z.string().min(1, "Last name is required"),
  email: z.string().email("Invalid email address"),
  phoneNumber: z
    .string()
    .regex(
      /^(\+92|92|0)?3\d{9}$/,
      "Phone number must be a valid Pakistani number, e.g., +923121223214 or 03121223214"
    ),
  address: z.string().min(4, "Address is required"),
  city: z.string().min(2, "City is required"),
  country: z.literal("Pakistan"),
  paymentMethod: z.literal("Cash on Delivery"),
});
