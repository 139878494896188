import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddToList from "../components/AddToList";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./style.css";
import { UserAuth } from "../Context";
import BrandDisplay from "../NewsFeed/BrandDisplay";
import formatPrice from "../lib/formatPrice";
import { getBrandDetails } from "../lib/getBrandDetails";
import useFetch from "../hooks/useFetch";
import usePost from "../hooks/usePost";
import { db } from "../FirebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import AddToChat from "../components/AddToChat";
import AddToCart from "../components/AddToCart";
import AddOrRemoveSaved from "./AddOrRemoveSaved";
import AddOrRemoveLike from "./AddOrRemoveLike";

const ProductCard5 = ({ product, isStrip, addButtonData }) => {
  const [likeCount, setLikeCount] = useState(product?.likes);
  const [favouritesCount, setFavouritesCount] = useState(product?.favourites);
  const [brandData, setBrandData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isInList, setIsInList] = useState(false);
  const [showToolTipCart, setShowToolTipCart] = useState(false);
  const [showToolTipFavorites, setShowToolTipFavorites] = useState(false);
  const [showToolTipChat, setShowToolTipChat] = useState(false);
  const [showToolTipAddToList, setShowToolTipAddToList] = useState(false);
  const [loginCount, setLoginCount] = useState(0);
  const [showCard, setShowCard] = useState(true);

  // const activeBrands = useActiveBrandsStore((state) => state.activeBrands);
  const { user } = UserAuth();
  const { data: activeBrands } = useFetch(
    `${process.env.REACT_APP_API}/api/activeBrands`
  );
  let url = process.env.REACT_APP_API + `/api/activity/${user?.uid}`;

  // let url = process.env.REACT_APP_API+`/api/logs/${user?.uid}`;
  const { data: postData, post } = usePost(url);

  const fetchBrandData = async () => {
    try {
      // Fetch the brand's display name and logo using the getDisplayName function
      const result = await getBrandDetails(product?.supplier, activeBrands);
      setBrandData(result);
      // console.log("result", result);
    } catch (error) {
      console.error("Error getting brand data:", error);
    }
  };

  const checkProductInList = async () => {
    const userRef = doc(
      db,
      "users",
      addButtonData.userId,
      "shoppingList",
      addButtonData.selectedShopList,
      "products",
      product?.id
    );
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      setIsInList(true);
    }
  };

  useEffect(() => {
    if (!product) {
      console.log("product is missing");
      return;
    }
    if (!activeBrands) {
      console.log("activeBrands is missing");
      return;
    }
    fetchBrandData();
  }, [product, activeBrands]);

  useEffect(() => {
    if (!product || !addButtonData) return;
    checkProductInList();
  }, [product, addButtonData]);

  const handleImageLoad = () => {
    setLoading(false);
  };

  const fetchLoginCount = async () => {
    const userRef = doc(db, "users", user?.uid);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      setLoginCount(userSnap?.data().loginCount || 0);
    }
  };

  useEffect(() => {
    if (!user) return;
    fetchLoginCount();
  }, [user]);

  const handleDismiss = () => {
    setShowToolTipCart(false);
    setShowToolTipFavorites(false);
    setShowToolTipChat(false);
    setShowToolTipAddToList(false);
  };

  const renderTooltipCart = (props) => (
    <Tooltip
      id="button-tooltip"
      className="custom-tooltip bg-secondary rounded-2 p-0"
      {...props}
    >
      👋 Welcome! Click here to add this item to your Cart.
    </Tooltip>
  );
  const renderTooltipChat = (props) => (
    <Tooltip
      id="button-tooltip"
      className="custom-tooltip bg-secondary rounded-2 p-0"
      {...props}
    >
      👋 Welcome! Click here to share this item with your friends.
    </Tooltip>
  );
  const renderTooltipAddToList = (props) => (
    <Tooltip
      id="button-tooltip"
      className="custom-tooltip bg-secondary rounded-2 p-0"
      {...props}
    >
      👋 Welcome! Click here to save this item to your list.
    </Tooltip>
  );
  if (!showCard) return;
  // if (product?.name === "Cambric Shalwar") console.log("product", product);
  return (
    <div className="mt-0 mb-1 mb-sm-1">
      <Card>
        <div style={{ position: "relative", height: "auto" }}>
          {loading && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
                backgroundColor: "#eef0f2", // background for the placeholder
              }}
            >
              {/* Placeholder: Can be an image, a spinner, or any other component */}
              {/* <Spinner
                animation="border"
                style={{ color: "grey", width: "20px", height: "20px" }}
              /> */}
            </div>
          )}
          <Link to={`/ProductDetails/${product?.id}`}>
            <Card.Img
              src={product?.imageUrl}
              style={{
                opacity: loading ? 0 : 1, // Hide the image until it's fully loaded
                transition: "opacity 0.5s ease-in-out",
                objectFit: "cover",
                display: "inline",
              }}
              onLoad={handleImageLoad}
              onError={() => setShowCard(false)}
              loading="lazy"
            />
          </Link>
        </div>

        {/* Price Tag */}
        <div
          className="position-absolute rounded-pill top-0 start-0 m-2"
          style={{
            fontSize: "12px",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          }}
        >
          {product?.newPrice && (
            <p className="m-0 text-white px-2 py-1">
              PKR{" "}
              {product?.newPrice
                ? formatPrice(product.newPrice)
                : formatPrice(product.oldPrice)}
            </p>
          )}
        </div>
        <div
          className="d-inline-flex ps-2 pe-2 align-items-center justify-content-center rounded-pill text-light position-absolute end-0 m-2"
          style={{
            fontSize: "1.3rem",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          }}
        >
          {/* <Link className="text-center text-body"> */}
          <OverlayTrigger
            show={loginCount > 5 ? false : showToolTipCart}
            placement="left" // You can change this to 'top', 'left','right, 'bottom'
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltipCart}
          >
            <Link
              className="text-center text-light"
              onClick={handleDismiss}
              onMouseLeave={handleDismiss}
              onMouseEnter={() => setShowToolTipCart(true)}
            >
              <AddToCart user={user} product={product} />
            </Link>
          </OverlayTrigger>
          {/* </Link> */}
        </div>
        {/* Plus Button to add product in the list */}
        {/* {addButtonData && (
          <div
            className="position-absolute rounded-circle top-0 end-0 m-2"
            style={{
              fontSize: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              if (!isInList) {
                addButtonData.handleAddProductToList(product?.id, product);
                setIsInList(true);
              }
            }}
          >
            {isInList ? (
              <CheckCircleFill className="fs-4 text-body" />
            ) : (
              <PlusCircleFill className="fs-4 text-body" />
            )}
          </div>
        )} */}
        {/* Strip */}
        {(isStrip === undefined || isStrip === true) && (
          <>
            <div
              className="d-inline-flex ps-1 pe-2 align-items-center justify-content-center rounded-pill text-light position-absolute bottom-0 end-0"
              style={{
                fontSize: "12px",
                marginBottom: "2.4rem",
                marginRight: "0.5rem",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
              }}
            >
              <OverlayTrigger
                show={loginCount > 5 ? false : showToolTipAddToList}
                placement="left" // You can change this to 'top', 'left','right, 'bottom'
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltipAddToList}
              >
                <span
                  onClick={handleDismiss}
                  onMouseLeave={handleDismiss}
                  onMouseEnter={() => setShowToolTipAddToList(true)}
                >
                  <AddToList product={product} componentName={"strip2"} />
                </span>
              </OverlayTrigger>
            </div>
            <div
              className="d-inline-flex px-2 align-items-center justify-content-center rounded-pill text-light position-absolute bottom-0 end-0 m-2"
              style={{
                fontSize: "12px",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
              }}
            >
              <OverlayTrigger
                show={loginCount > 5 ? false : showToolTipChat}
                placement="left" // You can change this to 'top', 'left','right, 'bottom'
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltipChat}
              >
                <span
                  onClick={handleDismiss}
                  onMouseLeave={handleDismiss}
                  onMouseEnter={() => setShowToolTipChat(true)}
                >
                  <AddToChat product={product} />
                </span>
              </OverlayTrigger>
            </div>
            <div
              className="align-items-center justify-content-center rounded-pill position-absolute bottom-0 start-0 m-2"
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.6)",
              }}
            >
              <Link
                className="text-center text-light d-flex align-items-center gap-1 px-2"
              >
                <AddOrRemoveLike
                  user={user}
                  product={product}
                  setLikeCount={setLikeCount}
                  componentName={"strip2"}
                />
                <div style={{ fontSize: "10px" }}>({likeCount})</div>
              </Link>
            </div>
          </>
          //   <Link className="text-center d-flex flex-column nav-link" to="">
          //     <i className="bi bi-eye"></i>
          //     <div
          //       style={{
          //         fontSize: "10px",
          //         marginTop: "-5px",
          //         marginBottom: "-2px",
          //       }}
          //     >
          //       {product?.views ?? 0}
          //     </div>
          //   </Link>

          //   <Link
          //     className="text-center text-light"
          //     onClick={handleDismiss}
          //     onMouseLeave={handleDismiss}
          //     onMouseEnter={() => setShowToolTipFavorites(true)}
          //   >
          //     <AddOrRemoveFavourites
          //       product={product}
          //       user={user}
          //       setFavouritesCount={setFavouritesCount}
          //     />
          //     <div style={{ fontSize: "10px", marginTop: "-7px" }}>
          //       {favouritesCount ?? 0}
          //     </div>
          //   </Link>

          //   <span
          //     onClick={handleDismiss}
          //     onMouseLeave={handleDismiss}
          //     onMouseEnter={() => setShowToolTipChat(true)}
          //   >
          //     <ShareMenu2 product={product} />
          //   </span>

          //   <span
          //     onClick={handleDismiss}
          //     onMouseLeave={handleDismiss}
          //     onMouseEnter={() => setShowToolTipAddToList(true)}
          //   >
          //     <AddToList product={product} componentName={"strip2"} />
          //   </span>

          //   <span
          //     onClick={handleDismiss}
          //     onMouseLeave={handleDismiss}
          //     onMouseEnter={() => setShowToolTipAddToList(true)}
          //   >
          //     <AddToChat product={product} />
          //   </span>
          // </div>
        )}
      </Card>

      <div className="pe-1 pb-1">
        <div className="d-flex align-items-center">
          {/* Logo */}
          <div
            className="avatar me-2"
            style={{ width: "35px", height: "35px" }}
          >
            <Link to={"/BrandHome/" + product?.supplier}>
              <img
                className="avatar-img rounded"
                src={brandData?.logo}
                alt=""
              />
            </Link>
          </div>
          <div
            className="d-flex flex-column justify-content-start"
            style={{ width: "78%" }}
          >
            <div className="mb-0">
              <BrandDisplay brandData={brandData} type={"ProductCard"} />
            </div>
            <div
              className="fw-bold h6 position-relative"
              style={{
                marginTop: "2px",
                fontSize: "12px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {product?.name}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard5;
