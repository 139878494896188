import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  FormCheck,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
} from "react-bootstrap";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { UserAuth } from "../Context";
import ProfilePicture from "../UserProfile/ProfilePicture";
import { Eye } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
const PrivacySetting = () => {
  const { user } = UserAuth();
  const [activeTab, setActiveTab] = useState("");
  const [checkBox, setCheckBox] = useState({
    friendRequest: true,
    message: true,
    profilePicture: true,
    birthday: true,
    location: true,
  });
  const [blockedUsers, setBlockedUsers] = useState([
    {
      id: "asad",
      userName: "Asad M.Furqan",
    },
    {
      id: "fahad",
      userName: "Fahad Ausaf",
    },
    {
      id: "osama",
      userName: "Osama A.Siddique",
    },
    {
      id: "sami",
      userName: "Abdul Sami",
    },
  ]);
  const [showModal, setShowModal] = useState(false);
  const fetchCheckBoxData = async () => {
    const userRef = doc(db, "users", user?.uid);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      const data = userSnap.data()?.privacySetting || {
        friendRequest: true,
        message: true,
        profilePicture: true,
        birthday: true,
        location: true,
      };
      setCheckBox(data);
    }
  };
  const fetchBlockedUsersData = async () => {
    const userRef = collection(db, "users", user?.uid, "blockedUsers");
    const userSnap = await getDocs(userRef);
    if (userSnap.size > 0) {
    }
  };
  const handleClose = () => setShowModal(false);
  useEffect(() => {
    fetchCheckBoxData();
  }, [user]);

  const handleSubmit = async () => {
    const userRef = doc(db, "users", user?.uid);
    await updateDoc(userRef, { privacySetting: { ...checkBox } });
    toast.success("Changes saved successfully!", toastOptions);
  };
  const handleUnblock = (index) => {
    toast.success(`Unblocked ${blockedUsers[index].userName}`, toastOptions);
    const tempBlockUsers = blockedUsers.filter(
      (user) => user.id != blockedUsers[index].id
    );
    setBlockedUsers(tempBlockUsers);
  };
  return (
    <>
      <Card className="w-100">
        {/* <Card.Header className="border-0 pb-0">
          <Card.Title>
            <h5 className="card-title"> Privacy Settings</h5>
          </Card.Title>
        </Card.Header> */}
        <ul className="card-header nav nav-tabs nav-bottom-line2 active justify-content-center justify-content-md-start my-0 pe-3 py-0 ps-4">
          <li className="nav-item">
            <Link
              className={`nav-link pt-3 pb-2 ${
                activeTab === "privacy" ? "active" : ""
              }`}
              // onClick={() => setActiveTab("privacy")}
            >
              Privacy Settings
            </Link>
          </li>
        </ul>
        <Card.Body className="pt-2 ms-1">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div className="mb-3">
              <ul className="list-group">
                <li className="list-group-item border-0 px-0 d-flex justify-content-between align-items-center">
                  <div>
                    <label className="mb-0 h6" htmlFor="friendRequestCheckbox">
                      Friend Request
                    </label>
                    <p className="small mb-0">
                      Allow anyone to send you friend request
                    </p>
                  </div>
                  <FormCheck className="form-switch">
                    <FormCheckInput
                      id="friendRequestCheckbox"
                      type="checkbox"
                      role="switch"
                      checked={checkBox.friendRequest}
                      onChange={() =>
                        setCheckBox((prev) => ({
                          ...prev,
                          friendRequest: !prev.friendRequest,
                        }))
                      }
                    />
                  </FormCheck>
                </li>
                <li className="list-group-item border-0 px-0 d-flex justify-content-between align-items-center">
                  <div>
                    <label className="mb-0 h6" htmlFor="messageCheckbox">
                      Message
                    </label>
                    <p className="small mb-0">
                      Allow anyone to send you send message
                    </p>
                  </div>
                  <FormCheck className="form-switch">
                    <FormCheckInput
                      id="messageCheckbox"
                      type="checkbox"
                      role="switch"
                      checked={checkBox.message}
                      onChange={() =>
                        setCheckBox((prev) => ({
                          ...prev,
                          message: !prev.message,
                        }))
                      }
                    />
                  </FormCheck>
                </li>
                <li className="list-group-item border-0 px-0 d-flex justify-content-between align-items-center">
                  <div>
                    <label className="mb-0 h6" htmlFor="pfpCheckBox">
                      Profile picture
                    </label>
                    <p className="small mb-0">
                      Allow anyone to see your profile picture
                    </p>
                  </div>
                  <FormCheck className="form-switch">
                    <FormCheckInput
                      id="pfpCheckBox"
                      type="checkbox"
                      role="switch"
                      checked={checkBox.profilePicture}
                      onChange={() =>
                        setCheckBox((prev) => ({
                          ...prev,
                          profilePicture: !prev.profilePicture,
                        }))
                      }
                    />
                  </FormCheck>
                </li>
                <li className="list-group-item border-0 px-0 d-flex justify-content-between align-items-center">
                  <div>
                    <label className="mb-0 h6" htmlFor="birthdayCheckBox">
                      Birthday
                    </label>
                    <p className="small mb-0">
                      Allow anyone to see your birthday
                    </p>
                  </div>
                  <FormCheck className="form-switch">
                    <FormCheckInput
                      id="birthdayCheckBox"
                      type="checkbox"
                      role="switch"
                      checked={checkBox.birthday}
                      onChange={() =>
                        setCheckBox((prev) => ({
                          ...prev,
                          birthday: !prev.birthday,
                        }))
                      }
                    />
                  </FormCheck>
                </li>
                <li className="list-group-item border-0 px-0 d-flex justify-content-between align-items-center">
                  <div>
                    <label className="mb-0 h6" htmlFor="locationCheckBox">
                      Location
                    </label>
                    <p className="small mb-0">
                      Allow anyone to see your location
                    </p>
                  </div>
                  <FormCheck className="form-switch">
                    <FormCheckInput
                      id="locationCheckBox"
                      type="checkbox"
                      role="switch"
                      checked={checkBox.location}
                      onChange={() =>
                        setCheckBox((prev) => ({
                          ...prev,
                          location: !prev.location,
                        }))
                      }
                    />
                  </FormCheck>
                </li>
                {/* <li className="list-group-item border-0 px-0 d-flex justify-content-between align-items-center">
                  <div>
                    <h6 className="mb-0">Block users</h6>
                    <p className="small mb-0">
                      See users you have blocked before
                    </p>
                  </div>
                  <Button
                    className="rounded-2 px-2 py-1"
                    variant="primary-soft"
                    size="sm"
                    onClick={() => setShowModal(true)}
                  >
                    <Eye className="fs-6 me-1" />
                    <span>View</span>
                  </Button>
                </li> */}
              </ul>
            </div>
            <div className="col-12 text-end">
              <button type="submit" className="btn btn-sm btn-primary mb-0">
                Save changes
              </button>
            </div>
          </form>
        </Card.Body>
      </Card>
      <Modal show={showModal} centered onHide={handleClose}>
        <ModalHeader closeButton className="pb-1">
          <ModalTitle>
            <div className="h5 fw-semibold">Blocked Users</div>
          </ModalTitle>
        </ModalHeader>
        <ModalBody className="py-0">
          {blockedUsers.map((user, index) => {
            return (
              <div className="my-3 d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <div className="avatar">
                    <ProfilePicture userData={user} />
                  </div>
                  <div className="mx-2">
                    <h6 className="mb-0">{user.userName}</h6>
                  </div>
                </div>
                <Button
                  variant="primary-soft"
                  size="sm"
                  onClick={() => handleUnblock(index)}
                >
                  Unblock
                </Button>
              </div>
            );
          })}
        </ModalBody>
      </Modal>
    </>
  );
};

export default PrivacySetting;
