import React, { useState } from "react";
import UserPosts from "./UserPosts";
import SavedPosts from "./SavedPosts";
import useUserDataStore from "../StatesStore/UserData";

function Posts() {
  const [activeTab, setActiveTab] = useState("userPosts");
  const userData = useUserDataStore((state) => state.userData);

  // Function to render active tab content
  const renderTabContent = () => {
    switch (activeTab) {
      case "userPosts":
        return <UserPosts userData={userData} componentName={"Posts"} />;
      case "savedPosts":
        return <SavedPosts userData={userData} componentName={"Posts"} />;
      default:
        return <UserPosts userData={userData} componentName={"Posts"} />;
    }
  };

  return (
    <>
      <div className="card border-bottom-0" style={{ minHeight: "38rem" }}>
        {/* Tab Navigation */}
        <ul className="card-header border-bottom-0 nav nav-tabs nav-bottom-line2 active justify-content-center justify-content-md-start my-0 pe-3 py-0 ps-4">
          <li className="nav-item">
            <button
              className={`nav-link pt-3 pb-2 ${
                activeTab === "userPosts" ? "active" : ""
              }`}
              onClick={() => setActiveTab("userPosts")}
            >
              My Posts
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link pt-3 pb-2 ${
                activeTab === "savedPosts" ? "active" : ""
              }`}
              onClick={() => setActiveTab("savedPosts")}
            >
              Saved Posts
            </button>
          </li>
        </ul>
        <div className="p-0">
          {/* Tab Content */}
          {renderTabContent()}
        </div>
      </div>
    </>
  );
}

export default Posts;
