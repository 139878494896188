import React, { useState, useEffect } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { UserAuth } from "../Context";
import { db } from "../FirebaseConfig";
import BrandPosts from "./BrandPosts";
import ProductCard from "../Products/ProductCard";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import SideNavBrands from "../SideNav/SideNavBrands";
import DiscountCount from "./DiscountCount";
import BrandProducts from "./BrandProducts";
import handlefollowBrand from "../hooks/brand/followBrand";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import useModalStore from "../StatesStore/ModalStore";
import GoToTop from "../components/gotoTop";
import { Button, Card, Modal } from "react-bootstrap";
import algoliasearch from "algoliasearch";
import UpdateBrandPFP from "./UpdateBrandPFP";
import BrandFeedback from "./BrandFeedback";
import ReviewsList from "./renderBrandFeedbacks";
import ProfilePicture from "../UserProfile/ProfilePicture";
import LoadingSpinner from "../UserProfile/LoadingSpinner";
import { Funnel, List } from "react-bootstrap-icons";
import ProductCardBrandsPage from "../Products/ProductCardBrandsPage";
import BrandFollower from "./BrandFollower";
//algolia object initialization and index mapping
const client = algoliasearch("EMFUSB67CG", "0f7466afc30b24c667a61b43cf879898");
const Productindex = client.initIndex("products"); //name of index in algolia is products

function BrandHome3() {
  let { brandName } = useParams() || "";
  const { user } = UserAuth();
  const [brandInfo, setBrandInfo] = useState();
  const [womenCategories, setWomenCategories] = useState(null);
  const [averageRating, setAverageRating] = useState(null);
  const [menCategories, setMenCategories] = useState(null);
  const [selectedOption, setSelectedOption] = useState("allProducts");
  const collectionRef = collection(db, "brands");
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [handlerIds, setHandlerIds] = useState([]);
  const [FollowButton, setFollowButton] = useState(false);
  const [FollowerCount, setFollowerCount] = useState(0);
  const { openModal } = useModalStore();
  const [reviewTrigger, setReviewTrigger] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [logoUpdate, setLogoUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reviewCount, setReviewCount] = useState(0);
  const [input, setInput] = useState("");
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [followerList, setFollowerList] = useState([]);
  // const q2 = useQuery();

  const handleReviewModal = () => setShowReviewModal(!showReviewModal);

  const fetchData = async (brandname) => {
    try {
      console.log("brandname:", brandname);
      if (!brandname) {
        console.error("brandname is undefined or null");
        return;
      }

      if (!user?.uid) {
        console.error("User ID is undefined or null");
        return;
      }

      const checkBrandSnapshot = doc(
        db,
        "users",
        user?.uid,
        "following",
        brandname
      );
      const checkBrandData = await getDoc(checkBrandSnapshot);

      if (checkBrandData.exists()) setFollowButton(true);
      else setFollowButton(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchCount = async () => {
    const brandFollowerRef = collection(collectionRef, brandName, "followers");
    const brandFollowerData = await getDocs(brandFollowerRef);
    const followers = await Promise.all(
      brandFollowerData.docs.map(async (follower) => {
        const userRef = doc(db, "users", follower?.id);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists())
          return { ...userSnap?.data(), uid: userSnap?.id };
      })
    );
    const filteredFollowers = followers.filter(
      (follower) => follower !== undefined
    );
    // console.log("BRAND followers", filteredFollowers);
    setFollowerList(filteredFollowers);
    setFollowerCount(filteredFollowers?.length);
  };

  const fetchHandler = async () => {
    const brandHanderdRef = collection(db, "brands", brandName, "brandHandler");
    try {
      const brandHandlerSnapshot = await getDocs(brandHanderdRef);
      const handlerIds = brandHandlerSnapshot.docs.map((doc) => doc.id); // Mapping through to get all document IDs
      console.log("Handler Document IDs in brands home: ", handlerIds);
      // return handlerIds;  // You can return these IDs if you need to use them elsewhere
      setHandlerIds(handlerIds);
    } catch (error) {
      console.error("Error fetching handler document IDs: ", error);
    }
  };

  const getBrandDetails = async () => {
    const q = query(collectionRef, where("name", "==", brandName));
    const snapshot = await getDocs(q);
    const brandDoc = snapshot.docs[0];
    setBrandInfo(snapshot.docs[0].data());

    // Increment views in the database
    const brandDocRef = doc(db, "brands", brandDoc.id);
    await updateDoc(brandDocRef, {
      views: brandDoc.data().views + 1,
    });
  };

  useEffect(() => {
    setWomenCategories(null);
    setMenCategories(null);

    const getCategories = async () => {
      const data = collection(db, "universalCategories");
      const querySnapshot = await getDocs(data);
      const categoriesData = querySnapshot.docs.map(
        (doc) => doc.data().subCategories
      );

      // Check if "Women" is present in brandInfo categories
      if (
        brandInfo?.category.includes("Women") &&
        brandInfo?.category.includes("Men")
      ) {
        // If both Men and Women categories are present, render separate dropdowns
        setWomenCategories(categoriesData[1]);
        setMenCategories(categoriesData[0]);
      } else if (brandInfo?.category.includes("Women")) {
        // If only Women category is present, set categories to Women

        setWomenCategories(categoriesData[1]);
      } else if (brandInfo?.category.includes("Men")) {
        // If only Men category is present, set categories to Men

        setMenCategories(categoriesData[0]);
      }
    };

    getCategories();
  }, [brandInfo]);

  const handleClick = () => {
    if (!user) {
      // Check if user is not logged in before opening modal
      openModal("SignInModal", "", ""); // Open modal here
    } else {
      const DATA = {
        displayName: brandInfo.displayName,
        FollowButton: setFollowButton,
        SetFollowerCount: setFollowerCount,
        UID: user.uid,
        brandName: brandName,
      };
      openModal("ConfirmUnfollow", "brand", DATA); // Open modal here
    }
  };

  const handleSearch = async (event, brandName) => {
    // console.log('input in handler search : ',input);
    event.preventDefault();
    const itemsPerPage = 12;

    if (input.trim().length >= 3) {
      const searchQuery = `${input.trim()} ${brandName}`;
      console.log("Search input: ", searchQuery);
      try {
        const offset = 0;
        const data = await Productindex.search(searchQuery, {
          offset,
          length: itemsPerPage,
        });
        const uniqueProducts = new Set([...data.hits]);
        const updatedProducts = Array.from(uniqueProducts);
        console.log(
          `${brandName} products in search on Brand Page: `,
          updatedProducts
        );
        setSearchResults(updatedProducts);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    } else {
      console.log("Please enter at least 3 characters to search.");
    }
    if (searchResults.length > 0) {
      setSelectedOption("searchResult");
    }
    if (searchResults.length == 0) {
      toast.warn(
        `No products found with search query:  ${input.trim()} `,
        toastOptions
      );
    }
  };

  useEffect(() => {
    const loadAllData = async () => {
      try {
        setLoading(true); // Set loading at the start of data fetching
        await getBrandDetails();
        await fetchHandler();
        await fetchData(brandName);
        await fetchCount();
        await fetchReviews();
        setLoading(false); // Set loading to false after all data is fetched
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        setLoading(false); // Ensure loading is set to false in case of an error
      }
    };

    if (brandName) {
      loadAllData();
    }
  }, [brandName, logoUpdate]);

  if (loading) {
    return <LoadingSpinner />;
  }

  const fetchReviews = async () => {
    try {
      const reviewsRef = collection(db, `brands/${brandName}/reviews`);
      const querySnapshot = await getDocs(reviewsRef);
      const reviewsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // setReviews(reviewsData);
      setReviewCount(reviewsData.length);
      if (reviewsData.length > 0) {
        const totalRating = reviewsData.reduce(
          (acc, review) => acc + review.rating,
          0
        );
        const avgRating = (totalRating / reviewsData.length).toFixed(1);
        // console.log(avgRating);
        console.log("avgRating for brand: ", avgRating, reviewCount);
        setAverageRating(avgRating);
      }
    } catch (error) {
      console.error("Error fetching reviews:", error);
      // setError("Failed to load reviews.");
    }
  };

  const toggleReviewTrigger = () => {
    setReviewTrigger((prev) => !prev); // Toggle to force re-render
  };

  return (
    <>
      <GoToTop />
      <div style={{ width: "50%" }} className="vstack px-0 ms-0 ms-lg-1 ">
        {/* BrandHeader */}
        <Card className="mb-2 bg-transparent border-0">
          <Card.Body className="p-0 mb-4">
            <div className="d-flex d-sm-none gap-2">
              {/* Profile Picture */}
              <div
                className="avatar avatar-xl userImage"
                style={{
                  width: "7rem", // Fixed width
                  height: "7rem", // Fixed height
                  overflow: "hidden", // Hide overflow to maintain square shape
                }}
              >
                {handlerIds?.includes(user?.uid) ? (
                  <UpdateBrandPFP
                    BrandId={brandName} // Ensure that brandInfo has an 'id' property
                    brandData={brandInfo}
                    className={"brandImage avatar avatar-xxxl"} // Set the size based on your UI design
                    onLogoUpdate={setLogoUpdated} // Uncomment and implement if needed
                  />
                ) : (
                  <ProfilePicture userData={brandInfo} />
                )}
              </div>
              <div className="brandDetailsAndFollowButton d-flex flex-column justify-content-between w-100 mt-3">
                <div>
                  <div className="brandName h5 m-0 p-0">
                    {brandInfo ? brandInfo?.displayName : ""}
                  </div>
                  <ul className="list-inline mb-0 gap-1 d-flex flex-wrap">
                    {/* Product Count */}
                    <li className="list-inline-item d-flex gap-1 align-items-center">
                      <DiscountCount brandName={brandName} /> Products
                    </li>
                    {/* Follower Count */}
                    <li className="list-inline-item d-flex gap-1 align-items-center">
                      {FollowerCount} Followers
                    </li>
                    {/* <li
                      className="list-inline-item d-flex gap-1 align-items-center"
                      onClick={(e) => {
                        e.preventDefault(); // Prevent default link behavior
                        handleReviewModal();
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {reviewCount} Reviews
                    </li> */}
                    {/* {user && (
                      <li className="rating list-inline-item d-flex gap-1 align-items-center">
                        {averageRating ? (
                          <>
                            <div className="d-flex">
                              {[...Array(5)].map((_, starIndex) => (
                                <span key={starIndex}>
                                  {starIndex < Math.round(averageRating) ? (
                                    <i className="bi bi-star-fill text-warning"></i>
                                  ) : (
                                    <i className="bi bi-star text-warning"></i>
                                  )}
                                </span>
                              ))}
                            </div>
                            <span className="mx-2">Rating</span>
                          </>
                        ) : (
                          <p
                            className="mb-0"
                            onClick={handleReviewModal}
                            style={{ cursor: "pointer" }}
                          >
                            Be the first to rate
                          </p>
                        )}
                      </li>
                    )} */}
                  </ul>
                </div>
                <div className="followButton">
                  <button
                    className={`btn btn-primary`}
                    type="button"
                    onClick={async () => {
                      if (!user) handleClick();
                      else {
                        if (FollowButton) {
                          handleClick();
                        } else {
                          setFollowButton(true);
                          toast.success(
                            "Brand followed successfully",
                            toastOptions
                          );
                          setFollowerCount((prev) => prev + 1);
                          await handlefollowBrand(user?.uid, brandName);
                        }
                      }
                    }}
                  >
                    <div>{FollowButton ? "Unfollow" : "Follow"}</div>
                  </button>
                </div>
              </div>
            </div>
            <div className="d-none d-sm-flex gap-3">
              <div
                className="avatar avatar-xl brandImage"
                style={{
                  width: "150px", // Fixed width
                  height: "150px", // Fixed height
                  overflow: "hidden", // Hide overflow to maintain square shape
                }}
              >
                {handlerIds?.includes(user?.uid) ? (
                  <UpdateBrandPFP
                    BrandId={brandName} // Ensure that brandInfo has an 'id' property
                    brandData={brandInfo}
                    className={"brandImage avatar avatar-xxxl"} // Set the size based on your UI design
                    onLogoUpdate={setLogoUpdated} // Uncomment and implement if needed
                  />
                ) : (
                  <ProfilePicture userData={brandInfo} />
                )}
              </div>
              <div className="details d-flex flex-row justify-content-between align-items-start w-100 mt-5">
                <div>
                  <div className="brandName h3 m-0 p-0">
                    {brandInfo?.displayName}
                  </div>
                  {/* Flex container to ensure horizontal layout on desktop and wrap on mobile */}
                  <ul
                    className="navbar nav align-items-start justify-content-center justify-content-sm-start p-0 m-0 border-0 h6 gap-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {/* Product Count */}
                    <li className="nav-item fw-light text-body">
                      <div className={`p-0`}>
                        <DiscountCount brandName={brandName} /> Products
                      </div>
                    </li>
                    {/* Follower Count */}
                    <li className="nav-item">
                      <div
                        className={`nav-link p-0 ${
                          selectedOption === "follower" ? "active" : ""
                        }`}
                        style={{ cursor: "pointer" }}
                        onClick={() => setSelectedOption("follower")}
                      >
                        {FollowerCount} Followers
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="followButton">
                  <Link
                    className="btn btn-primary"
                    type="button"
                    onClick={async () => {
                      if (!user) handleClick();
                      else {
                        if (FollowButton) {
                          handleClick();
                        } else {
                          setFollowButton(true);
                          toast.success(
                            "Brand followed successfully",
                            toastOptions
                          );
                          setFollowerCount((prev) => prev + 1);
                          await handlefollowBrand(user?.uid, brandName);
                        }
                      }
                    }}
                  >
                    {FollowButton ? "Unfollow" : "Follow"}
                  </Link>
                </div>
              </div>
            </div>
          </Card.Body>
          <Card.Footer className="p-0 border rounded-2">
            <ul
              className={`navbar nav align-items-center justify-content-between py-2 py-lg-3 pe-2 border-0 h6 `}
            >
              {/* <div className="d-block"></div> */}
              <div className="d-flex gap-1">
                <li className="nav-item">
                  <Link
                    className={`nav-link py-0 ${
                      selectedOption === "allProducts" ? "active" : ""
                    }`}
                    onClick={() => {
                      setSelectedOption("allProducts");
                      setInput(""); // Reset input
                      setSearchResults([]); // Reset search results
                    }}
                  >
                    All Products
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link py-0 ps-2 ${
                      selectedOption === "newsfeed" ? "active" : ""
                    }`}
                    onClick={() => {
                      setSelectedOption("newsfeed");
                      setInput(""); // Reset input
                      setSearchResults([]); // Reset search results
                    }}
                  >
                    Newsfeed
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link
                    className={`nav-link py-0 ${
                      selectedOption === "newArrivals" ? "active" : ""
                    }`}
                    onClick={() => {
                      setSelectedOption("newArrivals");
                      setInput(""); // Reset input
                      setSearchResults([]); // Reset search results
                    }}
                  >
                    New Arrivals
                  </Link>
                </li> */}
              </div>
              <Button
                variant="primary"
                className="border-0 rounded-2 p-0 text-white"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasFilterNav"
                aria-controls="offcanvasFilterNav"
              >
                <div className="d-flex align-items-center p-1 p-sm-2 ">
                  <span className="btn text-light p-0 me-1 ">
                    <Funnel className="fs-5 " />
                  </span>
                  <span className="">Filters</span>
                </div>
              </Button>
            </ul>
          </Card.Footer>
        </Card>
        <div className="mx-0 d-flex">
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              {/* BrandPosts */}
              <div
                style={{ minWidth: "72%", width: "72%" }}
                className="vstack gap-0 mt-2 p-0 "
              >
                {selectedOption === "newsfeed" ? (
                  <>
                    <div
                      className="card d-flex align-items-center justify-content-center shadow-lg py-5"
                      style={{ height: "20rem" }}
                    >
                      <h2 className="text-body">
                        NewsFeed will be live soon!!
                      </h2>
                    </div>
                  </>
                ) : selectedOption === "allProducts" ? (
                  <BrandProducts brandName={brandName} />
                ) : selectedOption === "newArrivals" ? (
                  <BrandPosts />
                ) : selectedOption === "follower" ? (
                  <BrandFollower followerList={followerList} />
                ) : selectedOption === "searchResult" ? (
                  <>
                    <div className="card-body">
                      <div className="row g-4">
                        {searchResults.length > 0 &&
                          searchResults.map((product, index) => (
                            <div className="col-6 col-sm-4 col-md-3 col-lg-4 px-1 mt-2">
                              <ProductCardBrandsPage
                                key={index}
                                product={product}
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  </>
                ) : (
                  // Newsfeed component will show
                  <></>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {/* Funnels nav */}
      {selectedOption !== "newsfeed" && selectedOption !== "newArrivals" && (
        <>
          <SideNavBrands brandName={brandName} />
        </>
      )}

      <Modal show={showReviewModal} onHide={handleReviewModal} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <BrandFeedback
            userId={user?.uid} // Pass the logged-in user ID
            brandName={brandName}
            brandDisplayName={brandInfo?.displayName}
            // Assuming brandName is the productID or you can adjust as per your schema
            onReviewSubmit={() => {
              toggleReviewTrigger(); // Close modal on successful submission
            }}
          />
          <ReviewsList brandId={brandName} trigger={reviewTrigger} />
        </Modal.Body>
      </Modal>

      <Modal
        show={showSearchModal}
        onHide={() => setShowSearchModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Search Products</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={(event) => handleSearch(event, brandName)}
            className="d-flex"
          >
            <input
              type="text"
              className="form-control me-2"
              placeholder="Search products..."
              value={input}
              onChange={(e) => setInput(e.target.value)}
              required
            />
            <Button variant="primary" type="submit">
              Search
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default BrandHome3;
