import React from "react";
import { Link } from "react-router-dom";
import ProfilePicture from "../UserProfile/ProfilePicture";
import { useFetchUserData } from "../hooks/user/useFetchUserData";

function UserCard3Footer({ post }) {
  const { userData } = useFetchUserData(post.userId);
  return (
    <div className="pe-1 pb-2">
      <div className="d-flex align-items-center">
        <div
          className="avatar me-2 mt-1"
          style={{ width: "35px", height: "35px" }}
        >
          <Link to={`/userProfile/${userData?.userId}`}>
            <ProfilePicture
              userData={userData}
              className="avatar-img rounded-2"
            />
          </Link>
        </div>
        <div
          className="d-flex flex-column justify-content-start"
          style={{ width: "78%" }}
        >
          <div className="mb-0">
            <div
              className="nav nav-divider"
              style={{ marginTop: "10px", marginBottom: "2px" }}
            >
              <div
                className={`nav-item card-title mb-0 fw-light h6`}
                style={{
                  fontSize: "12px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <Link
                  className={`nav-link`}
                  to={`/userProfile/${userData?.userId}`}
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {userData?.userName
                    ? userData?.userName
                        .split(" ")
                        .slice(0, 2)
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")
                    : ""}
                </Link>
              </div>
            </div>
          </div>
          <div
            className="fw-bold h6 mb-0 position-relative"
            style={{
              fontSize: "12px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {post?.title || ""}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserCard3Footer;
