import React, { useEffect, useState } from "react";
import {
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { Link } from "react-router-dom";

import {
  JoinedUser,
  PostLike,
  FollowedMe,
  ShoppingListChatMessage,
  FirstTimeListView,
  ListProductLike,
  FriendRequest,
  AcceptFriendRequest,
  InviteAccepted,
  ShoppingListInvite,
} from "./NotificationTypes";
import { GroupedNotification } from "./GroupNotification";
import useNotificationStore from "../StatesStore/Notifications";
import ProductPriceChange from "./ProductPriceChange";
import BrandHandlerInvite from "./BrandHandlerInvite";
import PostComment from "./PostComment";
import { Dropdown } from "react-bootstrap";
import { Bell, BellFill } from "react-bootstrap-icons";

const notificationComponents = {
  joined: JoinedUser,
  inviteAccepted: InviteAccepted,
  postLike: PostLike,
  postComment: PostComment,
  followed: FollowedMe,
  listChat: ShoppingListChatMessage,
  firstTimeListView: FirstTimeListView,
  listProductLike: ListProductLike,
  inviteOnList: ShoppingListInvite,
  friendRequest: FriendRequest,
  brandHandlerInvite: BrandHandlerInvite,
  acceptFriendRequest: AcceptFriendRequest,
  productPriceChange: ProductPriceChange,
};

function Notification({ user, userData, newNotificationsCount }) {
  const [notifications, setNotifications] = useState([]);

  const addNotifications = useNotificationStore(
    (state) => state.addNotifications
  );

  useEffect(() => {
    if (!user) return;

    const notificationsRef = collection(db, `users/${user.uid}/notifications`);
    const q = query(notificationsRef, orderBy("timestamp", "desc"), limit(3));

    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const notificationData = querySnapshot.docs.map((docSnapshot) => ({
          notificationId: docSnapshot.id,
          ...docSnapshot.data(),
        }));
        setNotifications(notificationData);
        addNotifications(notificationData);
      },
      (error) => {
        console.error("Error fetching notifications in real-time: ", error);
      }
    );

    return () => unsubscribe();
  }, [user]);

  // const handleNotificationClick = async () => {
  //   if (newNotificationsCount > 0) {
  //     const userDocRef = doc(db, "users", user.uid);
  //     await updateDoc(userDocRef, {
  //       lastSeenNotification: serverTimestamp(), // Update with the server timestamp
  //     });
  //     // setLastSeenNotification(new Date()); // Update in local state as well
  //     setTimeout(() => {
  //       // setNewNotificationsCount(0); // Reset the new notifications count
  //     }, 2000);
  //   }
  // };

  const renderNotification = (notification) => {
    if (Array.isArray(notification.id) && notification.id.length > 1) {
      return (
        <GroupedNotification
          key={notification.notificationId}
          notification={notification}
          groupedNotifications={notification}
          user={user}
        />
      );
    }

    const NotificationComponent = notificationComponents[notification.type];
    if (!NotificationComponent) return null;
    return (
      <NotificationComponent
        key={notification.notificationId}
        notification={notification}
        user={user}
      />
    );
  };

  return (
    <>
      <div class="card-header p-0 d-flex justify-content-between align-items-center ps-1">
        <div className="d-flex align-items-center">
          <div className="mb-1">
            {newNotificationsCount > 0 ? (
              <BellFill className="fs-5" />
            ) : (
              <Bell className="fs-5" />
            )}
          </div>
          <h6 class="mx-1 mb-0">Notifications</h6>
          <span class="badge bg-danger bg-opacity-10 text-danger ms-2">
            {newNotificationsCount} new
          </span>
        </div>
        {notifications.length > 2 && (
          <Link className="small me-2 mb-2" to="/notifications">
            View all
          </Link>
        )}
      </div>
      <div className="card-body p-0">
        {notifications.length > 0 && (
          <div
            className="p-0 pt-1"
            style={{
              overflow: "hidden",
              whiteSpace: "collapse",
            }}
          >
            {notifications.slice(0, 5).map(renderNotification)}
          </div>
        )}
      </div>
      {/* <div class="card-footer text-center">
								<a href="#" class="btn btn-sm btn-primary-soft">See all incoming activity</a>
							</div> */}
    </>
  );
}

export default Notification;
