import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import AccountSetting from "./AccountSetting";

// import PrivacySetting from './PrivacySetting';
import StaticLinks from "../components/StaticLinks";
import { UserAuth } from "../Context";
import { db } from "../FirebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import BrandHandlerSettings from "../Brands/BrandHandlerSettings";
import { Grid1x2Fill, GridFill } from "react-bootstrap-icons";
import BrandSelection from "./BrandSelection";
import CategorySelection from "./CategorySelection";
import PrivacySetting from "./PrivacySetting";

const UserSettings = ({ tab = "account" }) => {
  const [activeTab, setActiveTab] = useState(tab);
  const { user } = UserAuth();
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  //  const isbrandHandler = userData?.isBrandHandler;
  const isbrandHandler = false;

  return (
    <div className="col-lg-8 vstack mt-0 mt-lg-5 px-0">
      <div className="row">
        <div className="col-lg-3">
          <div className="d-flex align-items-center mb-4 d-lg-none">
            <button
              className="border-0 bg-transparent"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
            >
              <span className="btn btn-primary">
                <i className="fa-solid fa-sliders-h"></i>
              </span>
              <span className="h6 mb-0 fw-bold d-lg-none ms-2">Settings</span>
            </button>
          </div>
          <nav className="navbar navbar-light navbar-expand-lg mx-0">
            <div
              className="offcanvas offcanvas-start"
              tabIndex="-1"
              id="offcanvasNavbar"
            >
              <div className="offcanvas-header">
                <button
                  type="button"
                  className="btn-close text-reset ms-auto"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body p-0">
                <div className="card w-100">
                  <div className="card-body">
                    <ul className="nav nav-tabs nav-pills nav-pills-soft flex-column fw-bold gap-2 border-0">
                      <li
                        className={`nav-item ${
                          activeTab === "account" ? "active" : ""
                        }`}
                      >
                        <Link
                          className="nav-link d-flex align-items-center mb-0"
                          to="#nav-setting-tab-1"
                          data-bs-toggle="tab"
                          onClick={() => handleTabClick("account")}
                        >
                          <img
                            className="me-2 h-20px fa-fw"
                            src="assets/images/icon/person-outline-filled.svg"
                            alt=""
                          />
                          <div>Account</div>
                        </Link>
                      </li>
                      <li
                        className={`nav-item ${
                          activeTab === "brands" ? "active" : ""
                        }`}
                      >
                        <Link
                          className="nav-link d-flex align-items-center mb-0"
                          to="#nav-setting-tab-2"
                          data-bs-toggle="tab"
                          onClick={() => handleTabClick("brands")}
                        >
                          <GridFill className="fs-5" />
                          <div className="ms-2">Personalize Brands</div>
                        </Link>
                      </li>
                      <li
                        className={`nav-item ${
                          activeTab === "category" ? "active" : ""
                        }`}
                      >
                        <Link
                          className="nav-link mb-0 d-flex align-items-center  pe-0 "
                          to="#nav-setting-tab-3"
                          data-bs-toggle="tab"
                          onClick={() => handleTabClick("category")}
                        >
                          <Grid1x2Fill className="fs-5" height={"1rem"} />
                          <div className="ms-2">Personalize Categories</div>
                        </Link>
                      </li>
                      {isbrandHandler && (
                        <li
                          className={`nav-item ${
                            activeTab === "brandHandler" ? "active" : ""
                          }`}
                        >
                          <Link
                            className="nav-link d-flex mb-0"
                            to="#nav-setting-tab-2"
                            data-bs-toggle="tab"
                            onClick={() => handleTabClick("brandHandler")}
                          >
                            <img
                              className="me-2 h-20px fa-fw"
                              src="assets/images/icon/person-outline-filled.svg"
                              alt=""
                            />
                            <span>Brand Handler</span>
                          </Link>
                        </li>
                      )}
                      <li
                        className={`nav-item ${
                          activeTab === "privacy" ? "active" : ""
                        }`}
                      >
                        <Link
                          className="nav-link mb-0 d-flex align-items-center px-auto"
                          to="#nav-setting-tab-4"
                          data-bs-toggle="tab"
                          onClick={() => handleTabClick("privacy")}
                        >
                          <img
                            className="h-20px fa-fw"
                            src="assets/images/icon/shield-outline-filled.svg"
                            alt=""
                          />
                          <div className="ms-2">Privacy Settings</div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="card-footer text-center py-2">
                    <Link
                      className="btn btn-link text-secondary btn-sm"
                      to={`/userProfile/${user?.uid}/profile/userAbout`}
                    >
                      View Profile
                    </Link>
                  </div>
                </div>
              </div>
              <StaticLinks />
            </div>
          </nav>
        </div>

        <div className="col-lg-6 vstack gap-4">
          <div className="tab-content p-0 mb-0">
            {activeTab === "account" && (
              <div className="tab-pane show active fade" id="nav-setting-tab-1">
                <AccountSetting />
              </div>
            )}

            {activeTab === "brandHandler" && (
              <div className="tab-pane show active fade" id="nav-setting-tab-2">
                <BrandHandlerSettings />
              </div>
            )}

            {activeTab === "brands" && (
              <div className="tab-pane show active fade" id="nav-setting-tab-3">
                <BrandSelection />
              </div>
            )}
            {activeTab === "category" && (
              <div className="tab-pane show active fade" id="nav-setting-tab-4">
                <CategorySelection />
              </div>
            )}

            {activeTab === "privacy" && (
              <div className="tab-pane fade show active" id="nav-setting-tab-5">
                <PrivacySetting />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSettings;
