import { db } from "../../FirebaseConfig";
import {
  doc,
  setDoc,
  updateDoc,
  getDoc,
  collection,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";

export async function saveListMessageToDb(msgData, chatId, listMembers) {
  const { senderId, content, listId, type } = msgData;
  console.log("msgData", msgData);
  const chatDocRef = doc(db, "chats", chatId);

  // First, add the message to get its ID
  const messagesSubColRef = collection(db, "chats", chatId, "messages");
  const messageDoc = await addDoc(messagesSubColRef, {
    content,
    senderId,
    listId,
    type: type,
    timestamp: serverTimestamp(),
  });

  // Use the message document ID as messageId
  const lastMessage = {
    messageId: messageDoc.id,
    content,
    timestamp: serverTimestamp(),
    senderId,
  };

  // Check if the chat document exists
  const chatDoc = await getDoc(chatDocRef);

  if (!chatDoc.exists()) {
    // Create a new chat document if it doesn't exist
    await setDoc(chatDocRef, {
      type: "list",
      listName: listId,
      members: listMembers,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      lastMessage,
    });
  } else {
    // If chat document exists, update lastMessage and updatedAt
    await updateDoc(chatDocRef, {
      members: listMembers,
      updatedAt: serverTimestamp(),
      lastMessage,
      senderId
    });
  }

  console.log(`Message saved to chat: ${chatId}`);
  return messageDoc.id;
}
