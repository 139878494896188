import React from "react";

function Step2() {
  const step2 = process.env.PUBLIC_URL + "/assets/LandingPage/step2.png";
  const step2Mobile =
    process.env.PUBLIC_URL + "/assets/LandingPage/step2Mobile.png";

  return (
    <>
      <div className="d-block d-md-none w-100">
        <img src={step2Mobile} className="mx-auto" alt="Step2 GIF" />
      </div>
      <div className="d-none d-md-block">
        <img src={step2} alt="Step2 GIF" />
      </div>
    </>
  );
}

export default Step2;
