// index.js or your entry file
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthContextProvider } from './Context';
//import SearchProvider from './Context/SearchContext';

const root = createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Router>
      <AuthContextProvider>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </AuthContextProvider>
    </Router>
  // {/* </React.StrictMode> */}
);