import React from "react";

function Step1a() {
  const step1Image =
    process.env.PUBLIC_URL + "/assets/LandingPage/step1Image.png";

  return (
    <div
      className="row m-0"
      style={{ minHeight: "75vh", alignContent: "center" }}
    >
      <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
        <h1 className="display-6">Personalized Product Recommedations</h1>
        <p className="mt-3 lead">
          Get personalized products tailored just for you.
        </p>
      </div>
      <div
        className="col-12 col-md-6 mt-4 mt-md-0 p-0"
        style={{ alignContent: "center" }}
      >
        <img src={step1Image} className="me-0" alt="Step1a GIF" />
      </div>
    </div>
  );
}

export default Step1a;
