import React from "react";
import { Link } from "react-router-dom";

export default function StaticLinks() {
  return (
    <>
      {/* Helper link START */}
      <ul className="nav small mt-2 justify-content-center">
        {/* <li className="nav-item">
          <Link className="nav-link" to="/staticPages/about">
            About
          </Link>
        </li> */}
        {/* <li className="nav-item">
          <Link className="nav-link" to="/staticPages/faqs">
            Contact
          </Link>
        </li> */}
        <li className="nav-item">
          <Link className="nav-link" to="/staticPages/termsAndConditions">
            Terms of Service
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/contactUs">
            Contact us
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/staticPages/privacyPolicy">
            Privacy Policy
          </Link>
        </li>
      </ul>
      {/* Helper link END */}
      {/* Copyright */}
      <p className="small text-center mt-1">
        ©2024{" "}
        <Link className="text-body" to="/">
          Lookflock Ltd.
        </Link>
      </p>
    </>
  );
}
