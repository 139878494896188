import React from "react";
import { Modal, Button } from "react-bootstrap";
import useModalStore from "../StatesStore/ModalStore";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
function ConfirmModal() {
  const { title, data, closeModal } = useModalStore();
  return (
    <Modal show centered onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{data?.titleContent}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <i
          style={{ fontSize: "4.5rem" }}
          className="text-body bi bi-exclamation-triangle"
        ></i>
        <div className="mt-4 fs-5 text-body">{data?.content}</div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button
          variant="danger-soft"
          className="w-25 mx-1"
          onClick={closeModal}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          className="w-25 mx-1"
          onClick={async () => {
            if (data?.type === "Unfriend") {
              await data?.yesFunction(data?.UID, data?.friendUID);
              data?.setActiveList(
                data?.activeList.filter((id) => id !== data?.friendUID)
              );
              toast.success("User unfriend successfully!", toastOptions);
            } else data?.yesFunction();
            closeModal();
          }}
        >
          {data?.buttonContent}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmModal;
