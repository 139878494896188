import create from 'zustand';

const useCartStore = create((set,get) => ({
  cartItems: [],
  addItem: (item) => set((state) => ({ cartItems: [...state.cartItems, item] })),
  removeItem: (itemId) => set((state) => ({
    cartItems: state.cartItems.filter(item => item.id !== itemId)
  })),
  updateCart: (items) => set((state) => ({ 
    cartItems: items 
  })),

  getCartCount: () => {
    const state = get();
    return state.cartItems.reduce((total, item) => total + (item.quantity || 1), 0);
  },
   // Sync with localStorage
   syncWithLocalStorage: () => {
    const localCart = localStorage.getItem('cart');
    if (localCart) {
      const parsedCart = JSON.parse(localCart);
      set({ cartItems: Object.values(parsedCart) });
    }
  },
  clearCart: () => set({ cartItems: [] })
}));

export default useCartStore;
