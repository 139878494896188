import React from "react";
import { Modal, Button } from "react-bootstrap";
import useModalStore from "../StatesStore/ModalStore";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
function ConfirmDelete() {
  const { title, data, closeModal } = useModalStore();
  return (
    <Modal show centered onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <i
          style={{ fontSize: "4.5rem" }}
          className="text-body bi bi-exclamation-triangle"
        ></i>
        <div className="mt-4 fs-5 text-body">
          Are you sure you want to delete {data?.displayName}
          {data?.type === "cart" ? " from cart?" : ""}
          {title==="shoppingListProduct"? ` from ${data?.shoppingListName}?` : ""}
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button
          variant="primary-soft"
          className="w-25 mx-1"
          onClick={closeModal}
        >
          Cancel
        </Button>
        <Button
          variant="danger-soft"
          className="w-25 mx-1"
          onClick={() => {
            if (data?.type === "cart") data?.deleteFunction(data?.id);
            else data?.deleteFunction();
            closeModal();
            if (title === "shoppingList") {
              toast.success(
                data?.displayName + " Deleted Successfully",
                toastOptions
              );
            }
          }}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmDelete;
