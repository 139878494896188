import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import LoadingSpinner from "../UserProfile/LoadingSpinner";
import { db } from "../FirebaseConfig";
import { UserAuth } from "../Context";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import { X } from "react-bootstrap-icons";
function BrandSelection() {
  const { user } = UserAuth();
  const [gender, setGender] = useState("male");
  const [loading, setLoading] = useState(true);
  const [brands, setBrands] = useState([]); //This will have all the primary categories
  const [allBrands, setAllBrands] = useState([]); // Updated to use state for allBrands  //this will contain top 7 brands
  // BRANDS
  //top brands handlers
  const handleBrandClick = async (brand) => {
    // If brand is already added, remove it; otherwise, add it
    const brandRef = doc(db, "brands", brand?.name, "followers", user?.uid);
    const userRef = doc(db, "users", user?.uid, "following", brand?.name);
    // if brand name is in brands, then remove it
    if (brands.includes(brand.name)) {
      setBrands((prevBrands) =>
        prevBrands.filter((item) => item !== brand.name)
      );
      await deleteDoc(brandRef);
      await deleteDoc(userRef);
    }
    // Otherwise add it
    else {
      setBrands((prevBrands) => [...prevBrands, brand.name]);
      await setDoc(brandRef, {
        dateCreated: serverTimestamp(),
      });
      await setDoc(userRef, {
        timestamp: serverTimestamp(),
        type: "brand",
      });
    }
  };
  //fetch brands function
  const fetchBrands = async () => {
    try {
      setLoading(true);
      const brandsCol = collection(db, "brands");
      const brandSnapshot = await getDocs(brandsCol);
      let brandsArray = [];
      brandSnapshot.docs.forEach((doc) => {
        let brand = doc.data();
        brandsArray.push({
          value: brand.displayName,
          label: brand.displayName,
          like: brand.like,
          name: brand.name,
          category: brand.category[0],
        });
      });
      // Sort brands based on 'like' values
      brandsArray.sort((a, b) => b.like - a.like);
      // console.log("brandsArray", brandsArray);
      setAllBrands(brandsArray);
      //   console.log("Filtered brands", filteredBrands);
    } catch (error) {
      console.error("Error fetching brand display names:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (gender === "") return;
    const fetchData = async () => {
      //function call
      await fetchBrands();
      //function call
      //   await fetchCategories();
    };
    fetchData();
    // eslint-disable-next-line
  }, [gender]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      toast.success("Profile updated successfully", toastOptions);
      for (const brandName of brands) {
        // Get the brand document reference
        const brandDocRef = doc(db, "brands", brandName);

        // Add the user to the 'followers' subcollection in the brand document
        const followersDocRef = doc(brandDocRef, "followers", user?.uid);
        await setDoc(followersDocRef, {
          dateCreated: serverTimestamp(),
        });

        // Get the user's document reference
        const userDocRef = doc(db, "users", user.uid);

        // Add a document in the user's subcollection with the brand name
        const userBrandDocRef = doc(userDocRef, "following", brandName);
        await setDoc(userBrandDocRef, {
          timestamp: serverTimestamp(),
          type: "brand",
        });
      }
      const remainingBrands = allBrands.filter(
        (brand) => !brands.includes(brand.name)
      );
      console.log(remainingBrands);
      for (const brandName of remainingBrands) {
        // Get the brand document reference
        const brandDocRef = doc(db, "brands", brandName?.name);

        // Add the user to the 'followers' subcollection in the brand document
        const followersDocRef = doc(brandDocRef, "followers", user?.uid);
        const followersDocSnapshot = await getDoc(followersDocRef);
        if (followersDocSnapshot.exists()) {
          await deleteDoc(followersDocRef);
        }
        // Get the user's document reference
        const userDocRef = doc(db, "users", user.uid);

        // Add a document in the user's subcollection with the brand name
        const userBrandDocRef = doc(userDocRef, "following", brandName?.name);
        const userBrandDocSnapshot = await getDoc(userBrandDocRef);
        if (userBrandDocSnapshot.exists()) {
          await deleteDoc(userBrandDocRef);
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("Error updating profile", toastOptions);
    }
  };
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (user && user.uid) {
          const userDocRef = doc(db, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const userDataFromDb = userDocSnapshot.data();
            const brandRef = collection(userDocRef, "following");
            const brandSnapshot = await getDocs(brandRef);
            let brandsArray = [];
            brandSnapshot.docs.forEach((doc) => {
              if (doc.data()?.type === "brand") brandsArray.push(doc?.id);
            });
            // console.log("brandsArray", brandsArray);
            setBrands(brandsArray);
            setGender(userDataFromDb.gender);
          }
        }
      } catch (error) {
        console.error("Error fetching user data from database", error);
      }
    };

    fetchUserData();
  }, [user]);
  useEffect(() => {
    console.log("brands", brands);
  }, [brands]);

  return (
    <div className="rounded-2">
      <style jsx>
        {`
          .thinScrollBar {
            scrollbar-width: thin;
          }
        `}
      </style>
      <div className="pb-0 rounded-2 p-3">
        <h5>Favorite Brands</h5>
      </div>

      <div className="p-3 pt-2">
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            {brands.length > 0 && (
              <div
                className="alphabet-list navbar thinScrollBar pb-2"
                style={{ overflowX: "auto" }}
              >
                <ul className="nav d-flex flex-row flex-nowrap list-unstyled gap-2 pt-1 pb-2">
                  {allBrands.map((brand, index) => {
                    if (brands?.includes(brand?.name))
                      return (
                        <li
                          key={index}
                          className="btn rounded-2 btn-sm btn-primary text-light d-flex gap-2 align-items-center ps-1 py-1"
                          onClick={() => handleBrandClick(brand)}
                        >
                          <div className="btn text-light p-0">
                            <X className="fs-5" />
                          </div>
                          <div>{brand?.label}</div>
                        </li>
                      );
                  })}
                </ul>
              </div>
            )}
            {/* Brands */}
            <ul className="list-inline d-flex flex-wrap gap-3 mb-3">
              {allBrands.map(
                (brand, index) =>
                  !brands.includes(brand.name) && (
                    <li key={index} onClick={() => handleBrandClick(brand)}>
                      <button
                        className={`btn btn-outline-light rounded-2 brand-button btn-sm `}
                      >
                        {brand.label}
                      </button>
                    </li>
                  )
              )}
            </ul>
          </>
        )}
      </div>
    </div>
  );
}

export default BrandSelection;
