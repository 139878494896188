import React, { useEffect, useState } from "react";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";
import { UserAuth } from "../Context";
import { db } from "../FirebaseConfig";
import OrderSummary2 from "./OrderSummary2";
import { CartX, DashSquare, PlusSquare, Trash } from "react-bootstrap-icons";
import CardProductCard from "./CartProductCard";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import AddToList from "../components/AddToList";
import useModalStore from "../StatesStore/ModalStore";
import useCartStore from "../StatesStore/CartStore"; // Import the cart store
import LoadingSpinner from "../UserProfile/LoadingSpinner";

const Cart = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = UserAuth();
  const [brands, setBrands] = useState([]);
  const { openModal } = useModalStore();
  const { updateCart, clearCart } = useCartStore();

  // Helper functions for localStorage
  const getLocalCart = () => {
    const cart = localStorage.getItem("cart");
    return cart ? JSON.parse(cart) : {};
  };

  const updateLocalCart = (cart) => {
    localStorage.setItem("cart", JSON.stringify(cart));
    updateCart(Object.values(cart)); // Update Zustand store
  };

  const uniqueBrands = async (products) => {
    let brand = new Set();
    products.map((product) => {
      brand.add(product?.supplier);
    });
    brand = Array.from(brand);
    const sortedBrands = brand.sort();
    const actualBrands = await Promise.all(
      sortedBrands.map(async (brand) => {
        const brandSnap = doc(db, "brands", brand);
        const brandData = await getDoc(brandSnap);
        return { brandName: brandData?.data()?.displayName, name: brand };
      })
    );
    // console.log(actualBrands)
    setBrands(actualBrands);
  };

  const groupAndSortProductsBySupplier = (products) => {
    const grouped = products.reduce((acc, product) => {
      const supplier = product.supplier || "Unknown Supplier";
      if (!acc[supplier]) acc[supplier] = [];
      acc[supplier].push(product);
      return acc;
    }, {});
    return Object.values(grouped).flat();
  };

  const fetchProductDetails = async (productId) => {
    const productRef = doc(db, "products", productId);
    const productSnap = await getDoc(productRef);
    return productSnap.exists()
      ? { id: productSnap.id, ...productSnap.data() }
      : null;
  };

  useEffect(() => {
    document.body.scrollIntoView({ behavior: "smooth" });
    document.body.scrollTo(0, 0);
    setLoading(true);

    const fetchCartItems = async () => {
      try {
        if (user?.uid) {
          // Firebase listener for authenticated users
          return onSnapshot(
            query(
              collection(db, "users", user.uid, "cart"),
              orderBy("timestamp", "desc")
            ),
            async (cartSnapshot) => {
              if (!cartSnapshot.empty) {
                const cartItems = cartSnapshot.docs.map((doc) => ({
                  id: doc.id,
                  ...doc.data(),
                }));

                const productPromises = cartItems.map(async (item) => {
                  const productData = await fetchProductDetails(item.id);
                  return productData
                    ? { ...productData, quantity: item.quantity }
                    : null;
                });

                const productsData = (
                  await Promise.all(productPromises)
                ).filter(Boolean);
                const groupedProducts = groupAndSortProductsBySupplier(
                  productsData
                );
                setProducts(groupedProducts);
                uniqueBrands(groupedProducts);
                updateCart(groupedProducts);
              } else {
                setProducts([]);
                clearCart();
              }
            }
          );
        } else {
          // Local storage handling for non-authenticated users
          const localCart = getLocalCart();
          const productPromises = Object.values(localCart).map(async (item) => {
            const productData = await fetchProductDetails(item.id);
            return productData
              ? { ...productData, quantity: item.quantity }
              : null;
          });

          const productsData = (await Promise.all(productPromises)).filter(
            Boolean
          );
          const groupedProducts = groupAndSortProductsBySupplier(productsData);
          setProducts(groupedProducts);
          uniqueBrands(groupedProducts);
          updateCart(groupedProducts);
        }
      } catch (error) {
        console.error("Error fetching cart products:", error);
        // toast.error("Error loading cart items", toastOptions);
      } finally {
        setLoading(false);
      }
    };

    const unsubscribe = fetchCartItems();
    return () => {
      if (typeof unsubscribe === "function") unsubscribe();
    };
  }, [user]);

  const updateQuantity = async (pId, type) => {
    let updatedProducts = [...products];
    const productIndex = updatedProducts.findIndex((p) => p.id === pId);

    if (productIndex === -1) return;

    if (type === "plus") {
      updatedProducts[productIndex].quantity += 1;
    } else if (type === "minus" && updatedProducts[productIndex].quantity > 1) {
      updatedProducts[productIndex].quantity -= 1;
    }

    try {
      if (user?.uid) {
        // Update Firebase
        const docRef = doc(db, "users", user.uid, "cart", pId);
        await updateDoc(docRef, {
          quantity: updatedProducts[productIndex].quantity,
        });
      } else {
        // Update localStorage
        const localCart = getLocalCart();
        localCart[pId] = {
          ...localCart[pId],
          quantity: updatedProducts[productIndex].quantity,
        };
        updateLocalCart(localCart);
      }

      setProducts(updatedProducts);
      updateCart(updatedProducts);
    } catch (error) {
      console.error("Error updating quantity:", error);
      toast.error("Error updating quantity", toastOptions);
    }
  };

  const removeProduct = async (productId) => {
    try {
      if (user?.uid) {
        // Remove from Firebase
        const docRef = doc(db, "users", user.uid, "cart", productId);
        await deleteDoc(docRef);
      } else {
        // Remove from localStorage
        const localCart = getLocalCart();
        delete localCart[productId];
        updateLocalCart(localCart);
      }

      const newProducts = products.filter(
        (product) => product.id !== productId
      );
      setProducts(newProducts);
      updateCart(newProducts);
      uniqueBrands(newProducts);
      toast.success("Product removed from cart", toastOptions);
    } catch (error) {
      console.error("Error removing product from cart:", error);
      toast.error("Error removing product from cart", toastOptions);
    }
  };

  const handleDeleteProduct = (product) => {
    const DATA = {
      id: product?.id,
      deleteFunction: removeProduct,
      displayName: product?.name,
      type: "cart",
    };
    openModal("ConfirmDelete", "cartProduct", DATA);
  };

  return (
    <>
      {/* <div className="col-md-8 col-lg-6 vstack gap-3 text-body mt-0 mt-lg-5 px-3 px-lg-0 me-2"> */}
      {/* <div className="mt-0 mt-lg-5 row g-2 ps-3 pe-4 ms-3"> */}
      <div className="row g-2 ms-1 ms-lg-0 w-100">
        {/* Main Product Section */}
        <div className="col-lg-9 card p-0 vstack">
          <div className="card-header d-flex align-items-center text-center justify-content-between border-0 pb-0 p-2 ps-sm-3">
            <div className="h4 card-title fw-semibold text-start">
              Shopping Cart
            </div>
            <div className="h6 card-title fw-semibold text-start">
              {products?.length ?? 0} item{products?.length > 0 ? "s" : ""} in
              your cart
            </div>
          </div>
          <div className="card-body p-0 table-responsive">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th className="h6 ps-2 ps-sm-3">Product</th>
                  <th className="h6 text-center">Price</th>
                  <th className="h6 text-center">Quantity</th>
                  <th className="h6 text-center">Total Price</th>
                  <th className="h6 text-center"> </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={6} className="align-middle text-center h1">
                      <LoadingSpinner />
                    </td>
                  </tr>
                ) : products?.length === 0 ? (
                  <tr>
                    <td
                      colSpan={6}
                      className="align-middle text-center h2"
                      style={{ height: "70vh" }}
                    >
                      <div className="d-flex flex-column align-items-center pb-3">
                        <CartX className="display-5" />
                      </div>
                      Your cart is empty
                    </td>
                  </tr>
                ) : (
                  brands.map((brand) => {
                    return (
                      <React.Fragment key={brand.brandName}>
                        <tr className="align-middle">
                          <td
                            colSpan={6}
                            className="h6 fw-semibold text-body ps-2 ps-sm-3"
                          >
                            {brand.brandName}
                          </td>
                        </tr>
                        {products.map((product) => {
                          // console.log("product",product)
                          return (
                            product?.supplier === brand?.name && (
                              <tr key={product.id} className="align-middle">
                                <td className="ps-2 ps-sm-3 w-50">
                                  <CardProductCard product={product} />
                                </td>
                                <td className="text-center">
                                  {product?.newPrice}
                                </td>
                                <td>
                                  <div className="hstack gap-2 justify-content-center">
                                    <PlusSquare
                                      className="fs-5 rounded-1"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        updateQuantity(product?.id, "plus")
                                      }
                                    />
                                    {product?.quantity}
                                    <DashSquare
                                      className="fs-5 rounded-1"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        updateQuantity(product?.id, "minus")
                                      }
                                    />
                                  </div>
                                </td>
                                <td className="text-center">
                                  {product?.newPrice * product?.quantity}
                                </td>
                                <td className="text-center">
                                  <Trash
                                    className="fs-5"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleDeleteProduct(product)}
                                  />
                                </td>
                              </tr>
                            )
                          );
                        })}
                      </React.Fragment>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* Order Summary Section */}
        <div className="col-lg-3 vstack p-0 ps-lg-2">
          <OrderSummary2 products={products} showButton={true} />
        </div>
      </div>
    </>
  );
};

export default Cart;
