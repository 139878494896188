import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import useModalStore from "../StatesStore/ModalStore";

const OrderSummary2 = ({ products, showButton }) => {
  const navigate = useNavigate();
  const { openModal } = useModalStore();
  const [orderedProducts, setOrderedProducts] = useState([]);
  const handleCheckOutClick = () => {
    const DATA = {
      content: "Are you sure you want to checkout?",
      titleContent: "Confirm Checkout",
      buttonContent: "Checkout",
      yesFunction: () => {
        navigate("/shipping", { state: { products } });
      },
    };
    const DATA2 = {
      content: "Your cart is empty!! Please add some products to checkout",
      titleContent: "Confirm Checkout",
      buttonContent: "Done",
      yesFunction: () => {},
    };
    if (calculateTotal() !== 0) {
      openModal("ConfirmModal", "", DATA);
    } else openModal("ConfirmModal", "", DATA2);
    // toast.warn("Your cart is empty", toastOptions);
  };
  const calculateTotal = () => {
    return products.reduce(
      (total, product) => total + (product.newPrice * product.quantity || 0),
      0
    );
  };
  const groupAndSortProductsBySupplier = (products) => {
    const groupedProducts = products.reduce((acc, product) => {
      const supplier = product.supplier;
      if (!acc[supplier]) {
        acc[supplier] = [];
      }
      acc[supplier].push(product);
      return acc;
    }, {});

    const sortedSuppliers = Object.keys(groupedProducts).sort();

    return sortedSuppliers.flatMap((supplier) => groupedProducts[supplier]);
  };
  useEffect(() => {
    const groupedProducts = groupAndSortProductsBySupplier(products);
    setOrderedProducts(groupedProducts);
  }, [products]);

  return (
    <div className="card">
      <div className="card-header px-3">
        <div className="h5 mb-0 card-title">Cart Total</div>
      </div>
      <div className="card-body px-0 py-3">
        <ul className="list-unstyled px-3">
          <div className="mb-2 h6 fw-semibold">
            Items selected ({orderedProducts.length ?? 0})
          </div>
          {orderedProducts.map((product) => (
            <li
              key={product?.id}
              className="d-flex justify-content-between row my-2"
            >
              <div className="col-8">{product?.name}</div>
              <div className="col-4">
                Rs. {product?.newPrice * product?.quantity}
              </div>
            </li>
          ))}
        </ul>
        <hr />
        <div className="d-flex justify-content-between fw-bold px-3">
          <span>Total</span>
          <span>Rs. {calculateTotal()}</span>
        </div>
        {showButton && (
          <>
            <hr className="m-0 mb-3" />
            <div className="d-grid py-0 px-3">
              <button
                className="btn btn-primary-soft btn-sm rounded-2"
                onClick={handleCheckOutClick}
              >
                Proceed to Checkout
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default OrderSummary2;
