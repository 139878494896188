import { format } from 'date-fns';

const formatTimestamp = (timestamp) => {
  if (!timestamp || !timestamp.toDate) {
    return "N/A";
  }

  const postDate = timestamp.toDate();
  const now = new Date();
  const timeDifferenceInSeconds = (now - postDate) / 1000;

  if (timeDifferenceInSeconds < 60) {
    return "now";
  }

  if (timeDifferenceInSeconds < 3600) {
    // Less than an hour ago
    const minutes = Math.floor(timeDifferenceInSeconds / 60);
    return `${minutes}m`;
  }

  if (timeDifferenceInSeconds < 86400) {
    // Less than a day ago
    const hours = Math.floor(timeDifferenceInSeconds / 3600);
    return `${hours}h`;
  }

  if (timeDifferenceInSeconds < 604800) {
    // Less than a week ago
    const days = Math.floor(timeDifferenceInSeconds / 86400);
    return `${days}d`;
  }

  if (timeDifferenceInSeconds < 2592000) {
    // Less than a month ago, but more than a week
    const weeks = Math.floor(timeDifferenceInSeconds / 604800);
    return `${weeks}w`;
  }

  if (timeDifferenceInSeconds < 31536000) {
    // Less than a year ago
    const months = Math.floor(timeDifferenceInSeconds / 2592000);
    return `${months}M`;
  }

  // More than a year ago
  return format(postDate, 'MMMM dd, yyyy');
};

export default formatTimestamp;
