import React, { useEffect, useState } from "react";
import UserProfile from "./UserProfile";
import { UserAuth } from "../Context";
import StaticLinks from "../components/StaticLinks";
import SearchPageSideNav from "./SearchPageSideNav";

function SideNavSearch() {
  //   const { user } = UserAuth(); // Use UserAuth instead of LoginContext
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 900);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 900);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div
    //  className="col-lg-3 d-none d-lg-block"
    >
      {/* Advanced filter responsive toggler END */}

      {/* Navbar START*/}
      <nav className="navbar mx-0">
        <div
          className="offcanvas offcanvas-end"
          tabIndex="-1"
          id="offcanvasSideSearchbar"
          style={{ width: isSmallScreen ? "15rem" : "30rem" }}
        >
          {/* Offcanvas header */}
          <div className="offcanvas-header">
            <button
              type="button"
              className="btn-close text-reset me-auto"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <SearchPageSideNav />

          {/* <StaticLinks />  Footer*/}
          {/* Offcanvas body */}
          {/* <div className="offcanvas-body d-block px-2 px-lg-0">
            <BrandsMenu />
          </div> */}
        </div>
      </nav>

      {/* Navbar END*/}
    </div>
  );
}

export default SideNavSearch;
