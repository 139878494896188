import React from "react";

function Step3() {
  const step3 = process.env.PUBLIC_URL + "/assets/LandingPage/step3.png";
  const step3Mobile =
    process.env.PUBLIC_URL + "/assets/LandingPage/step3Mobile.png";

  return (
    <>
      <div className="d-block d-md-none w-100">
        <img src={step3Mobile} className="mx-auto" alt="Step3 GIF" />
      </div>
      <div className="d-none d-md-block">
        <img src={step3} alt="Step3 GIF" />
      </div>
    </>
  );
}

export default Step3;
