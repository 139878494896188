import React, { useEffect, useState } from "react";
import { Adsense } from "@ctrl/react-adsense";

const GoogleAdCardMobile = () => {

  return (
    <Adsense
      style={{
        // width: "355px",
        // height: "265px",
        display: "block",
        marginLeft:'9px',
        marginBottom:'12px',
      }}
      client="ca-pub-6917679702799024"
      slot="2618702749"
      format="auto"
    />
  );
};

export default GoogleAdCardMobile;
