import React, { useEffect, useRef, useState } from "react";
import ProfilePicture from "../UserProfile/ProfilePicture";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import ProductCard5 from "../Products/ProductCard5";
import { decryptChat, encryptChat } from "../ShoppingList/Encryption";
import useChatClient from "../StatesStore/ChatClient";
import { generateChatId } from "./lib.js/generateChatId";

function ChatToast({ isOpen, onClose, userData, user, uid }) {
  const [msg, setMsg] = useState("");
  const [msgs, setMsgs] = useState([]);
  const [isRecipientOnline, setIsRecipientOnline] = useState(false);
  const [channel, setChannel] = useState(null);
  const client = useChatClient((state) => state.client);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      const chatContainer = messagesEndRef.current.parentElement;
      if (chatContainer) {
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }
    }
  };

  useEffect(() => {
    if (isOpen) scrollToBottom();
  }, [msgs, isOpen]);

  const fetchDetails = async (type, id) => {
    try {
      const collectionName = type === "product" ? "products" : "posts";
      const docRef = doc(db, collectionName, id);
      const docSnapshot = await getDoc(docRef);
      return docSnapshot.exists() ? docSnapshot.data() : null;
    } catch (error) {
      console.error(`Error fetching ${type} details:`, error);
      return null;
    }
  };

  useEffect(() => {
    if (!client || !user || !uid) return;

    const setupChannel = async () => {
      try {
        // Create a unique channel ID for the conversation
        const channelId =  generateChatId(user.uid, uid)
        
        // Initialize the channel
        const channel = client.channel('messaging', channelId, {
          members: [user.uid, uid],
        });

        await channel.watch();

        // Listen for new messages
        channel.on('message.new', (event) => {
          setMsgs((prevMsgs) => [...prevMsgs, {
            content: event.message.text,
            senderId: event.user.id,
            type: 'text',
            timestamp: new Date(event.created_at)
          }]);
        });

        // Listen for presence changes
        client.on('user.presence.changed', (event) => {
          if (event.user.id !== user.uid) {
            setIsRecipientOnline(event.user.online);
          }
        });
        const response1 = await channel.query({ 
          messages: { limit: 100 },
          presence: true,
          state: true,
        });
    
        // Get initial online status of members
        const members = response1.members;
        console.log('members',members);
        
        members.forEach(member => {
          if (member.user.id !== user.uid) {
            setIsRecipientOnline(member.user.online);
          }
        });
        // Load previous messages
        const response = await channel.query({ messages: { limit: 50 } });
        const formattedMessages = response.messages.map(message => ({
          content: message.text,
          senderId: message.user.id,
          type: 'text',
          timestamp: new Date(message.created_at)
        }));

        setMsgs(formattedMessages);
        setChannel(channel);
      } catch (error) {
        console.error("Error setting up Stream channel:", error);
      }
    };

    setupChannel();

    return () => {
      if (channel) {
        channel.stopWatching();
      }
    };
  }, [client, user, uid]);

  const sendMsg = async (e) => {
    e.preventDefault();
    scrollToBottom();

    if (msg?.trim() === "" || !channel) return;

    const encryptedMsg = encryptChat(msg);

    try {
      await channel.sendMessage({
        text: msg,
      });

      setMsg("");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <>
      <div
        aria-live="polite"
        aria-atomic="true"
        className="position-relative"
        data-bs-scroll="true"
      >
        <div className="toast-container toast-chat d-flex gap-3 align-items-end custom-scrollbar">
          <div
            id="chatToast"
            className={`toast mb-0 bg-mode ${isOpen ? "show" : ""}`}
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            data-bs-autohide="false"
          >
            <div className="toast-header bg-mode">
              <div className="d-flex justify-content-between align-items-center w-100">
                <div className="d-flex">
                  <div className="flex-shrink-0 avatar me-2 ">
                    <ProfilePicture
                      userData={userData}
                      className="avatar-img rounded"
                    />
                  </div>
                  <div className="flex-grow-1">
                    <h6 className="mb-0 mt-1">{userData?.userName}</h6>
                    <div className="small text-secondary">
                      <i
                        className={`fa-solid fa-circle ${
                          isRecipientOnline ? "text-success" : "text-muted"
                        } me-1`}
                      ></i>
                      {isRecipientOnline ? "Online" : "Offline"}
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <a
                    className="btn btn-secondary-soft-hover py-1 px-2"
                    data-bs-toggle="collapse"
                    href="#collapseChat"
                    aria-expanded="false"
                    aria-controls="collapseChat"
                  >
                    <i className="bi bi-dash-lg"></i>
                  </a>
                  <button
                    className="btn btn-secondary-soft-hover py-1 px-2"
                    onClick={onClose}
                    aria-label="Close"
                  >
                    <i className="fa-solid fa-xmark"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="toast-body collapse show" id="collapseChat">
              <div className="chat-conversation-content custom-scrollbar h-300px overflow-y-auto overflow-x-hidden">
                {msgs?.map((message, index) => {
                  return (
                    <div key={index}>
                      {message.type === "product" && message.details && (
                        <div
                          className={`d-flex justify-content-${
                            message?.senderId === user?.uid ? "end" : "start"
                          }`}
                        >
                          <div className="col-6">
                            <ProductCard5
                              product={message?.details}
                              isStrip={false}
                            />
                          </div>
                        </div>
                      )}
                      {!message.productId && (
                        <div className="d-flex justify-content-end text-end mb-1">
                          <div className="w-100">
                            <div
                              className={`d-flex flex-row justify-content-${
                                message?.senderId === user?.uid ? "end" : "start"
                              }`}
                            >
                              {message.senderId !== user?.uid && (
                                <div className="flex-shrink-0 avatar avatar-xs me-2">
                                  <ProfilePicture
                                    userData={userData}
                                    className="avatar-img rounded"
                                  />
                                </div>
                              )}
                              <div
                                className={`${
                                  message.senderId === user?.uid
                                    ? "bg-primary text-white"
                                    : "bg-light text-secondary"
                                } p-2 px-3 rounded-2`}
                                style={{ maxWidth: "50%" }}
                              >
                                {message?.content}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
                <div ref={messagesEndRef} />
              </div>
              <div className="mt-2">
                <textarea
                  className="form-control mb-sm-0 mb-3"
                  placeholder="Type a message"
                  rows="1"
                  value={msg}
                  onChange={(e) => setMsg(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      sendMsg(e);
                    }
                  }}
                ></textarea>
                <div className="d-sm-flex align-items-end mt-2">
                  <button
                    className="btn btn-sm btn-primary ms-auto"
                    onClick={sendMsg}
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChatToast;