import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { UserAuth } from "../Context";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import referralLink from "../lib/referralLink";
import { Typography, message } from "antd";
import useModalStore from "../StatesStore/ModalStore";

const ShareMenu = () => {
  const { user } = UserAuth();
  const [referralCode, setReferralCode] = useState("");
  const [loading, setLoading] = useState(true);
  const { openModal } = useModalStore();
  const title = "Invite for Social Shopping Experience Now! 😍";
  const textRef = useRef(null); // Create a ref for the Text component

  useEffect(() => {
    const fetchReferralCode = async () => {
      try {
        const userDocRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists()) {
          const userData = docSnap.data();
          if (userData && userData.referralCode) {
            setReferralCode(userData.referralCode);
            setLoading(false);
          }
        }
      } catch (error) {
        console.error("Error fetching referral code:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchReferralCode();
  }, [user]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const referralLinkURL = referralLink(referralCode);

  return (
    <div className="position-relative">
      <div
        className="bg-primary-soft-hover d-flex "
        id="eventActionShare"
        // data-bs-toggle="dropdown"
        aria-expanded="false"
        onClick={() => openModal("Invite", title, referralLinkURL)}
      >
        <i class="bi bi-person-plus me-2"></i>
        <div>Invite</div>
      </div>
    </div>
  );
};

export default ShareMenu;
