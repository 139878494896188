import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddToList from "../components/AddToList";
import { Card } from "react-bootstrap";
import "./style.css";
import { UserAuth } from "../Context";
import BrandDisplay from "../NewsFeed/BrandDisplay";
import formatPrice from "../lib/formatPrice";
import { getBrandDetails } from "../lib/getBrandDetails";
import useFetch from "../hooks/useFetch";
import usePost from "../hooks/usePost";
import { db } from "../FirebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import AddToChat from "../components/AddToChat";
import AddToCart from "../components/AddToCart";
import AddOrRemoveLike from "./AddOrRemoveLike";
import Slider from "react-slick";

const PrevArrow = ({ style, onClick }) => {
  return (
    <div
      className="slick-prev "
      style={{
        ...style,
        fontSize: "24px",
        color: "grey",
        zIndex: 1,
        left: "2%",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: "20px",
        height: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // background: "rgba(var(--bs-primary-rgb), 0.3)",
        background: "rgba(0, 0, 0, 0.6)",
        borderRadius: "0.4rem",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <span
        className="carousel-control-prev-icon"
        style={{ filter: "brightness(1.4)", width: "15px", height: "15px" }}
      ></span>
    </div>
  );
};

const NextArrow = ({ style, onClick }) => {
  return (
    <div
      style={{
        ...style,
        background: "rgba(0, 0, 0, 0.6)",
        fontSize: "24px",
        color: "grey",
        zIndex: 1,
        right: "2%",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: "20px",
        height: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // background: "rgba(var(--bs-primary-rgb), 0)",
        borderRadius: "0.4rem",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <span
        className="carousel-control-next-icon"
        style={{ filter: "brightness(1.4)", width: "15px", height: "15px" }}
      ></span>
    </div>
  );
};
const ProductCardBrandsPage = ({ product, isStrip, addButtonData }) => {
  const [likeCount, setLikeCount] = useState(product?.likes);
  const [brandData, setBrandData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isInList, setIsInList] = useState(false);
  const [loginCount, setLoginCount] = useState(0);
  const [showCard, setShowCard] = useState(true);
  const settings = {
    speed: 500,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    swipe: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  // const activeBrands = useActiveBrandsStore((state) => state.activeBrands);
  const { user } = UserAuth();
  const { data: activeBrands } = useFetch(
    `${process.env.REACT_APP_API}/api/activeBrands`
  );
  let url = process.env.REACT_APP_API + `/api/activity/${user?.uid}`;

  // let url = process.env.REACT_APP_API+`/api/logs/${user?.uid}`;
  const { data: postData, post } = usePost(url);

  const fetchBrandData = async () => {
    try {
      // Fetch the brand's display name and logo using the getDisplayName function
      const result = await getBrandDetails(product?.supplier, activeBrands);
      setBrandData(result);
      // console.log("result", result);
    } catch (error) {
      console.error("Error getting brand data:", error);
    }
  };

  const checkProductInList = async () => {
    const userRef = doc(
      db,
      "users",
      addButtonData.userId,
      "shoppingList",
      addButtonData.selectedShopList,
      "products",
      product?.id
    );
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      setIsInList(true);
    }
  };

  useEffect(() => {
    if (!product || !activeBrands) return;
    fetchBrandData();
  }, [product, activeBrands]);

  useEffect(() => {
    if (!product || !addButtonData) return;
    checkProductInList();
  }, [product, addButtonData]);

  const handleImageLoad = () => {
    setLoading(false);
  };

  const fetchLoginCount = async () => {
    const userRef = doc(db, "users", user?.uid);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      setLoginCount(userSnap?.data().loginCount || 0);
    }
  };

  useEffect(() => {
    if (!user) return;
    fetchLoginCount();
  }, [user]);

  if (!showCard) return;
  // if (product?.name === "Cambric Shalwar") console.log("product", product);
  return (
    <div className="mt-0 mb-1 mb-sm-1">
      <Card>
        <div style={{ position: "relative", height: "auto" }}>
          {loading && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
                backgroundColor: "#eef0f2", // background for the placeholder
              }}
            ></div>
          )}
          <Link to={`/ProductDetails/${product?.id}`}>
            <Slider {...settings} className="m-0">
              {[product?.imageUrl, ...(product?.secondaryImages || [])]?.map(
                (image, index) => (
                  <Card.Img
                    key={index}
                    src={image}
                    style={{
                      opacity: loading ? 0 : 1, // Hide the image until it's fully loaded
                      transition: "opacity 0.5s ease-in-out",
                      objectFit: "cover",
                      display: "inline",
                    }}
                    onLoad={handleImageLoad}
                    onError={() => setShowCard(false)}
                    loading="lazy"
                  />
                )
              )}
            </Slider>
          </Link>
        </div>

        {/* Price Tag */}
        <div
          className="position-absolute rounded-pill top-0 start-0 m-2"
          style={{
            fontSize: "12px",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          }}
        >
          {product?.newPrice && (
            <p className="m-0 text-white px-2 py-1">
              PKR{" "}
              {product?.newPrice
                ? formatPrice(product.newPrice)
                : formatPrice(product.oldPrice)}
            </p>
          )}
        </div>
        <div
          className="d-inline-flex ps-2 pe-2 align-items-center justify-content-center rounded-pill text-light position-absolute end-0 m-2"
          style={{
            fontSize: "1.3rem",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          }}
        >
          {/* <Link className="text-center text-body"> */}
          <Link className="text-center text-light">
            <AddToCart user={user} product={product} />
          </Link>
          {/* </Link> */}
        </div>
        {/* Plus Button to add product in the list */}
        {/* Strip */}
        {(isStrip === undefined || isStrip === true) && (
          <>
            <div
              className="d-inline-flex ps-1 pe-2 align-items-center justify-content-center rounded-pill text-light position-absolute bottom-0 end-0"
              style={{
                fontSize: "12px",
                marginBottom: "2.4rem",
                marginRight: "0.5rem",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
              }}
            >
              <span>
                <AddToList product={product} componentName={"strip2"} />
              </span>
            </div>
            <div
              className="d-inline-flex px-2 align-items-center justify-content-center rounded-pill text-light position-absolute bottom-0 end-0 m-2"
              style={{
                fontSize: "12px",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
              }}
            >
              <span>
                <AddToChat product={product} />
              </span>
            </div>
            <div
              className="align-items-center justify-content-center rounded-pill position-absolute bottom-0 start-0 m-2"
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.6)",
              }}
            >
              <Link className="text-center text-light d-flex align-items-center gap-1 px-2">
                <AddOrRemoveLike
                  user={user}
                  product={product}
                  setLikeCount={setLikeCount}
                  componentName={"strip2"}
                />
                <div style={{ fontSize: "10px" }}>({likeCount})</div>
              </Link>
            </div>
          </>
        )}
      </Card>
    </div>
  );
};

export default ProductCardBrandsPage;
