import React from "react";
import UserProfile from "./UserProfile";
import { UserAuth } from "../Context";
import StaticLinks from "../components/StaticLinks";
import SignIn from "./SignIn";
import { List } from "react-bootstrap-icons";

function SideNavUserProfile() {
  const { user } = UserAuth(); // Use UserAuth instead of LoginContext
  return (
    <>
      <div className="me-lg-1 col-lg-20">
        {/* <div className="col-lg-3 pe-2 ms-0 ms-lg-3 mt-0 mt-lg-5 "> */}
        {/* Burger Menu */}
        <button
          className="border-0 rounded-2 icon-md btn btn-light p-0 ms-2 mt-2 d-lg-none position-absolute z-3"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasSideNavigationbar"
          aria-controls="offcanvasSideNavigationbar"
        >
          <List className="fs-4" />
        </button>
        {/* Advanced filter responsive toggler END */}

        {/* Navbar START*/}
        <nav className="navbar navbar-expand-lg mx-0">
          <div
            className="offcanvas offcanvas-start"
            tabIndex="-1"
            id="offcanvasSideNavigationbar"
          >
            {/* Offcanvas header */}
            {/* <div className="offcanvas-header"> */}
            {/* <button
              type="button"
              className="btn btn-lg position-absolute z-1 end-0 p-0 m-2"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <XSquareFill className="text-secondary" />
            </button> */}
            {/* </div> */}
            <div class="offcanvas-header">
              <button
                type="button"
                class="btn-close text-reset ms-auto"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            {user && <UserProfile />}
            {!user && <SignIn />}
            {/* <StaticLinks /> */}
            {/* Offcanvas body */}
          </div>
        </nav>

        {/* Navbar END*/}
      </div>
    </>
  );
}

export default SideNavUserProfile;
