import React from "react";
import OrderSummary from "../Cart/OrderSummary ";
import ShippingForm from "./ShippingForm ";
import { useLocation } from "react-router-dom";
import OrderSummary2 from "../Cart/OrderSummary2";

const Shipping = () => {
  const location = useLocation();
  const products = location.state?.products || [];
  // console.log("products", products);

  return (
    <div className="row g-2 w-100 px-2 ps-lg-3 pe-lg-0 ms-0 ms-lg-2">
      {/* Shipping Form Section */}
      <div className="col-md-9 card">
        <div className="card-header">
          <h1 className="h4 card-title">Shipping Information</h1>
        </div>
        <div className="card-body">
          <ShippingForm products={products} />
        </div>
      </div>

      {/* Order Summary Section */}
      <div className="col-md-3">
        <OrderSummary2 products={products} />
      </div>
    </div>
  );
};

export default Shipping;
