import React, { useEffect, useState } from "react";
import UserMenuHeader from "../Authentication/UserMenuHeader";
import SignInMenuHeader from "../Authentication/SignInMenuHeader";
import { UserAuth } from "../Context";
import HeaderMenu from "./HeaderMenu";
import { Link, useLocation } from "react-router-dom";
import "./style.css";
import useSearchInputStore from "../StatesStore/SearchInputStore";
import UserMenuHeader3 from "../Authentication/UserMenuHeader3";

function Header() {
  const { user } = UserAuth();
  const location = useLocation();
  // Input state is used to store user input
  // const [input, setInput] = useState("");
  const {
    searchInput,
    setSearchInput,
    resetSearchInput,
  } = useSearchInputStore();
  // Handle outside click
  const handleOutsideClick = () => {
    const main = document.getElementById("main");
    // const navbarCollapsible = document.getElementById("navbarCollapse");
    const brand = document.getElementById("brandsCollapse");
    // const category = document.getElementById("categoryCollapse");
    const menCategory = document.getElementById("menCollapse");
    const womenCategory = document.getElementById("womenCollapse");
    resetSearchInput();
    function fun() {
      if (main.classList.contains("show")) {
        main.setAttribute("data-bs-toggle", "collapse");
        main.setAttribute("data-bs-target", "#navbarCollapse");
      } else {
        main.removeAttribute("data-bs-target", "#navbarCollapse");
        main.removeAttribute("data-bs-toggle", "collapse");
      }
      if (user && brand.classList.contains("show")) {
        brand.classList.remove("show");
      }
      // if (category.classList.contains("show")) {
      //   category.classList.remove("show");
      // }
      if (user && menCategory.classList.contains("show")) {
        menCategory.classList.remove("show");
      }
      if (user && womenCategory.classList.contains("show")) {
        womenCategory.classList.remove("show");
      }
    }
    setTimeout(fun, 400);
  };

  useEffect(() => {
    const main = document.getElementById("main");
    main.addEventListener("click", handleOutsideClick);
    // const brand = document.getElementById("brandsCollapse");
    // const category = document.getElementById("categoryCollapse");
    // brand.addEventListener("click", handleOutsideClick);
    // category.addEventListener("click", handleOutsideClick);
    return () => {
      main.removeEventListener("click", handleOutsideClick);
      // brand.removeEventListener("click", handleOutsideClick);
      // category.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  // Handle search
  // const handleSearch = (e) => {
  //   e.preventDefault();
  //   if (searchInput.length > 2) {
  //     window.location.href = `#/search/${searchInput}`;
  //   }
  // };
  const handleLogoClick = () => {
    setSearchInput(""); // Reset searchInput state to empty
  };
  return (
    <nav className="navbar navbar-expand-lg bg-mode border border-0 border-bottom">
      <div className="container my-2 my-lg-0 my-sm-1 my-xs-0 px-2">
        {/* Logo START */}
        <Link
          className="text-body text-center fs-icon"
          to="/"
          onClick={handleLogoClick}
        >
          <div style={{ fontFamily: "Nunito Sans" }}>
            <i>lookflock &nbsp;</i>
          </div>
        </Link>
        {/* Logo END */}

        {/* Responsive navbar toggler */}
        {/* <button
          className="navbar-toggler ms-auto icon-md btn btn-light p-0"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={handleOutsideClick}
        >
          <i className="bi bi-search fs-5"></i>
        </button>
        <div className="collapse navbar-collapse z-3" id="navbarCollapse">
          <div className="nav my-2 flex-nowrap align-items-center px-2">
            <div className="nav-item w-100">
              <form
                className="rounded position-relative"
                onSubmit={handleSearch}
              >
                <input
                  className="form-control ps-5 bg-light"
                  type="search"
                  name="input"
                  placeholder="Search..."
                  aria-label="Search"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <button
                  className="btn bg-transparent px-2 py-0 position-absolute top-50 start-0 translate-middle-y"
                  type="submit"
                >
                  <i className="bi bi-search fs-5"></i>
                </button>
              </form>
            </div>
          </div>
        </div> */}

        {/* Nav right START */}
        <ul className="nav d-flex align-items-center gap-3">
          {user ? <UserMenuHeader3 /> : <SignInMenuHeader />}
        </ul>
      </div>
    </nav>
  );
}

export default Header;
