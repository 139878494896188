import React, { useEffect, useState } from "react";
import { collection, getDocs, onSnapshot } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { UserAuth } from "../Context";
import EmptyBox from "../components/EmptyBox";
import ProductCard3 from "../Products/ProductCard3";
import UserCard3 from "../Cards/UserCard3";
import LoadingSpinner from "./LoadingSpinner";
import { XBlock, XMasonry } from "react-xmasonry";
export default function SavedPosts() {
  const [savedPosts, setSavedPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = UserAuth();

  useEffect(() => {
    const fetchSavedPosts = async () => {
      if (!user) return;
      setLoading(true);
      try {
        const savedPostRef = collection(db, "users", user?.uid, "saved");
        const unsubscribe = onSnapshot(savedPostRef, async () => {
          const savedPostSnap = await getDocs(savedPostRef);
          setSavedPosts(savedPostSnap.docs.map((post) => post.data()));
        });
      } catch (error) {
        console.error("Error fetching saved posts:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSavedPosts();
  }, [user]);
  console.log(savedPosts);
  function getBlockWidth() {
    // Return 1 for desktop (width >= 900), else 2
    return window.innerWidth >= 900 ? 1 : 2;
  }

  function getTargetBlockWidth() {
    return window.innerWidth < 440 ? 200 : 250;
  }
  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="px-4">
          <XMasonry
            center={true}
            responsive={true}
            maxColumns={5}
            targetBlockWidth={getTargetBlockWidth()}
          >
            {savedPosts?.map((post, index) => (
              <XBlock width={getBlockWidth()} key={post.id}>
                {/* <UserCard post={post} /> */}
                <div className={`me-0 ${index === 0 ? "ms-0" : "ms-2"}`}>
                  <UserCard3 post={post} />
                </div>
              </XBlock>
            ))}
          </XMasonry>
        </div>
      )}
    </>
  );
}
