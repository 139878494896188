import React from "react";
import { Modal, Button } from "react-bootstrap";
import useModalStore from "../StatesStore/ModalStore";
import handleUnfollowBrand from "../hooks/brand/unfollowBrand";
import handleUnfollowUser from "../hooks/user/unfollowUser";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import useNotification from "../hooks/useNotification";
import useFollowUser from "../hooks/useFollowUser";

function ConfirmUnfollow() {
  const { title, data, closeModal } = useModalStore();
  const { deleteNotification } = useNotification();
  const { unfollowUser } = useFollowUser(data.UID, data.friendUID);

  const BrandUnfollow = async () => {
    toast.success("Brand unfollowed successfully", toastOptions);
    closeModal();
    data.FollowButton(false);
    data.SetFollowerCount((prev) => prev - 1);
    await handleUnfollowBrand(data.UID, data.brandName);
  };

  const UserUnfollow = async () => {
    await unfollowUser();
    console.log(
      "user unfollow data.UID, data.friendUID",
      data.UID,
      data.friendUID
    );
    data.FollowButton(false);
    // toast.success("User unfollowed successfully", toastOptions);
    closeModal();
    await deleteNotification({
      userId: data.friendUID,
      id: data.UID,
      type: "followed",
      by: "user",
    });
  };

  return (
    <Modal show centered onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Unfollow</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <i
          style={{ fontSize: "4.5rem" }}
          className="text-body bi bi-exclamation-triangle"
        ></i>
        <div className="mt-4 fs-5 text-body">
          Are you sure you want to unfollow {data.displayName}?
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button
          variant="danger-soft"
          className="w-25 mx-1"
          onClick={closeModal}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          className="w-25 mx-1"
          onClick={() => (title === "brand" ? BrandUnfollow() : UserUnfollow())}
        >
          Unfollow
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmUnfollow;
