import React from "react";
import { Card } from "react-bootstrap";
import BrandDisplay from "../../NewsFeed/BrandDisplay";
import formatPrice from "../../lib/formatPrice";
import { Link } from "react-router-dom";
import ColorSelector from "../../Experiments/colorDisplay";

const ProductCardExpanded = ({ product, brandData, handleClick }) => {
  return (
    <div className="m-1 m-sm-1 expanded-card shadow-sm rounded">
      <Card onClick={handleClick} className="position-relative border-0">
        <div className="d-flex flex-row">
          <div
            style={{
              position: "relative",
              height: "220px",
              width: "140px",
              overflow: "hidden",
            }}
          >
            <Card.Img
              src={product.imageUrl}
              style={{
                height: "100%",
                objectFit: "cover",
                cursor: "pointer",
                borderTopLeftRadius: "0.475rem",
                borderBottomLeftRadius: "0.475rem",
                borderRadius: '0.5rem',
                padding: '4px',

              }}
              loading="lazy"
            />
          </div>

          <div
            className="p-1 "
            style={{
              flex: 1,
              marginLeft: "10px",
              maxHeight: "220px",
              overflowY: "auto",
              overflowX: "hidden",
              fontSize: "14px",
              lineHeight: "1.4",
              color: "#555",
              scrollbarWidth: "thin",
              scrollbarColor: "#888 #f1f1f1",
            }}
          >

            <div
              style={{
                paddingRight: "10px"
              }}
            >
              <div className="price d-flex align-items-center pt-0">
                <div className="text-danger h6 fw-light">
                  PKR {formatPrice(product?.newPrice)}
                </div>
                {product?.oldPrice > 0 && (
                  <strike className="text-body h6 fw-light mx-2 small">
                    PKR {formatPrice(product?.oldPrice)}
                  </strike>
                )}
                {product?.discount > 0 && (
                  <span className="mx-1 h6 fw-light badge rounded-pill bg-warning p-1">
                    {product?.discount}% Discount
                  </span>
                )}
              </div>
              <hr className="text-body mt-0" />
              <div
                dangerouslySetInnerHTML={{ __html: product?.description }}
                style={{
                  wordWrap: "break-word",
                }}
              />
            </div>

            {/* Color and Size Section */}
            <div className="mt-0">
              {product?.colors.length > 0 && (
                <div className="colorDiv h6 fw-light col-md-6">
                  <div>Available Colors</div>
                  <div className="d-flex flex-wrap my-1">
                    {product?.colors?.length > 0 && (
                      <ColorSelector colors={product?.colors} width="20px" height="20px" />
                    )}
                  </div>
                </div>
              )}

              {product?.sizes && product.sizes.length > 0 && (
                <div>
                  <small className="sizeDiv h6 fw-light d-flex flex-column col-md-6">Available Sizes:</small>
                  <div className="d-flex">
                    {product.sizes.map((size, index) => (
                      <span
                        key={index}
                        className="badge bg-light text-dark me-2"
                        style={{
                          border: '1px solid #dee2e6',
                          padding: '4px 8px',
                          fontSize: '12px'
                        }}
                      >
                        {size}
                      </span>
                    ))}
                  </div>
                </div>
              )}

            </div>
            <Link className="small me-2 mb-2" to={`/ProductDetails/${product?.id}`}>
              View all details
            </Link>
          </div>
        </div>

      </Card>
      <div className="ps-1 pb-1">
        <div className="d-flex align-items-center">
          {/* Logo */}
          <div
            className="avatar me-2"
            style={{ width: "35px", height: "35px" }}
          >
            <Link to={"/BrandHome/" + product?.supplier}>
              <img
                className="avatar-img rounded"
                src={brandData?.logo}
                alt=""
              />
            </Link>
          </div>
          <div
            className="d-flex flex-column justify-content-start"
            style={{ width: "78%" }}
          >
            <div className="mb-0">
              <BrandDisplay brandData={brandData} type={"ProductCard"} />
            </div>
            <div
              className="fw-bold h6 position-relative"
              style={{
                marginTop: "2px",
                fontSize: "12px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {product?.name}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCardExpanded;