import React from "react";
import { Link } from "react-router-dom";

function CartProductCard({ product }) {
  const productImage = () => {
    return (
      <div className="prodImage w-50">
        <Link to={`/ProductDetails/${product?.id}`}>
          <img
            className="rounded-2"
            loading="lazy"
            //   style={{ width: "7rem" }}
            src={product?.imageUrl}
            alt={product?.name}
          />
        </Link>
      </div>
    );
  };
  const productData = () => {
    return (
      <div className="prodData w-100 d-flex flex-column gap-2 justify-content-center flex-wrap">
        <div className="d-none d-md-block" style={{ fontSize: "0.8rem" }}>
          {product?.category}
          {" > "}
          {product?.subCategory}
          {" > "}
          {product?.subSubCategory}
        </div>
        <Link
          to={`/ProductDetails/${product?.id}`}
          className="h6 fw-semibold mb-0 position-relative"
          style={
            {
              // display: "inline-block", // Ensures proper handling of text inside
              // maxWidth: "100%", // Restrict the container to its parent's width
              // whiteSpace: "nowrap", // Prevents wrapping
              // overflow: "hidden", // Hides overflow text
              // textOverflow: "ellipsis", // Adds ellipsis
            }
          }
        >
          {product?.name}
        </Link>
      </div>
    );
  };
  // console.log("product", product);
  return (
    <>
      {/* Mobile */}
      <div className="d-flex d-sm-none flex-column gap-1 align-items-start">
        {productImage()}
        {productData()}
      </div>
      {/* Desktop */}
      <div className="d-none d-sm-flex flex-row gap-3 align-items-center">
        {productImage()}
        {productData()}
      </div>
    </>
  );
}

export default CartProductCard;
