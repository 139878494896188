import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ProfilePicture from "../UserProfile/ProfilePicture";
import { doc, getDoc, writeBatch, arrayUnion } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { useParams } from "react-router-dom";
import { UserAuth } from "../Context";
import SendFriendRequest from "./SendConnectionRequest";
import formatTimestamp from "../lib/formatTimestamp";

export const HandleUnfriend = async (senderId, receiverId) => {
  try {
    // Start a batched write to perform multiple updates atomically
    const batch = writeBatch(db);

    // Reference to the connections subcollection for both the sender and receiver
    const senderConnectionRef = doc(
      db,
      "users",
      senderId,
      "connections",
      receiverId
    );
    const receiverConnectionRef = doc(
      db,
      "users",
      receiverId,
      "connections",
      senderId
    );

    // Delete the connection documents from both users
    batch.delete(senderConnectionRef);
    batch.delete(receiverConnectionRef);

    // Commit the batched write
    await batch.commit();
    console.log("Unfriended successfully!");
  } catch (error) {
    console.error("Error unfriending user: ", error);
  }
};

export const HandleBlockUser = async (senderId, receiverId) => {
  try {
    // Start a batched write to perform multiple updates atomically
    const batch = writeBatch(db);

    // Reference to the sender's document in the connections subcollection
    const senderConnectionRef = doc(
      db,
      "users",
      senderId,
      "connections",
      receiverId
    );
    const receiverConnectionRef = doc(
      db,
      "users",
      receiverId,
      "connections",
      senderId
    );

    // Update the sender's document by adding the receiverId to the blockList
    const senderDocRef = doc(db, "users", senderId);
    batch.update(senderDocRef, {
      blockList: arrayUnion(receiverId),
    });

    // Delete the connection documents from both the sender and receiver
    batch.delete(senderConnectionRef);
    batch.delete(receiverConnectionRef);

    // Commit the batched write
    await batch.commit();
    console.log("User blocked successfully!");
  } catch (error) {
    console.error("Error blocking user: ", error);
  }
};

export default function UserActiveConnections({
  activelist,
  loggedInUser,
  connectionsTimestamps,
  activeList
}) {
  const [userData, setUserData] = useState([]);
  const { uid } = useParams();
  const { user } = UserAuth();
  const myID = user;

  useEffect(() => {
    if (!activelist || activelist.length === 0) {
      // If it's null or empty, you can either set the userData to an empty array or keep the existing data
      setUserData([]);
      return; // Prevents further execution of the useEffect
    }

    const fetchUserData = async () => {
      try {
        const userList = await Promise.all(
          activelist.map(async (userId) => {
            const docSnap = await getDoc(doc(db, "users", userId));
            if (docSnap.exists()) {
              return { ...docSnap.data(), uid: docSnap.id };
            } else {
              console.log("No such document!");
              return null;
            }
          })
        );

        // Filter out any null values if a document wasn't found
        const filteredUserList = userList.filter((user) => user !== null);
        console.log("Fetched users from active friends:", filteredUserList);
        setUserData(filteredUserList);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUserData();
  }, [activelist]);

  const isCurrentUserProfile = user?.uid === uid;

  console.log("UserDatas from active connections", userData);
  console.log(
    "connectionsTimestamps from active connections",
    connectionsTimestamps
  );
  console.log("uid from active connections", uid);
  return (
    <>
      <h5 className="card-title px-4 mb-3">
                {activeList.length || 0} Friends
              </h5>
      {/* Connections Item */}
      {userData.map((user) => {
        return (
          <div className="d-flex align-items-center mb-4 px-4">
          
            {/* Avatar */}
            <div className="avatar me-3 mb-0">
              <Link
                to={`/userProfile/${user.uid}`}
                className="avatar-img rounded-circle"
              >
                <ProfilePicture userData={user} avatarSize={42} />
              </Link>
            </div>
            {/* Info */}
            <div className="w-100">
              <div className="d-sm-flex align-items-start">
                <h6 className="mb-0">
                  <Link to={`/userProfile/${user.uid}`} className="h6 mb-0">
                    {user.userName
                      ? user.userName
                          .split(" ")
                          .slice(0, 2)
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")
                      : ""}
                  </Link>
                </h6>
                <p className="small ms-sm-2 mb-0">
                  {connectionsTimestamps[user.uid]
                    ? formatTimestamp(connectionsTimestamps[user.uid])
                    : "N/A"}
                </p>
              </div>
              {/* Connections START */}
            </div>
            {/* Button */}
            <div className="ms-md-auto d-flex">
              {isCurrentUserProfile && (
                <>
                  <button
                    className="btn btn-primary-soft btn-sm mb-0 me-2"
                    onClick={() => HandleUnfriend(user?.uid, loggedInUser)}
                  >
                    Unfriend
                  </button>
                  <button
                    className="btn btn-danger-soft btn-sm mb-0"
                    onClick={() => HandleBlockUser(user?.uid, loggedInUser)}
                  >
                    Block
                  </button>
                </>
              )}
              <div className="ms-md-auto d-flex">
                {!isCurrentUserProfile && (
                  <>
                    {!(myID.uid === user.uid) && (
                      <button
                        className="btn btn-primary-soft btn-sm mb-0"
                        onClick={() =>
                          SendFriendRequest(loggedInUser, user.uid)
                        }
                      >
                        Add friend
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
