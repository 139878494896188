import React, { useState, useEffect } from "react";
import { LikeAProduct, Posting } from "./PointsTypes";
import { UserAuth } from "../Context";

import { collection, query, getDocs, orderBy } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { Link, useParams } from "react-router-dom";
import PrivateProfile from "../UserProfile/PrivateProfile";
import LoadingSpinner from "../UserProfile/LoadingSpinner";

const pointsComponents = {
  posting: Posting,
  likeAProduct: LikeAProduct,
};

function PointsStats({ userData }) {
  const { user } = UserAuth();
  const { uid } = useParams();
  const [activeTab, setActiveTab] = useState("");
  const [pointsLog, setPointsLog] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch points log from Firestore
  useEffect(() => {
    const fetchPointsLog = async () => {
      if (!user) return;

      try {
        const pointsLogRef = collection(db, "users", user.uid, "pointsLog");
        const pointsLogQuery = query(
          pointsLogRef,
          orderBy("timestamp", "desc")
        );
        const querySnapshot = await getDocs(pointsLogQuery);
        const pointsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setPointsLog(pointsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching points log:", error);
        setLoading(false);
      }
    };

    fetchPointsLog();
  }, [user]);

  const Friendlist = userData?.friendRequests?.active || [];
  const privacy = userData?.privacySettings?.accountSetting || "private";
  const isCurrentUserProfile = user?.uid === uid;
  const isUserInFriendList = Friendlist.includes(user?.uid);
  // console.log("pointsLog", pointsLog);
  return (
    <>
      {(isCurrentUserProfile || privacy === "public" || isUserInFriendList) && (
        <div className="card h-100">
          <style jsx>
            {`
              .pointDiv {
                scrollbar-width: thin;
              }
            `}
          </style>
          {/* Card header */}
          {/* <div className="card-header border-0 pb-0">
            <h5 className="card-title">Points</h5>
          </div> */}
          <ul className="card-header rounded-2 rounded-bottom-0 nav nav-tabs nav-bottom-line2 active justify-content-center justify-content-md-start my-0 pe-3 py-0 ps-4">
            <li className="nav-item">
              <Link
                className={`nav-link pt-3 pb-2 ${
                  activeTab === "points" ? "active" : ""
                }`}
                // onClick={() => setActiveTab("points")}
              >
                Points
              </Link>
            </li>
          </ul>
          <div
            className="card-body pointDiv ms-1"
            // style={{ maxHeight: "70vh", overflowY: "scroll" }}
          >
            {loading ? (
              <LoadingSpinner />
            ) : (
              <div className="timeline">
                {pointsLog.length > 0 ? (
                  pointsLog.map((log) => {
                    console.log(log);
                    const Component = pointsComponents[log.type];
                    return Component ? (
                      <Component key={log.id} {...log} />
                    ) : null;
                  })
                ) : (
                  <p>No activity yet.</p>
                )}
              </div>
            )}
          </div>

          {/* <div className="card-footer border-0 py-3 text-center position-relative d-grid pt-0">
        <a href="#!" role="button" className="btn btn-sm btn-loader btn-primary-soft" data-bs-toggle="button" aria-pressed="true">
          <span className="load-text">Load more activity</span>
          <div className="load-icon">
            <div className="spinner-grow spinner-grow-sm" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </a>
      </div> */}
        </div>
      )}
      {!isCurrentUserProfile &&
        privacy === "private" &&
        !isUserInFriendList && <PrivateProfile />}
    </>
  );
}

export default PointsStats;
