import React, { useEffect, useState } from "react";
import { UserAuth } from "../Context";
import {
  onSnapshot,
  doc,
  collection,
  getDocs,
  getDoc,
  where,
  query,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { Link } from "react-router-dom";
import ProfilePicture from "../UserProfile/ProfilePicture";
import FriendList from "./FriendList";
import {
  FileEarmark,
  FileEarmarkFill,
  FileImage,
  GridFill,
  TrophyFill,
} from "react-bootstrap-icons";

function UserProfile() {
  // console.log("location", location);
  const { user } = UserAuth();
  const [activeTab, setActiveTab] = useState("account");
  const [userData, setUserData] = useState(null);
  const [followingCount, setFollowingCount] = useState(0);
  const [followersCount, setFollowersCount] = useState(0);
  const [postCount, setPostCount] = useState(0);
  const isbrandHandler = false;
  const uid = user?.uid;
  useEffect(() => {
    if (user) {
      const userDocRef = doc(db, "users", user.uid);
      const unsubscribe = onSnapshot(userDocRef, (doc) => {
        if (doc.exists()) {
          setUserData({ uid: doc.id, ...doc.data() });
        } else {
          console.log("User document does not exist.");
        }
      });

      return () => {
        // Unsubscribe from the snapshot listener when the component unmounts
        unsubscribe();
      };
    }
  }, [user]);
  useEffect(() => {
    if (!user) return;
    const fetchFollowersAndFollowingData = () => {
      const userDocRef = doc(db, "users", user.uid);
      const followersRef = collection(userDocRef, "followers");
      const followingRef = collection(userDocRef, "following");
      const followingQuery = query(followingRef, where("type", "==", "user"));

      const unsubscribeFollowers = onSnapshot(followersRef, (snapshot) => {
      setFollowersCount(snapshot.size);
      });

      const unsubscribeFollowing = onSnapshot(followingQuery, (snapshot) => {
      setFollowingCount(snapshot.size);
      });

      return () => {
      unsubscribeFollowers();
      unsubscribeFollowing();
      };
    };
    fetchFollowersAndFollowingData();
  }, [user]);
  useEffect(() => {
    if (!user) return;

    const fetchPostsCount = async () => {
      try {
        const userRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setPostCount(userData.posts || 0);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching posts count:", error);
      }
    };

    fetchPostsCount();
  }, [user]);

  return (
    <>
      {/* Offcanvas body */}

      <div className="offcanvas-body d-flex flex-column gap-2 px-2 ps-1 px-lg-0 userProfile">
        {/* Card START */}
        <div className="card overflow-hidden">
          {/* Cover image */}
          {/* Card body START */}
          <div
            className="h-50px"
            style={{
              backgroundImage: `url(assets/images/bg/01.jpg)`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          <div className="card-body pt-0 pb-2">
            <div className="text-center">
              {/* Avatar */}
              {/* {renderProfilePicture()} */}
              <div className="avatar avatar-lg mt-n5 mb-3">
                <Link
                  to={
                    user
                      ? `/userProfile/${user.uid}/shoppinglist`
                      : "/userProfile"
                  }
                >
                  <ProfilePicture
                    userData={userData}
                    className="avatar-img rounded border border-white border-3"
                  />
                </Link>
              </div>
              {/* Info */}
              <h5 className="mb-0">
                <Link
                  to={
                    user
                      ? `/userProfile/${user.uid}/shoppinglist`
                      : "/userProfile"
                  }
                >
                  {userData
                    ? userData.userName
                        .split(" ")
                        .slice(0, 2)
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")
                    : ""}
                </Link>
              </h5>
              <small>
                <strong>{userData ? userData.userTitle : ""}</strong>
              </small>
              <p className="mt-3">
                {userData && userData.overview
                  ? userData.overview
                      .split(" ")
                      .slice(0, 10)
                      .join(" ") +
                    (userData.overview.split(" ").length > 10 ? "..." : "")
                  : ""}
              </p>

              {/* User stat START */}
              <div className="hstack gap-2 gap-lg-2 justify-content-center">
                {/* User stat item */}
                <Link
                  className="nav-link p-0"
                  to={`userProfile/${user?.uid}/posts`}
                >
                  <h6 className="mb-0">{postCount}</h6>
                  <small>Post</small>
                </Link>
                {/* Divider */}
                <div className="vr border mx-1"></div>
                {/* User stat item */}
                <Link
                  className="nav-link p-0"
                  to={`userProfile/${user?.uid}/network/userfollowers`}
                >
                  <h6 className="mb-0">{followersCount}</h6>
                  <small>Followers</small>
                </Link>
                {/* Divider */}
                <div className="vr border mx-1"></div>
                {/* User stat item */}
                <Link
                  className="nav-link p-0"
                  to={`userProfile/${user?.uid}/network/userfollowing`}
                >
                  <h6 className="mb-0">{followingCount}</h6>
                  <small>Following</small>
                </Link>
              </div>

              {/* User stat END */}
            </div>
            <div className="d-sm-none d-block"></div>
          </div>
          {/* Card  END */}
          </div>
          <div className="card overflow-hidden">
          {/* Card footer */}
          <div className="card-header py-2 pb-0 px-3">
            <h6>Settings</h6>
          </div>
          <ul className="card-body p-0 nav nav-pills nav-link-body flex-column fw-semibold gap-2 border-0 py-2">
            <li
              className={`nav-item ${activeTab === "account" ? "active" : ""}`}
            >
              <Link
                className="nav-link d-flex align-items-center mb-0"
                to={"/settings/accountSetting"}
                onClick={() => setActiveTab("account")}
              >
                <img
                  className="me-2 h-20px fa-fw"
                  src="assets/images/icon/cog-outline-filled.svg"
                  alt=""
                />
                <span>Account Settings</span>
              </Link>
            </li>
            <li
              className={`nav-item ${
                activeTab === "stylePreferences" ? "active" : ""
              }`}
            >
              <Link
                className="nav-link d-flex align-items-center mb-0"
                to={"/settings/stylePreferences"}
                onClick={() => setActiveTab("stylePreferences")}
              >
                <GridFill className="fs-5" style={{ color: "#676A79" }} />
                <div className="ms-2">Style Preferences</div>
              </Link>
            </li>
            <li
              className={`nav-item ${activeTab === "network" ? "active" : ""}`}
            >
              <Link
                className="nav-link mb-0 d-flex align-items-center  pe-0 "
                to={`/settings/network/${uid}`}
                onClick={() => setActiveTab("network")}
              >
                {/* <i class="bi bi-person-hearts me-2 h-25px fa-fw "style={{height:'25px'}}></i> */}
                <img
                  className="me-2 h-20px fa-fw"
                  src="assets/images/icon/person-outline-filled.svg"
                  alt=""
                />
                <div className="">Network</div>
              </Link>
            </li>
            <li className={`nav-item ${activeTab === "posts" ? "active" : ""}`}>
              <Link
                className="nav-link mb-0 d-flex align-items-center  pe-0 "
                to={`/settings/posts/${uid}`}
                onClick={() => setActiveTab("posts")}
              >
                {/* <i class="bi bi-person-hearts me-2 h-25px fa-fw "style={{height:'25px'}}></i> */}
                {/* <i class="bi bi-file-earmark-fill"></i> */}
                <FileImage className="fs-5" style={{ color: "#676A79" }} />
                <span className="ms-2">Posts</span>
              </Link>
            </li>

            {isbrandHandler && (
              <li
                className={`nav-item ${
                  activeTab === "brandHandler" ? "active" : ""
                }`}
              >
                <Link
                  className="nav-link d-flex mb-0"
                  to="#nav-setting-tab-2"
                  onClick={() => setActiveTab("brandHandler")}
                >
                  <img
                    className="me-2 h-20px fa-fw"
                    src="assets/images/icon/person-outline-filled.svg"
                    alt=""
                  />
                  <span>Brand Handler</span>
                </Link>
              </li>
            )}
            <li
              className={`nav-item ${activeTab === "privacy" ? "active" : ""}`}
            >
              <Link
                className="nav-link mb-0 d-flex align-items-center px-auto"
                to={"/settings/privacySetting"}
                onClick={() => setActiveTab("privacy")}
              >
                <img
                  className="h-20px fa-fw"
                  src="assets/images/icon/shield-outline-filled.svg"
                  alt=""
                />
                <div className="ms-2">Privacy Settings</div>
              </Link>
            </li>
            {/* <li
              className={`nav-item ${
                activeTab === "pointStats" ? "active" : ""
              }`}
            >
              <Link
                className="nav-link mb-0 d-flex align-items-center px-auto"
                to={`/settings/pointStats/${uid}`}
                onClick={() => setActiveTab("pointStats")}
              >
                <TrophyFill className="fs-5" style={{ color: "#676A79" }} />
                <div className="ms-2">Points</div>
              </Link>
            </li> */}
          </ul>
          <div className="card-footer text-center py-2">
            <Link
              className="btn btn-link btn-sm"
              to={`/userProfile/${user?.uid}/shoppinglist`}
            >
              View Profile
            </Link>
          </div>
        </div>

        {/* Card END */}
      </div>
    </>
  );
}

export default UserProfile;
