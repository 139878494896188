import React from "react";

function Step3() {
  const step3Image =
    process.env.PUBLIC_URL + "/assets/LandingPage/step3Image.png";

  return (
    <div
      className="row mt-0 mt-md-5"
      style={{ minHeight: "65vh", alignItems: "center" }}
    >
      <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
        <h1 className="display-6 text-center text-md-start ms-3">
          Plan Your Events with Ease
        </h1>
        <p className="lead text-center text-md-start ms-3">
          Effortlessly create and manage shopping lists for all your upcoming
          events.
        </p>
      </div>
      <div className="col-12 col-md-6 d-flex justify-content-center align-items-center mt-4 mt-md-0">
        <img
          className="img-fluid"
          src={step3Image}
          alt="Step 3 Illustration"
          style={{ maxWidth: "50%" }}
        />
      </div>
    </div>
  );
}

export default Step3;
