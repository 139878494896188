import React from "react";
import useModalStore from "../StatesStore/ModalStore";
import Invite from "./Invite";
import ConfirmUnfollow from "./ConfirmUnfollow";
import ConfirmDelete from "./ConfirmDelete";
import ShoppingListInvite from "./ShoppingListInvite";
import SignInModal from "./SignInModal";
import ConfirmModal from "./ConfirmModal";

function ModalManager() {
  const modalLookup = {
    SignInModal,
    Invite,
    ConfirmUnfollow,
    ConfirmDelete,
    ShoppingListInvite,
    ConfirmModal,
  };
  const { type, data, open } = useModalStore();
  let renderModal;
  if (open && type) {
    const ModalComponent = modalLookup[type];
    renderModal = <ModalComponent data={data} />;
  }
  return <span>{renderModal}</span>;
}

export default ModalManager;
