import React from "react";

const colorMapping = {
  Pink: "#FFC0CB",
  Blue: "#87CEEB",
  Green: "#008000",
  Yellow: "#FFFF00",
  White: "#FFFFFF",
  Black: "#000000",
  Red: "#FF0000",
  Purple: "#800080",
  Grey: "#808080",
  Brown: "#A52A2A",
  Multi: "", // Multi will be handled separately
  Orange: "#FFA500",
};

const getColorCode = (colorName) => {
  return colorMapping[colorName] || "#000000"; // Return black as default if color is not found
};

const ColorSelector = ({ colors,width = "30px", height = "30px" }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "0px" }}>
      {/* <span className="me-2" style={{ fontWeight: 'bold' }}>{colors?.length > 1 ? 'Colors:' : 'Color:'} </span> */}
      <div style={{ display: "flex", gap: "10px" }}>
        {colors?.map((color, index) => (
          <div
            className="border border-body border-2 rounded-circle"
            style={{ padding: "0.1rem" }}
          >
            <div
              key={index}
              title={color}
              style={{
                backgroundColor: getColorCode(color),
                // border: "2px solid lightgrey",
                borderRadius: "50%", // Set to 5px for rounded corners
                width,
                height,
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                justifyContent: "center",
                background:
                  color === "Multi"
                    ? "conic-gradient(white , yellow, orange, red, purple, blue, green)"
                    : getColorCode(color),
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ColorSelector;
