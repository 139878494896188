import React from "react";

function Step2() {
  const step2Image =
    process.env.PUBLIC_URL + "/assets/LandingPage/step2Image.png";

  return (
    <div
      className="row mt-0 mt-lg-5"
      style={{ minHeight: "65vh", alignItems: "center" }}
    >
      <div className="col-12 col-lg-6 d-flex flex-column justify-content-center">
        <h1 className="display-6 text-center text-lg-start ms-3">
          Get Instant Feedback from Friends!
        </h1>
        <p className="lead text-center text-lg-start ms-3">
          Share your ideas and get quick feedback
        </p>
      </div>
      <div className="col-12 col-lg-6 d-flex justify-content-center mt-4 mt-lg-0">
        <img className="img-fluid" src={step2Image} alt="Step2 GIF" />
      </div>
    </div>
  );
}

export default Step2;
