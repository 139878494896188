import React, { useState } from "react";
import { XBlock, XMasonry } from "react-xmasonry";
import ProductCard5 from "../Products/ProductCard5";
import Skeleton from "../Products/Skeleton";
import { useQuery } from "@tanstack/react-query";

function TestingStep1() {
  const [isLoading, setIsLoading] = useState(false);

  const defaultFeed = async () => {
    const response = await fetch(`${process.env.REACT_APP_API}/api/logoutFeed`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  };
  const { data, error } = useQuery({
    queryKey: ["defaultFeed"],
    queryFn: () => defaultFeed(),
    // enabled: !user, // Query runs when userId is undefined
  });
  function getTargetBlockWidth() {
    const screenWidth = window.innerWidth;
    return screenWidth < 440 ? 200 : 250;
  }
  const step1 = process.env.PUBLIC_URL + "/assets/LandingPage/step1.png";

  return (
    <>
      <div class="card card-body rounded-4 border-0 p-2 p-lg-5">
        <div className="px-2 px-lg-5 d-flex flex-column gap-4">
          <div className="w-100 d-flex align-items-center justify-content-center">
            <div className="h4 text-center w-75">
              Find style inspiration and get personalized product
              recommendation.
            </div>
          </div>
          <div>
            <div className="h6 fw-light">Style Recommendation</div>
            {/* <div className="d-flex gap-1 flex-wrap align-items-center justify-content-center"> */}
            <div className="row mx-0 gap-2">
              <div className="btn btn-primary btn-md text-white col">
                Evening gowns for summer
              </div>
              <div className="btn btn-primary btn-md text-white col">
                Smart Casual Office wear
              </div>
              <div className="btn btn-primary btn-md text-white col">
                Uncoventional bold party styles
              </div>
              <div className="btn btn-primary btn-md text-white col">
                Evening gowns for summer
              </div>
            </div>
          </div>
          <div>
            <div className="h6 fw-light">Recommended for you</div>
            <XMasonry
              center={true}
              responsive={true}
              maxColumns={3}
              targetBlockWidth={getTargetBlockWidth()}
            >
              {data?.slice(0, 6).map((product, index) => {
                // Default product card rendering
                return (
                  <XBlock key={product.id} width={1}>
                    <div
                      className={`me-0 ${
                        index === 0 || index === 5 ? "ms-0" : "ms-2"
                      }`}
                    >
                      <ProductCard5 product={product} />
                    </div>
                  </XBlock>
                );
              })}
              {isLoading &&
                Array.from({ length: 25 }).map((_, index) => (
                  <XBlock key={`skeleton-${index}`} width={1}>
                    <Skeleton />
                  </XBlock>
                ))}
            </XMasonry>
          </div>
        </div>
      </div>
    </>
  );
}

export default TestingStep1;
