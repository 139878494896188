import React from "react";
import StaticLinks from "../components/StaticLinks";
import { Route, Routes } from "react-router-dom";
import PrivacyPolicy from "./PrivacyPolicy";
import FAQs from "../old/FAQs";
import Support from "../old/Support";
import Documentation from "../old/Documentation";
import TermsOfService from "./TermsOfService";
import ScrollToTop from "./scrollToTop";
import SideNavUserProfile from "../SideNav/SideNavUserProfile";
import ContactUs from "../ContactUs";
export default function StaticPages() {
  return (
    <>
      <ScrollToTop />
      {/* <div className="col-md-8 col-lg-6 vstack gap-3 text-body mt-0 mt-lg-5 px-3 px-lg-0 me-2"> */}
      <div
        // style={{ width: "80%" }}
        className="vstack text-body px-0 ms-0 ms-lg-1"
      >
        <Routes>
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/termsAndConditions" element={<TermsOfService />} />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/support" element={<Support />} />
          <Route path="/documentation" element={<Documentation />} />
        </Routes>
      </div>
      {/* <StaticLinks /> */}
    </>
  );
}
