import { db } from "../../FirebaseConfig";
import {
  doc,
  setDoc,
  updateDoc,
  getDoc,
  collection,
  addDoc,
  Timestamp,
  serverTimestamp,
} from "firebase/firestore";
import { generateChatId } from "./generateChatId";

export async function saveMessageToDB(msgData) {
  const { senderId, receiverId, content } = msgData;
  const chatId = generateChatId(senderId, receiverId);

  // Reference to the chat document
  const chatDocRef = doc(db, "chats", chatId);

  // Check if the chat document exists
  const chatDoc = await getDoc(chatDocRef);
  let lastMessage;
  if (!chatDoc.exists()) {
    // Create a new chat document if it doesn't exist

    lastMessage = {
      messageId: crypto.randomUUID(), // Unique message ID
      content,
      timestamp: serverTimestamp(),
      senderId,
    };

    await setDoc(chatDocRef, {
      type: "private",
      members: [senderId, receiverId],
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      lastMessage,
    });
  } else {
    // If chat document exists, update lastMessage and updatedAt

    lastMessage = {
      messageId: crypto.randomUUID(), // Unique messZZage ID
      content,
      timestamp: serverTimestamp(),
      senderId,
    };

    await updateDoc(chatDocRef, {
      updatedAt: serverTimestamp(),
      lastMessage,
      senderId
    });
  }

  // Now add the message to the messages subcollection
  const messagesSubColRef = doc(
    db,
    "chats",
    chatId,
    "messages",
    lastMessage?.messageId
  );
  await setDoc(messagesSubColRef, {
    ...msgData,
    timestamp: serverTimestamp(),
  });

  console.log(`Message saved to chat: ${chatId}`);
  return lastMessage?.messageId;
}
