import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../FirebaseConfig";
import { collection, getDocs } from "firebase/firestore";

function HeaderMenu4({ category }) {
  const [womenCategories, setWomenCategories] = useState([]);
  const [menCategories, setMenCategories] = useState([]);

  useEffect(() => {
    const getMenu = async () => {
      const data = collection(db, "universalCategories");
      const querySnapshot = await getDocs(data);
      const categoriesData = querySnapshot.docs.map(
        (doc) => doc.data().subCategories
      );
      setWomenCategories(categoriesData[1]);
      setMenCategories(categoriesData[0]);
    };

    getMenu();
  }, []);

  const renderDropdownItems = (categories, gender) => {
    return Object.keys(categories)
      ?.sort()
      ?.map((categoryName) => (
        <li className="dropdown-item mb-2 ps-2 mt-2" key={categoryName}>
          <button
            className="dropdown-item dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {categoryName}
          </button>
          <ul className="dropdown-menu py-0">
            {categories[categoryName]
              .filter(
                (subCategory) =>
                  subCategory.name !== "None" &&
                  subCategory.displayName !== "None"
              )
              .map((subCategory, index) => (
                <li key={index}>
                  <Link
                    className="dropdown-item px-2"
                    to={`/AllProducts/${gender}/${categoryName}/${subCategory.name}`}
                  >
                    {subCategory.displayName}
                  </Link>
                </li>
              ))}
          </ul>
        </li>
      ));
  };

  return (
    <ul className="navbar-nav navbar-nav-scroll ms-auto">
      <li className="nav-item dropdown px-2">
        {category === "Women" && renderDropdownItems(womenCategories, "Women")}
        {category === "Men" && renderDropdownItems(menCategories, "Men")}
      </li>
    </ul>
  );
}

export default HeaderMenu4;
